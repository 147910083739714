import HighchartsReact from 'highcharts-react-official';
import React, { createRef, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useEffect } from 'react';
import Highcharts from 'highcharts/highstock'
import Boost from "highcharts/modules/boost";
import LabelModal from './LabelModal';
import { debounce, flatten, get, inRange, isEmpty, max, min, orderBy, set, sortBy, toNumber, toString } from 'lodash';

import EcgChartNavigator from './EcgChartNavigator';
import useLocales from 'src/hooks/useLocales';
import { Ecg } from 'src/classes/Ecg';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { EcgHelpers, binarySearch, findClosest, newArythmiesDetection } from './EcgHelpers';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import uuidv4 from 'src/utils/uuidv4';
import YaxisSetting from './YaxisSetting';
import { t } from 'i18next';
Boost(Highcharts);
const chartColors = ["#1640D6", "#9b141a", "#8fb62a", "rgb(188, 116, 33)", '#1c1c1c'];
Highcharts.SVGRenderer.prototype.symbols.verticalCross = function (x, y, w, h) {
    return [
        'M', x + w / 2, y,           // Move to the top middle of the vertical line
        'L', x + w / 2, y + h,       // Draw the vertical line
        'M', x, y + h / 2,           // Move to the middle of the horizontal line
        'L', x + w, y + h / 2,       // Draw the horizontal line
        'z'
    ];
};
EcgChart2.defaultProps = {
    ecg: [],
    isLabelModeActive: false,
    onPlotBandsChange: () => { },
    labelExtremes: {
        min: 0,
        max: 0
    },
    initialExtremes: {
        min: 0,
        max: 0
    },
    examInVetCase: null
}
const MAX_SEC = 60
const MAX_SEC_DISABLE_TIP = 20


function EcgChart2({ sens, arythmiePr, arythmies, isRulerEnabled, sync, ecg, isTimeInterPicsEnabled, isLabelModeActive, isEditLabelModeActive, onPlotBandsChange, initialExtremes, labelExtremes, reverseEcg, examInVetCase, ecgLabels, onDeleteSuccess, disablePlaceLabelMode, isZoomTriggered, picOption, onChangePicsOption, onChangeTimeInterPics, handlePicsChange, barres, r_peak_uri, edit_r_peak_uri, isQuadrillageEnabled, quadrillageValue, onPicsUpdated, navTriggered, onSetPLotTrack, onPeaksChange, Pics, trainingClick, onChangeLabelMode, onChangeEditLabelMode }, ref) {
    const [autoZoomValue, setAutoZoomValue] = useState(20)
    //Selected ecg label (plotband)
    const [selectedPlot, setSelectedPlot] = useState(null);
    //formatted ecg labels to fit ecg chart (plotbands)
    const [plotBands, setPlotBands] = useState([])
    const [open, setOpen] = useState(false)
    const [backdropOpen, setBackdropOpen] = useState(false)
    const [startPoint, setStartPoint] = useState(0)
    const [endPoint, setEndPoint] = useState(0)

    const { translate } = useLocales()
    const deleteEcgMutation = useMutation((id) => Ecg.deleteEcgLabel(id))
    const { enqueueSnackbar } = useSnackbar();
    const [isZoomActive, setIsZoomActive] = useState(false)
    const [peaks, setPeaks] = useState([])
    //Mutations for api calls
    const picsMutation = useMutation(async () => await Ecg.getPics(r_peak_uri))
    const updatePicsMutation = useMutation((str) => Ecg.updatePics(edit_r_peak_uri, str))
    const chartRef = useRef(null)
    const navigatorRef = useRef(null)
    const yAxisSettingRef = useRef(null)
    useEffect(() => {
        let el = Highcharts.chart('ecgChart', {
            chart: {
                id: 'ecg',
                backgroundColor: '#ffffff',
                zoomType: "x",
                spacingTop: 20,
                width: null,
                spacingBottom: 20,
                style: {
                    fontFamily: "Public Sans",
                },
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
                resetZoomButton: {
                    theme: {
                        style: {
                            display: 'none'

                        }
                    }
                },
                events: {
                    selection: (event) => {
                        handleSelectionEvent(event, this)
                    },
                    click: function (e) {
                        addCustomMarker(e)
                    },

                },
            },
            loading: {
                labelStyle: {
                    color: 'black'
                }
            },
            mapNavigation: {
                enabled: true
            },
            credits: {
                enabled: false,
            },
            title: {
                text: 'ECG'
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: true
            },

            boost: {
                allowForce: true,
                enabled: true,
                useGPUTranslations: true,
                seriesThreshold: 1
            },
            plotOptions: {
                series: {
                    showInNavigator: true,
                    marker: {
                        enabled: false
                    },
                    states: {
                        hover: false,
                        inactive: {
                            opacity: 1
                        }
                    },
                    animation: false,
                },
                scatter: {
                    marker: {
                        enabled: true
                    },
                    states: {
                        hover: false
                    }
                }
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            yAxis: {
                gridLineColor: "transparent",
                title: {
                    text: "mV",
                },
                min: isMinYEcgNegative.min,
                max: isMinYEcgNegative.max,
                alignTicks: false,
                startOnTick: false,
                endOnTick: false,
                events: {
                    afterSetExtremes: (e) => {
                        redrawPicsData()
                    }
                }
            },
            xAxis: {
                events: {
                    afterSetExtremes: (e) => {
                        let chart = chartRef?.current?.chart
                        if (!chart) return
                        let xdata = chart.series[0].processedXData
                        let dataStartPoint = xdata[0]
                        let dataEndPoint = xdata[xdata.length - 1]
                        if (!sync) {
                            createTrackPlotBand(dataStartPoint * 0.001, dataEndPoint * 0.001)
                        }
                        if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
                            sync && isZoomTriggered(null, null)
                            setIsZoomActive(false)
                        } else {
                            setIsZoomActive(true)
                        }
                        //Check if the chart less than 20 secondes or more
                        if (timeCondition()) {
                            handlePicsChange(true)
                        } else {
                            handlePicsChange(false)
                        }
                        redrawPicsData()
                        checkYAxisLock()
                        if (!dataStartPoint && !dataEndPoint) {
                            isZoomTriggered(null, null)
                            return
                        }
                        if (sync) {
                            isZoomTriggered(dataStartPoint * 0.001, dataEndPoint * 0.001)
                            createTrackPlotBand(null, null)

                        }

                    },

                },
                crosshair: true,
                gridLineColor: "tranparent",
                labels: {
                    formatter: function () {
                        return new Date(this.value).toISOString().slice(11, 19)
                    }
                },

            },
            series: [
                {

                    data: isEmpty(ecg) ? [] : ecg,
                    name: "Ecg",
                    type: "line",
                    id: 'ecg',
                    boostThreshold: 1,
                    enableMouseTracking: false,
                    turboThreshold: 1,
                    color: chartColors[0],
                    visible: true,
                    fillOpacity: 0.3,
                    lineWidth: 2,
                    zIndex: 10,
                    showInNavigator: true,
                }
            ]
        })
        chartRef.current = {
            chart: el
        }
    }, [ecg.length])
    //Different types of ecg Label


    const mappedArythmies = useMemo(() => {
        if (isEmpty(arythmies)) return []
        console.log({ arythmies });

        let data = arythmies.map(e => get(e, 'index', null))

        return data

    }, [arythmies])

    const mappedTips = useMemo(() => {
        let chart = chartRef?.current?.chart
        if (!chart) return []

        try {
            let xDataFull = chart.series[0].xData
            let firstElFull = xDataFull[0]
            let lastElFull = xDataFull[xDataFull.length - 1]
            let yDataProcessed = chart.series[0].processedYData
            let maxValue = max(yDataProcessed)
            let minValue = min(yDataProcessed)
            let _barres = EcgHelpers.getRenderedPicsBarres(Pics, firstElFull, lastElFull, minValue, maxValue)
            let tip = EcgHelpers.getTimeInterPics(_barres)
            return tip
        } catch (error) {
            console.warn('Error fetching time interpics:' + error)
        }

    }, [Pics, chartRef?.current?.chart])


    const getTypes = (colorOpacity = 0.5) => {
        return [
            {
                text: translate('label.type.pathologie'),
                value: 'PATHOLOGY',
                color: `rgba(236, 94, 88,${colorOpacity} )`
            },
            {
                text: translate('label.type.noise'),
                value: 'NOISE',
                color: `rgba(251, 231, 77,${colorOpacity} )`
            },
            {
                text: translate('label.type.other'),
                value: 'OTHER',
                color: `rgba(122, 221, 251,${colorOpacity} )`
            }
        ]
    }

    const createTrackPlotBand = (from, to) => {
        if (!from && !to) return onSetPLotTrack(null)
        let plot = {
            color: "rgba(153, 148, 151, 0.2)", // Color value
            from, // Start of the plot band
            to,
            borderWidth: 2,
            //replace later by db id
            id: uuidv4(),
            //Handle click events
            zIndex: 3,
        }
        onSetPLotTrack(plot)
    }

    useEffect(() => {
        if (trainingClick) {
            let seconds = navigatorRef?.current?.getSeconds()

            let chart = chartRef?.current?.chart

            if (!chart) return

            let extremesAddValue = (seconds / 2) * 1000

            chart.xAxis[0].setExtremes(trainingClick * 1000 - extremesAddValue, trainingClick * 1000 + extremesAddValue)


        }
    }, [trainingClick])

    useImperativeHandle(ref, () => ({

        screenShotChart() {
            //to handle chart width when window size has changed
            let chart = chartRef?.current?.chart
            if (!chart) return
            chart.exportChart({
                type: 'image/png'
            });
        },

        reflow() {
            //to handle chart width when window size has changed
            let chart = chartRef?.current?.chart
            if (!chart) return
            if (chart) chart.reflow()
        },
        //Function to zoom to specific min and max of the xAxis
        handleZoom(min, max) {
            let chart = chartRef?.current?.chart
            if (!chart) return
            try {

                if (min && max) {
                    chart.showResetZoom();
                }
                chart?.xAxis[0]?.setExtremes(min, max)

            } catch (err) {
                console.error(err);
            }


        },
        //Function that return xAxis points
        getChartxAxis() {
            let chart = chartRef?.current?.chart
            if (!chart) return
            return chart.series[0].processedXData
        },
        //Function wheter you want to show or remove the zoom button and and the navigation bar under the chart
        exposeZoomButton(val) {
            let chart = chartRef?.current?.chart
            if (!chart) return
            let nav_el = document.querySelector('#chartNavigator')
            let yData = get(chart, 'yAxis[0]', {})
            let xData = chart.series[0].processedXData

            let min = yData?.min + 150
            if (val) {
                chart.showResetZoom();

                if (nav_el) {
                    nav_el.style.visibility = 'visible';
                }
                if (chart?.watermark) {
                    chart.watermark.destroy()
                }
            } else {
                if (chart.resetZoomButton) {
                    chart?.resetZoomButton?.hide()
                }
                if (nav_el) {
                    nav_el.style.visibility = 'hidden';
                }
                chart.watermark = chart.renderer.text(t('ecg.screenshot.watermark'),
                    chart.xAxis[0].toPixels(xData[xData.length - 1]), chart.yAxis[0].toPixels(min))
                    .css({
                        fontSize: '18px'
                    })
                    .attr({
                        'text-anchor': "end"
                    })
                    .add().toFront()
            }
        },
        //Launch Arythmies detection to get all Arythmies in array 

        launchDetection: (arythmiePr, showMsg) => {
            setBackdropOpen(true)
            let chart = chartRef?.current?.chart


            let xData = chart.series[0].xData
            let firstEl = xData[0]
            let lastEl = xData[xData.length - 1]
            var yAxis = chart.yAxis[0];
            let extremes = yAxis.getExtremes()
            let minValue = extremes?.min ?? 0
            let maxValue = extremes?.max ?? 4096
            let _barres = EcgHelpers.getRenderedPicsBarres(Pics, firstEl, lastEl, minValue, maxValue)


            let tips = EcgHelpers.getTimeInterPics(_barres)

            let arythmies = newArythmiesDetection(tips, arythmiePr, ecgLabels)
            showMsg && enqueueSnackbar(translate('snackbar.arFound', { arythmies: arythmies.length }))
            setBackdropOpen(false)
            return { arythmies }

        },
        //Get previous arythmie
        launchPrevDetection: (arythmies) => {
            let chart = chartRef?.current?.chart
            let xData = chart.series[0].processedXData
            let firstEl = xData[0]
            let index = 0
            if (!isEmpty(arythmies)) {
                let arr = sortBy(arythmies.map(a => a.x1))
                let close = findClosest(arr, firstEl)
                index = binarySearch(arr, close)
            }
            return index
        },
        //Get next arythmie
        launchNextDetection: (arythmies) => {
            let chart = chartRef?.current?.chart
            let xData = chart.series[0].processedXData
            let firstEl = xData[0]
            let index = 0
            if (!isEmpty(arythmies)) {
                let arr = sortBy(arythmies.map(a => a.x1))
                let close = findClosest(arr, firstEl)

                index = binarySearch(arr, close)
            }
            return index
        },
        //highlight the side of a arythmies by setting inter peaks time to red color
        colorizeArythmies: (ar) => {
            removeTimeInterPics()
            addTimeInterPics(null, ar)
        },
        //Getting peaks from api
        getPics: async () => {
            return await picsMutation.mutateAsync()
        },
        //Launch Detection to get peaks in the ecg chart
        launchPicsDetection: async () => {
            return await launchPicsDetections()
        },
        isPicsDetectionLaunched: () => {
            return !isEmpty(Pics)
        },
        //Fire a function to draw grid in the ecg chart based on quadrillageValue
        launchQuadrillage: (quadrillageValue) => {
            removeGrid(false)
            addGrid(quadrillageValue)
        },
        //Remove grid from the ecg chart
        removeQuadrillage: () => {
            removeGrid()
        },
        handleArythmies: (arythmie) => {
            let chart = chartRef?.current?.chart
            if (!chart) return

            let xData = get(chart, 'series[0].processedXData', [])

            let displayedSeconds = autoZoomValue * 1000 > MAX_SEC * 1000 ? 20 * 1000 : autoZoomValue * 1000
            if (!timeCondition()) {
                displayedSeconds = xData[xData.length - 1] - xData[0]
            }

            let arythmiesSeconds = arythmie.x2 - arythmie.x1

            let diff = (Math.abs(displayedSeconds - arythmiesSeconds)) / 2

            return {
                x1: Math.max(0, arythmie.x1 - diff),
                x2: arythmie.x2 + diff,
            }


        },

        toggleBoost(boost = true) {
            let chart = chartRef?.current?.chart
            if (!chart) return


            chart.update({
                boost: {
                    enabled: boost,
                    useGPUTranslations: true,
                }
            })
        },

        toggleLoading(value = false) {
            let chart = chartRef.current?.chart
            if (!chart) return
            value ? chart.showLoading() : chart.hideLoading()
        }


    }), [Pics.length, isZoomActive, r_peak_uri, mappedArythmies.length, arythmiePr, mappedTips.length, ecgLabels.length]);


    //check yAxis values based on lock value
    const checkYAxisLock = () => {
        let lock = yAxisSettingRef.current.getLockedState()
        let chart = chartRef?.current?.chart
        if (!chart) return
        if (lock) {
            //keep track on proccessed y data max and min
            let yData = get(chart, 'series[0].processedYData', [])
            let maxValue = max(yData)
            let minValue = min(yData)
            yAxisSettingRef.current.updateMaxMin(minValue, maxValue)
        }
    }

    const addGrid = (quadrillageValue, redraw = true) => {
        if (timeCondition()) {
            removeGrid()
            return
        }
        let chart = chartRef?.current?.chart
        if (!chart) return
        let xData = get(chart, 'series[0].processedXData', [])

        let result = EcgHelpers.getQuadrillageData(chart, quadrillageValue, isMinYEcgNegative.min)
        chart.grid = []
        //data for yAxis
        //we draw lines based on the data from getQuadrillage value
        //i % 9 == 0 ? 1.5 : 0.5 we want to set a thick line each 10th line
        result.data.forEach((e, i) => {
            let obj = chart.renderer.rect(chart?.xAxis[0].toPixels(e[0]), 35, i % 9 == 0 ? 1.5 : 0.5, 325).attr({
                fill: 'rgba(245, 39, 72, 0.2)',
                zIndex: 10000
            }).add(false);
            chart.grid.push(obj)
        })

        //data for xAxis
        result.dataY.forEach((e, i) => {
            let obj = chart.renderer.rect(chart?.xAxis[0].toPixels(xData[0]), chart?.yAxis[0].toPixels(e), 1700, i % 9 == 0 ? 1.5 : 0.5).attr({
                fill: 'rgba(245, 39, 72, 0.2)',
                zIndex: 10000
            }).add(false);
            chart.grid.push(obj)
        })


        redraw && chart.redraw()
    }

    //remove Grid from ecg Chart
    const removeGrid = (redraw = true) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        try {
            chart?.grid?.forEach(i => typeof i === 'object' && i?.destroy())
            redraw && chart.redraw()
        } catch (err) {
            console.error(err);
        }
    }

    const launchPicsDetections = async () => {
        let chart = chartRef?.current?.chart

        let y_first_extrem = 0
        let y_last_extreme = 4096

        if (chart) {
            var yAxis = chart.yAxis[0];
            let extremes = yAxis.getExtremes()
            y_first_extrem = extremes?.min ?? 0
            y_last_extreme = extremes?.max ?? 4096
        }

        setBackdropOpen(true)
        let _barres = []
        let peaks = await picsMutation.mutateAsync()
        setPeaks(peaks)
        _barres = EcgHelpers.getBars(ecg, peaks, y_first_extrem, y_last_extreme)
        setBackdropOpen(false)
        return _barres
    }

    const debouncedRedraw = debounce(() => {
        let chart = chartRef?.current?.chart
        if (!chart) return

        //remove grid
        removeGrid(false)
        //remove bars
        removeBarsChart(false)
        //remove croix
        removeCroixChart(false)
        //remove interPics
        removeTimeInterPics(false)

        //redraw chart
        if (timeCondition()) {
            chart.redraw()
        } else {


            try {
                if (picOption) {
                    addPics()
                }
                if (isQuadrillageEnabled) {

                    addGrid(quadrillageValue, false)
                }
                if (isTimeInterPicsEnabled) {
                    if (!timeCondition(MAX_SEC_DISABLE_TIP)) {
                        addTimeInterPics([], {}, false)
                    }
                }

            } catch (error) {

            } finally {
                chart.redraw()

            }


        }
    }, 200)

    //Remove all ecg toolbar options and reset to redraw new data
    const redrawPicsData = useCallback(() => debouncedRedraw(), [picOption, isQuadrillageEnabled, isTimeInterPicsEnabled, sync, mappedArythmies.length, arythmiePr, Pics.length, mappedTips.length])

    //To Edit clicked plot band on the chart we open modal to modify this plot
    const handlePlotEdit = (id) => {
        let found = ecgLabels.find(i => i?.uuid === id)
        if (!found) return

        setSelectedPlot(found)
        setStartPoint(found?.index_start)
        setEndPoint(found?.index_end)
        setOpen(true)
    }

    //Edit the chart plot band by id
    const deleteEcgLabel = (id) => {
        deleteEcgMutation.mutateAsync(id)
            .then(result => {
                onDeleteSuccess()
                setOpen(false)
                getPlotInRange()
            }).catch(err => {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'error'
                })
            })
    }
    useEffect(() => {
        onPlotBandsChange(plotBands)
        redrawPicsData()
    }, [plotBands])

    //When isTimeInterPicsEnabled or the length of barres change we add the time between barres or we remove them base on a bool value
    useEffect(() => {
        redrawPicsData()
        updateClickEvent()
    }, [isTimeInterPicsEnabled, Pics.length, mappedArythmies.length, sens, picOption, mappedTips.length])



    //get The label name base on label type
    const getLabelName = ({ type, label }) => {
        switch (type) {
            case "NOISE":
                return translate('label.type.noise')
            case "OTHER":
                return label
            case "PATHOLOGY":
                return label
            default:
                return 'Label'
        }
    }


    //Creating plot bands from ecg labels array
    useEffect(() => {

        if (isEmpty(ecgLabels)) {
            setPlotBands([])
            return
        }

        setPlotBands(ecgLabels.map(ecg => ({
            color: getTypes(0.3).find(e => e.value === ecg?.type)?.color ?? `rgba(122, 221, 251,${0.5} )`, // Color value
            from: ecg?.index_start, // Start of the plot band
            to: ecg?.index_end,
            borderWidth: 2,
            comment: ecg?.comment ?? "",
            borderColor: getTypes(1).find(e => e.value === ecg?.type)?.color,
            //replace later by db id
            id: toString(ecg.id),
            //Handle click events
            zIndex: 1000,
            events: {
                click: function (event) {
                    var mac = /(Mac)/i.test(navigator.platform);
                    let pressed = mac ? event.metaKey : event.ctrlKey

                    if (pressed) return

                    return isEditLabelModeActive && handlePlotEdit(ecg?.uuid)
                },
                mousemove: function (e) {


                    let chart = chartRef.current?.chart

                    let old = chart?.tooltip

                    if (old) {
                        old?.destroy()
                        chart.tooltip = undefined
                    }
                    if (!ecg?.comment) return

                    let normalizedEvent = chart.pointer.normalize(e);


                    let plotbands = get(chartRef.current, 'chart.xAxis[0].plotLinesAndBands', [])

                    if (isEmpty(plotbands)) return

                    let tooltip = chart.renderer.label(
                        ecg?.comment,
                        normalizedEvent.chartX + 10, // Offset to prevent covering the cursor
                        normalizedEvent.chartY + 10
                    ).attr({
                        fill: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 1001,
                        padding: 5,
                        r: 5,
                    }).css({
                        fontSize: '12px',
                        color: '#ffffff',
                        transition: 'opacity 0.5s'
                    }).add();

                    chart.tooltip = tooltip
                },
                mouseout: () => {
                    let chart = chartRef.current?.chart

                    let tooltip = chart?.tooltip

                    if (tooltip) {
                        tooltip?.destroy()
                        chart.tooltip = undefined
                    }

                }
            },
            label: {
                text: getLabelName(ecg), // Content of the label. 
                align: 'center', // Positioning of the label. Default to center.
                style: {
                    fontWeight: '700',
                    color: 'black'
                }
            }
        })))

    }, [ecgLabels, isEditLabelModeActive])





    //we check if place label mode active if true we disable zoom event and we create a label from selected area
    //else if the value is false we keep the default event zoom
    const handleSelectionEvent = (event, current) => {
        let { xAxis } = event

        let { min = 0, max = 0 } = get(xAxis, '[0]', {})
        if (isRulerEnabled) {
            event.preventDefault()
            addRulerToChart(min, max)
            return
        }
        if (isLabelModeActive) {
            event.preventDefault()
            setSelectedPlot(null)
            setStartPoint(min < 0 ? 0 : min)
            setEndPoint(max)
            setOpen(true)
            removeTimeInterPics()
            return
        }

    }

    const removeRulerFromChart = () => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        try {
            if (chart.hasOwnProperty('arrowSVG')) {
                chart?.arrowSVG?.forEach(e => e?.destroy())
            }
            if (chart.hasOwnProperty('arrowValue')) {
                chart?.arrowValue?.forEach(e => e?.destroy())
            }

        } catch (err) {
            console.log({ err });
        } finally {
            chart.arrowSVG = []
            chart.arrowValue = []
        }

    }
    const addRulerToChart = (min, max) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        let yData = get(chart, 'yAxis[0]', {})
        let maxValue = yData?.max
        chart.arrowSVG = !isEmpty(chart?.arrowSVG) ? chart?.arrowSVG : []
        chart.arrowValue = !isEmpty(chart?.arrowValue) ? chart?.arrowValue : []
        let minPx = chart.xAxis[0].toPixels(min)
        let maxPx = chart.xAxis[0].toPixels(max)
        let yPos = chart.yAxis[0].toPixels(maxValue - percentage(maxValue, 20))
        let width = maxPx - minPx
        chart.arrowSVG.push(chart.renderer.path(['M', 0, 0, 'L', width, 0, 'L', width - 5, 5, 'M', width, 0, 'L', width - 5, -5], minPx, yPos).attr({
            'stroke-width': 1.5,
            stroke: 'black'
        })
            .translate(minPx, yPos)
            .add().toFront())
        chart.arrowValue.push(chart.renderer.label(Math.ceil(max - min) + ' ms', chart.xAxis[0].toPixels((max + min) / 2), chart.yAxis[0].toPixels(maxValue - percentage(maxValue, 10)))
            .css({
                fontSize: '10px',
                fontWeight: '700'
            })
            .attr({
                'text-anchor': "middle"
            })
            .add().toFront())
    }

    useEffect(() => {
        let chart = chartRef?.current?.chart
        if (!chart) return

        chart.update({
            chart: {
                events: {
                    selection: function (event) {
                        handleSelectionEvent(event, this)
                    }
                }
            }
        }, false)

        if (!isRulerEnabled) {
            removeRulerFromChart()
        }

    }, [isLabelModeActive, isRulerEnabled])


    //we reverse the ecg based on reverseEcg value
    useEffect(() => {
        let chart = chartRef?.current?.chart
        if (!chart) return


        chart.update({
            yAxis: {
                reversed: reverseEcg,
            },
        })

    }, [reverseEcg]);

    //Toolbar Actions
    //Function to remove Barres peaks from ecg chart
    const removeBarsChart = (redraw = true) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        if (!chart) return


        let series_ids = []
        for (var s in chart.series) {
            String(chart?.series[s]?.userOptions?.id).includes('bar') && series_ids.push(chart?.series[s]?.userOptions?.id);
        }
        if (!isEmpty(series_ids)) {
            series_ids.forEach(id => {
                chart?.get(id)?.remove(false)
            })
            redraw && chart.redraw()


        }





    }
    //Function to remove triangles peaks from ecg chart
    const removeCroixChart = (redraw = true) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        if (!chart) return


        chart?.get('CroixChart')?.remove(redraw);


    }
    //Function to add triangles peaks to ecg chart
    const addCroixChart = (redraw = true) => {
        let chart = chartRef?.current?.chart
        if (!chart) return

        let xData = chart.series[0].processedXData
        let firstEl = xData[0]
        let lastEl = xData[xData.length - 1]

        //Todo base on barres
        let _croix = EcgHelpers.getRenderedPicsCroix(Pics, firstEl, lastEl, chart)

        chart.addSeries({
            data: _croix, id: 'CroixChart',
            type: 'scatter', marker: { symbol: 'verticalCross', fillColor: '#009900', color: '#009900', lineColor: '#009900', lineWidth: 2 },
            zIndex: 20,
            enableMouseTracking: false,
        }, false)
        redraw && chart.redraw()


    }
    //Function to add barres peaks to ecg chart
    const addBars = (redraw = true) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        let xData = chart.series[0].processedXData
        let firstEl = xData[0]
        let lastEl = xData[xData.length - 1]
        //keep track on proccessed y data max and min
        var yAxis = chart.yAxis[0];
        let extremes = yAxis.getExtremes()
        let minValue = extremes?.min ?? 0
        let maxValue = extremes?.max ?? 4096

        let _barres = EcgHelpers.getRenderedPicsBarres(Pics, firstEl, lastEl, minValue, maxValue)

        _barres.forEach(serie => chart.addSeries(serie, false))
        redraw && chart.redraw()


    }

    const addPics = () => {
        //adding peaks based on picOption selected
        if (picOption.picsCroix) {
            addCroixChart(false)
        }
        if (picOption.picsBarre) {
            addBars(false)
        }
    }
    //Function to add Time inter peaks between barres
    const addTimeInterPics = (br, ar = {}, redraw = true) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        let xData = chart.series[0].processedXData
        //first el left first element in the xAxis
        let firstEl = xData[0]
        //last el right last element in the xAxis
        let lastEl = xData[xData.length - 1]

        if (!chart) return


        //we remove previous peaks displayed on the screen
        removeTimeInterPics()
        chart.interPics = []

        let tips = EcgHelpers.renderTip(mappedArythmies, mappedTips, firstEl, lastEl)




        //for each time we draw it in his specific place
        tips.forEach((pic, i) => {
            let obj = null
            obj = chart.renderer.label(
                pic.value,
                chart.xAxis[0].toPixels(pic?.x),
                chart.yAxis[0].toPixels(pic?.y)
            ).css({
                fontSize: '9px',
                color: mappedArythmies.includes(pic.index) ? 'red' : 'black',
                fontWeight: mappedArythmies.includes(pic.index) ? 'bold' : 'normal',
                fontStyle: mappedArythmies.includes(pic.index) ? 'italic' : 'normal',
            })
                .attr({
                    zIndex: 100,
                    'text-anchor': "middle"
                }).add()
            chart.interPics.push(obj)
        })

        redraw && chart.redraw()


    }
    //function to remove inter peaks time
    const removeTimeInterPics = (redraw = true) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        try {
            if (chart?.interPics && !isEmpty(chart?.interPics)) {
                chart?.interPics?.forEach(i => typeof i === 'object' && i?.destroy())
                chart.interPics = []
            }
            if (chart?.arPics && !isEmpty(chart?.arPics)) {
                chart?.arPics?.forEach(i => typeof i === 'object' && i?.destroy())
                chart.arPics = []
            }
            redraw && chart.redraw()
        } catch (err) {
            console.error(err);
        }

    }

    //End Toolbar Actions

    //Function to track if the xAxis is above givn sec sec or no
    const timeCondition = (secs = MAX_SEC) => {
        let chart = chartRef?.current?.chart
        if (!chart) return false
        let xData = get(chart, 'series[0].processedXData', [])
        if (isEmpty(xData)) return false
        return Math.floor((xData[xData.length - 1] - xData[0]) * 0.001) > secs
    }

    const updateClickEvent = () => {
        const chart = chartRef?.current?.chart
        if (!chart) return

        chart.update({
            chart: {
                events: {
                    click: function (e) {
                        addCustomMarker(e)
                    }
                }
            }
        }, false)

    }


    //check if min ecg y value is < ou > from 0
    const isMinYEcgNegative = useMemo(() => {
        try {
            let yValues = ecg.map(e => e[1])
            let _min = min(yValues)
            return _min < 0 ? { min: -2048, max: 4096 } : { min: 0, max: 4096 }
        } catch (error) {
            return { min: 0, max: 4096 }
        }

    }, [ecg])

    useEffect(() => {
        getPlotInRange()

    }, [plotBands, ecg])

    const getPlotInRange = () => {
        let chart = chartRef?.current?.chart
        removeAllPlotBands()
        if (!chart || isEmpty(plotBands)) return


        let xdata = chart.series[0].processedXData
        let dataStartPoint = xdata[0]
        let dataEndPoint = xdata[xdata.length - 1]


        let plotInRange = plotBands.filter(e => inRange(e.from, dataStartPoint, dataEndPoint) || inRange(e.to, dataStartPoint, dataEndPoint) || (inRange(dataStartPoint, e.from, e.to)))

        if (!isEmpty(plotInRange)) {
            plotInRange.forEach(plot => {
                chart.xAxis[0].addPlotBand(plot);
            })
        } else {

            let tooltip = chart?.tooltip

            if (tooltip) {
                tooltip?.destroy()
                chart.tooltip = undefined
            }
        }
    }

    // Function to remove all plot bands from xAxis
    function removeAllPlotBands() {

        let chart = chartRef?.current?.chart
        if (!chart) return

        let xAxis = chart.xAxis[0];  // Assuming you're working with the first xAxis

        // Loop through all plot bands and remove them
        let plotBands = xAxis.plotLinesAndBands || [];
        plotBands.forEach(function (plotBand) {
            if (plotBand.id) {
                xAxis.removePlotBand(plotBand.id);
            }
        });
    }

    useEffect(() => {
        //Update the chart with the plotBands (ecg labels)
        let chart = chartRef?.current?.chart
        if (!chart) return





        chart.update({
            xAxis: {
                events: {
                    afterSetExtremes: (e) => {
                        let chart = chartRef?.current?.chart
                        if (!chart) return

                        getPlotInRange()

                        let xdata = chart.series[0].processedXData
                        let dataStartPoint = xdata[0]
                        let dataEndPoint = xdata[xdata.length - 1]

                        if (!isEmpty(arythmies)) {
                            navTriggered()
                        }
                        if (!sync) {
                            createTrackPlotBand(dataStartPoint * 0.001, dataEndPoint * 0.001)
                        }
                        if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
                            sync && isZoomTriggered(null, null)
                            setIsZoomActive(false)

                        } else {
                            setIsZoomActive(true)

                        }
                        //Check if the chart less than 20 secondes or more
                        if (timeCondition()) {
                            handlePicsChange(true)
                        } else {
                            handlePicsChange(false)
                        }
                        redrawPicsData()
                        checkYAxisLock()
                        if (!dataStartPoint && !dataEndPoint) {
                            isZoomTriggered(null, null)
                            return
                        }
                        if (sync) {
                            isZoomTriggered(dataStartPoint * 0.001, dataEndPoint * 0.001)
                            createTrackPlotBand(null, null)

                        }


                    },

                },
                crosshair: false,
                gridLineColor: "tranparent",
                labels: {
                    formatter: function () {
                        return new Date(this.value).toISOString().slice(11, 19)
                    }
                },

            },
            yAxis: {
                events: {
                    afterSetExtremes: (e) => {
                        redrawPicsData()
                    }
                }
            }
        })

    }, [picOption, isQuadrillageEnabled, sync, mappedArythmies.length, arythmiePr, mappedTips.length, plotBands])

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.which === 39) {
                onNext()
            }
            if (e.which === 37) {
                onBack()
            }
        });
        return () => document.removeEventListener("keydown", () => { });

    }, [open])


    useEffect(() => {
        //Zoom to labeles extremes min and max to focus on the selected label by letting 10% on the left and right
        let chart = chartRef?.current?.chart
        if (!chart) return
        let { min: minLabel, max: maxLabel } = labelExtremes


        if (minLabel === 0 && maxLabel === 0) return 0

        //check if zoom values > labels extremes
        let seconds = navigatorRef.current.getSeconds() * 1000

        let minValue = null
        let maxValue = null

        let diff = maxLabel - minLabel

        if (seconds < diff) {
            minValue = minLabel - ((maxLabel - minLabel) * 10 / 100)
            maxValue = maxLabel + ((maxLabel - minLabel) * 10 / 100)
        } else {
            let rest = seconds - diff
            let addedExtremes = rest / 2

            minValue = minLabel - addedExtremes
            maxValue = maxLabel + addedExtremes
        }




        chart.xAxis[0].setExtremes(minValue, maxValue)

        if (!chart.resetZoomButton) {
            chart.showResetZoom();
        }



    }, [labelExtremes])


    //Add or remove barres peaks from ecg Graph
    const addCustomMarker = async (e) => {
        e.preventDefault()
        let chart = chartRef?.current?.chart
        if (!chart) return
        //check current os if mac or windows
        //to track ctrl on winodws or command on mac
        var mac = /(Mac)/i.test(navigator.platform);
        let pressed = mac ? e.metaKey : e.ctrlKey
        //getting x and y value from the clicked point
        let yValue = Math.floor(get(e, 'yAxis[0].value', get(e, 'point.y', 0)))

        let xValue = 0;
        if (get(e, 'xAxis[0].value') !== undefined) {
            xValue = Math.floor(get(e, 'xAxis[0].value'))
        } else if (get(e, 'point.x') !== undefined) {
            xValue = Math.floor(get(e, 'point.x'))
        }

        if (pressed) {
            setBackdropOpen(true)
            setTimeout(() => {
                let left = xValue - sens
                let right = xValue + sens
                //It seems the search window is moving when we remove peaks

                let mappedPics = []
                try {
                    mappedPics = Pics.filter(e => inRange(e, left, right))
                } catch (err) {
                    console.log({ err });
                }
                if (!isEmpty(mappedPics)) {


                    let closest = mappedPics.reduce((acc, obj) => {
                        return Math.abs(obj - xValue) < Math.abs(acc - xValue) ? obj : acc
                    }
                    )

                    if (closest) {
                        //we remove the closest bar from the peak array 
                        //we make a put request to apply the changes
                        //we send the payload as a string
                        let _peaks = sortBy([...Pics].filter(e => e !== closest))
                        let str = _peaks.join('\n')
                        onPeaksChange(_peaks)
                        setBackdropOpen(false)
                        updatePicsMutation.mutateAsync(str)

                    }
                } else {
                    //we delete the barre
                    let xdata = chart.series[0].processedXData
                    let ydata = chart.series[0].processedYData

                    //we get the highest closest point to the click
                    //we stringify the value then we make a put request
                    let val = EcgHelpers.getHighestPoint(xValue, yValue, xdata, ydata, sens)

                    let _peaks = sortBy([...Pics, val.value[0]])
                    let str = _peaks.join('\n')
                    //we add the barre to the chart
                    onPeaksChange(_peaks)
                    setBackdropOpen(false)

                    updatePicsMutation.mutateAsync(str)

                }
            }, 0)

        }

    }

    const onBack = (pourcentage) => {
        //on click on the previous button on the navigation bottom panel we go back by the same amount of point from the xAxis
        setBackdropOpen(true)

        let chart = chartRef?.current?.chart
        if (!chart) return
        try {
            let data = chart.series[0].processedXData
            let firstEl = data[0]
            let lasEl = data[data.length - 1]
            let diff = lasEl - firstEl

            //we keep 1/4 of xAxis shown
            let quarter = diff / 4


            firstEl = firstEl + quarter



            chart.xAxis[0].setExtremes(firstEl - diff, firstEl)


        } catch (err) {

        } finally {
            setBackdropOpen(false)

        }



    }

    const onNext = (pourcentage) => {
        //on click on the next button on the navigation bottom panel we go ahead by the same amount of point from the xAxis
        setBackdropOpen(true)
        let chart = chartRef?.current?.chart
        try {
            if (!chart) return

            let data = chart.series[0].processedXData
            let firstEl = data[0]
            let lasEl = data[data.length - 1]
            let diff = lasEl - firstEl

            //we keep 1/4 of xAxis shown
            let quarter = diff / 4

            lasEl = lasEl - quarter
            chart.xAxis[0].setExtremes(lasEl, lasEl + diff)

            //after we go  we check if the pics time inter pics grid are checked to recalculate and  redraw them again


        } catch (err) {
            console.error(err);
        } finally {
            setBackdropOpen(false)

        }


    }

    const onZoomIn = () => {
        //when we clique on the zoomIn button we zoom by 15% from the start and the end of the xAxis
        setBackdropOpen(prev => !prev)

        let chart = chartRef?.current?.chart
        if (!chart) return
        let data = chart.series[0].processedXData
        let dataStartPoint = data[0]
        let dataEndPoint = data[data.length - 1]

        let prValue = percentage(dataEndPoint - dataStartPoint, 15)

        chart.xAxis[0].setExtremes(dataStartPoint + prValue, dataEndPoint - prValue)
        //after we set Extremes  we check if the pics time inter pics grid are checked to recalculate and  redraw them again

        setBackdropOpen(prev => !prev)


    }
    const onZoomOut = () => {
        //when we clique on the zoomOut button we zoom out by 15% from the start and the end of the xAxis

        setBackdropOpen(prev => !prev)

        let chart = chartRef?.current?.chart
        if (!chart) return
        let data = chart.series[0].processedXData

        let dataStartPoint = data[0]
        let dataEndPoint = data[data.length - 1]

        let prValue = percentage(dataEndPoint - dataStartPoint, 15)

        chart.xAxis[0].setExtremes(dataStartPoint - prValue, dataEndPoint + prValue)

        //after we set Extremes  we check if the pics time inter pics grid are checked to recalculate and  redraw them again

        setBackdropOpen(prev => !prev)


    }

    //The custom zoom button focus on 20sec from the chart
    const onCustomZoom = (value) => {
        setBackdropOpen(true)
        setAutoZoomValue(value)
        let chart = chartRef?.current?.chart
        if (!chart) return
        let data = chart.series[0].processedXData
        let dataStartPoint = data[0]
        let endPoint = data[data.length - 1]

        let middle = (endPoint + dataStartPoint) / 2


        let seconds = value * 1000

        let addedExtremes = seconds / 2

        chart.xAxis[0].setExtremes(middle - addedExtremes, middle + addedExtremes)
        //after we set Extremes  we check if the pics time inter pics grid are checked to recalculate and  redraw them again
        setBackdropOpen(false)
    }

    function percentage(num, per) {
        return (num / 100) * per;
    }

    const resetZoom = () => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        chart.xAxis[0].setExtremes(initialExtremes.min, initialExtremes.max);
        sync && isZoomTriggered(null, null)
        setIsZoomActive(false)
    }
    const onYaxisExtremesChanged = (min, max) => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        chart.update({
            yAxis: {
                min,
                max,
            }
        })
    }
    const focusYAxisMaxMinValues = () => {
        let chart = chartRef?.current?.chart
        if (!chart) return
        let yData = get(chart, 'series[0].processedYData', [])
        let maxValue = max(yData)
        let minValue = min(yData)

        //keep track on proccessed y data max and min

        yAxisSettingRef.current.updateMaxMin(minValue, maxValue)
    }


    return (
        <div id='ecgGraph' style={{ position: 'relative', width: '100%' }}>
            <Box display={'flex'} position={'relative'}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                    open={backdropOpen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <YaxisSetting picOption={picOption} focusYAxisMaxMinValues={focusYAxisMaxMinValues} ref={yAxisSettingRef} extremes={isMinYEcgNegative} onExtremesChange={onYaxisExtremesChanged}></YaxisSetting>
                <Box width={`100%`} overflow={'hidden'} flexGrow={2}>
                    <div id="ecgChart" style={{ height: '500px', width: '100%' }} />
                    <EcgChartNavigator ref={navigatorRef} enableTriggerZoom={false} zoom={isZoomActive} onBack={onBack} onNext={onNext} onZoomIn={onZoomIn} onZoomOut={onZoomOut} onCustomZoom={onCustomZoom} resetZoom={resetZoom}></EcgChartNavigator>
                </Box>
            </Box>

            <LabelModal open={open}
                plotBands={plotBands}
                handleClose={() => {
                    setStartPoint(0)
                    setEndPoint(0)
                    setOpen(false)
                    onChangeLabelMode(false)
                    onChangeEditLabelMode(false)
                }} startPoint={startPoint} endPoint={endPoint}
                onSubmit={() => {
                    setOpen(false)
                    disablePlaceLabelMode()
                }}
                handleDelete={({ id }) => deleteEcgLabel(id)}
                onEdit={handlePlotEdit}
                itemToEdit={selectedPlot}
                examInVetCase={examInVetCase}
                loading={deleteEcgMutation.isLoading}
            ></LabelModal>


        </div>


    )
}

export default forwardRef(EcgChart2)