import { Box } from '@mui/material'
import React from 'react'

function TableSection({
    title,
    number
}) {
    return (
        <Box display={'flex'} gap={0.5} justifyContent={'space-between'} alignItems={'center'}>
            <p>{title} :</p>
            <Box flex={1} height={'10px'} sx={{
                backgroundPosition: 'bottom',
                backgroundRepeat: 'repeat-x',
                alignSelf: 'end',
                mb: 0.5,
                backgroundSize: 'contain',
                backgroundImage: "url('https://www.svgrepo.com/show/353085/dot-single.svg')"
            }}></Box>
            <p>{number}</p>
        </Box>
    )
}

export default TableSection