import React, { useMemo } from 'react'
import { memo } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { VisibilityOutlined } from '@mui/icons-material';
import useLocales from 'src/hooks/useLocales';
import { dateComparator, dateComparatorDesc } from 'src/utils/examDashboard';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { formatDate } from 'src/utils/dashboard';

function LabelsArray({ plotBands, onSelect, loading }) {


    const sortedPlots = useMemo(() => {
        return isEmpty(plotBands) ? [] : [...plotBands].sort((a, b) => dateComparatorDesc(
            formatDate(dayjs(a?.creation_date).unix()), formatDate(dayjs(b?.creation_date).unix())))
    },
        [plotBands])

    const { translate, dataGridLocales } = useLocales()
    const COLUMNS = [

        {
            field: 'label', headerName: translate('grid.name'), flex: 1
        },
        {
            field: 'comment', headerName: translate('grid.comment'), flex: 1
        },
        {
            field: 'link', headerName: translate('grid.link'), flex: 1, renderCell: (params) => <Button onClick={() => onSelect(params.row)} startIcon={<VisibilityOutlined></VisibilityOutlined>}>{translate('grid.see_in_graph')}</Button>
        }
    ]
    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={sortedPlots}
                getRowId={(row) => row?.id}
                localeText={dataGridLocales()}
                loading={loading}
                columns={COLUMNS}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableSelectionOnClick
            />
        </Box>
    )
}

export default memo(LabelsArray)