import { LockOpenOutlined, LockOutlined, ZoomInMapOutlined } from '@mui/icons-material'
import { Box, IconButton, Slider, TextField, Tooltip } from '@mui/material'
import { debounce } from 'lodash'
import React, { forwardRef, useImperativeHandle } from 'react'
import { memo } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

function YaxisSetting({ extremes, onExtremesChange, focusYAxisMaxMinValues, picOption }, ref) {
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(4096)
    const [value, setValue] = useState([])
    const [locked, setLocked] = useState(false)

    useImperativeHandle(ref, () => ({
        getLockedState: () => locked,
        updateMaxMin: (min, max) => setValue([min, max])
    }))

    useEffect(() => {
        setMin(extremes.min)
        setMax(extremes.max)
        setValue([extremes.min, extremes.max])
    }, [extremes])

    useEffect(() => {

        verify(value);

    }, [value]);

    const verify = useCallback(
        debounce(value => {
            onExtremesChange(value[0], value[1]);
        }, 200),
        []
    );

    return (
        <Box display={'flex'} paddingBottom={11} paddingTop={6}>
            <Slider
                value={value}
                min={min}
                max={max}
                disableSwap
                orientation='vertical'
                onChange={(e, nv) => setValue(nv)}
                valueLabelDisplay="auto"
            />
            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={1} alignItems={'center'}>
                    <TextField size='small' onChange={e => setValue(prev => [prev[0], e.target.value])}
                        inputProps={{ style: { fontSize: 10 } }} // font size of input text
                        sx={{ width: '60px' }} value={value[1]}></TextField>
                    <Tooltip title='AutoZoom'>
                        <IconButton onClick={focusYAxisMaxMinValues}>
                            <ZoomInMapOutlined></ZoomInMapOutlined>
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => setLocked(prev => !prev)}>
                        {locked ? <LockOutlined></LockOutlined> : <LockOpenOutlined></LockOpenOutlined>}
                    </IconButton>
                </Box>

                <TextField size='small' onChange={e => setValue(prev => [e.target.value, prev[1]])}
                    inputProps={{ style: { fontSize: 10 } }} // font size of input text
                    sx={{ width: '60px' }} value={value[0]}></TextField>
            </Box>
        </Box>

    )
}

export default forwardRef(YaxisSetting)