import JSZip from "jszip"
import { get } from "lodash"
import { useSnackbar } from "notistack"
import { useMutation } from "react-query"
import { Examen } from "src/classes/Examen"
import useLocales from "./useLocales"
import axios from "axios"
import { useState } from "react"
import { saveAs } from 'file-saver';
import dayjs from "dayjs"
import Duration from 'dayjs/plugin/duration'
dayjs.extend(Duration)

export const useVetCaseDownload = () => {

    const fetchExamDataMutation = useMutation((id) => Examen.downloadExams(id))
    const fetchLinkMutation = useMutation((url) => axios.get(url))
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { translate } = useLocales()
    const [loading, setLoading] = useState(false)

    const isVetCaseHasEquisymExam = (exams = []) => {
        return exams.some(e => get(e, 'exam[@type]') === 'ExamEquisym')
    }
    const fetchUrl = async (url, filename, head_url) => {
        // create file link in browser's memory

        await axios.head(head_url)
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);




    }

    const downloadExamData = async (vetcase) => {


        let exams = get(vetcase, 'exam_in_vet_cases', [])
        let eqsExams = exams.filter(e => get(e, 'exam[@type]') === 'ExamEquisym')
        if (exams.length > 0 && isVetCaseHasEquisymExam(exams)) {
            try {
                let { data: urlResponse } = await fetchExamDataMutation.mutateAsync(vetcase.id)
                let { filename, url, head_url } = urlResponse

                const DOWNLOAD_TIME = 30 * eqsExams.length

                //check if download time < 3 minutes
                let strCondition = DOWNLOAD_TIME <= 180 ? translate('general.downloadBeganSeveral') : translate('general.downloadBegan', { time: dayjs.duration(DOWNLOAD_TIME, 'seconds').minutes() })


                let snackSuccess = enqueueSnackbar(strCondition, { variant: 'success', autoHideDuration: 10 * 1000 })
                setLoading(true)
                setTimeout(async () => {
                    try {
                        await fetchUrl(url, filename, head_url)
                        enqueueSnackbar(translate('examVideo.successDownload'), {
                            variant: 'success'
                        })
                        setLoading(false)
                        return

                    } catch (err) {


                        closeSnackbar(snackSuccess)
                        let tentative = 1
                        let myInterval = setInterval(async () => {


                            if (tentative <= 10) {
                                try {

                                    await fetchUrl(url, filename, head_url)
                                    clearInterval(myInterval)
                                    enqueueSnackbar(translate('examVideo.successDownload'), {
                                        variant: 'success'
                                    })
                                    setLoading(false)
                                } catch (err) {
                                    if (tentative === 10) {
                                        enqueueSnackbar(translate('general.error'), {
                                            variant: 'error'
                                        })
                                        setLoading(false)
                                    }
                                    if (tentative < 10) {
                                        tentative += 1
                                    }
                                }
                            } else {
                                setLoading(false)
                                return
                            }


                        }, 10 * 1000)


                    }

                }, DOWNLOAD_TIME * 1000)


            } catch (err) {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'error'
                })
            }
        } else {
            if (exams.length === 0) {
                enqueueSnackbar(translate('snackbar.emptyVetCase'), {
                    variant: 'warning'
                })
            }
            if (!isVetCaseHasEquisymExam(exams)) {
                enqueueSnackbar(translate('snackbar.folderWithoutEquisym'), {
                    variant: 'warning'
                })
            }
        }


    }
    return {
        downloadExamData,
        loading: fetchExamDataMutation.isLoading || loading || fetchLinkMutation.isLoading
    }
}