import { Close, PeopleAlt, SupervisedUserCircle, SupervisedUserCircleOutlined } from '@mui/icons-material';
import { Avatar, Box, Divider, IconButton, ListItem, ListItemAvatar, ListItemText, Modal, Typography } from '@mui/material';
import React from 'react'
import useLocales from 'src/hooks/useLocales';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxHeight: 400,
    height: 400,
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};
UsersInTeamModal.defaultProps = {
    users: []
}
function UsersInTeamModal({ open, handleClose, users }) {

    const { translate } = useLocales()
    const getName = (user) => {
        if (user?.lastname) {
            return user?.lastname + ' ' + user?.firstname
        } else {
            return user?.email
        }
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box height={50} display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <PeopleAlt color='primary' fontSize='medium' />
                        <Typography color='primary' variant='h6'>{translate('general.members')}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>
                <Divider></Divider>
                {users.map((user) => (
                    <React.Fragment key={user.id}>
                        <ListItem alignItems="center" >
                            <ListItemAvatar>
                                <Avatar src="/static/images/avatar/1.jpg" />
                            </ListItemAvatar>
                            <ListItemText
                                primary={getName(user)}
                            />
                        </ListItem>
                    </React.Fragment>
                ))}
            </Box>
        </Modal>
    )
}

export default UsersInTeamModal