import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Alert, Box } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../redux/store";
// hooks
import useSettings from "../../hooks/useSettings";
import useResponsive from "../../hooks/useResponsive";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// config
import { HEADER, NAVBAR } from "../../config";
//
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";
import NavbarHorizontal from "./navbar/NavbarHorizontal";
import jwtDecode from "jwt-decode";
import AdminBanner from "src/components/global/AdminBanner";

// ----------------------------------------------------------------------

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ collapseClick, theme, showBanner }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + (showBanner ? 60 : 24),
  paddingBottom: HEADER.MOBILE_HEIGHT + (showBanner ? 60 : 24),
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + (showBanner ? 60 : 24),
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + (showBanner ? 60 : 24),
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { horse } = useSelector((state) => state.horse);
  const { user } = useSelector(state => state.user)
  const [open, setOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false)

  const isDesktop = useResponsive("up", "lg");

  const token = localStorage.getItem('accessToken')


  useEffect(() => {
    if (!token) return

    let decode = jwtDecode(token)

    if ('iu' in decode) {
      setShowBanner(true)
    }

  }, [token])

  return (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 1 },
      }}
    >

      <DashboardHeader
        isCollapse={isCollapse}
        withNavbar={horse && true}
        onOpenSidebar={() => setOpen(true)}
      />

      {!isDesktop && (
        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
      )}
      <MainStyle collapseClick={collapseClick} showBanner={showBanner}>
        {
          showBanner && <AdminBanner></AdminBanner>
        }
        


        <Outlet />
      </MainStyle>
    </Box>
  );
}
