import { add } from "date-fns";
import _mock from "./_mock";
import { randomInArray } from "./funcs";
// utils
import { fDate } from "../utils/formatTime";

// ----------------------------------------------------------------------

// export const _examens = [...Array(10)].map((_, index) => ({
//   id: _mock.id(index),
//   folderNumber: `DOS-${17048 + index}`,
//   horseName: _mock.company(index),
//   clientFullname: _mock.name.fullName(index),
//   toolName: randomInArray(["EQUISYM", "EQUIMETRE", "IPULSE", "OTHER"]),
//   userFullname: _mock.name.fullName(index + 10),
//   reason: randomInArray(["Motif 1", "Motif 2", "Motif 3", "Motif 4"]),
//   createdAt: add(new Date(), { days: index - 15, hours: index }),
// }));

export const _examens = [
  {
    id: "01",
    title: `EQUISYM - ${fDate(add(new Date(), { days: 0 - 15, hours: 0 }))}`,
    folderNumber: `DOS-00001`,
    horseName: "Acsima",
    horseId: "01",
    clientFullname: "Haras des peupliers",
    toolName: "EQUISYM",
    userFullname: "Marie Didier",
    reason: "Motif 1",
    createdAt: add(new Date(), { days: 0 - 15, hours: 0 }),
    children: [
      { title: "Condition 1", id: "2" },
      { title: "Condition 2", id: "3" },
      { title: "Condition 3", id: "4" },
    ],
  },
  {
    id: "02",
    title: `EQUIMETRE - ${fDate(add(new Date(), { days: 2 - 15, hours: 2 }))}`,
    folderNumber: `DOS-00001`,
    horseName: "Acsima",
    horseId: "01",
    clientFullname: "Haras des peupliers",
    toolName: "EQUIMETRE",
    userFullname: "Marie Didier",
    reason: "Motif 2",
    createdAt: add(new Date(), { days: 2 - 15, hours: 2 }),
    children: [{ title: "ECG", id: "5" }],
  },
];
