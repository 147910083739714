import { MoreVert } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react'
import useLocales from 'src/hooks/useLocales';

function ExamOptions({ exam, createRepport, addToCase }) {

    const { translate } = useLocales()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVert></MoreVert>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <MenuItem disabled={isEmpty(exam?.exam_in_vet_cases)} onClick={() => createRepport(exam)} dense>{translate('pinnedConditions.createForHorse', { horse: exam.horse?.name })}</MenuItem>
                <MenuItem onClick={() => addToCase(exam)} dense>{translate('modal.addToCase')}</MenuItem>
            </Menu>
        </div>)
}

export default ExamOptions