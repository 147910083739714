import { Add } from '@mui/icons-material'
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import useLocales from 'src/hooks/useLocales'

function UserNotInTeam({ team, organisation, addUser, isLoading }) {
    const { translate } = useLocales()
    const _users = useMemo(() => {
        let ids = !isEmpty(team?.user_in_teams) ? team.user_in_teams.map(({ user }) => user.id) : []
        return !isEmpty(organisation?.user_in_organisations) ? organisation?.user_in_organisations?.filter(({ user }) => !ids.includes(user?.id)) : []
    }, [team, organisation])

    const getName = (user) => {
        if (user?.lastname) {
            return user?.lastname + ' ' + user?.firstname
        } else {
            return user?.email
        }
    }

    return (
        <>
            <Typography sx={{mb:2}} variant='overline'>{translate('settings.tabMembers')}</Typography>
            {_users.map(({ user }) => (
                <React.Fragment key={user.id}>
                    <ListItem alignItems="center" disableGutters>
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                            primary={getName(user)}
                        />
                        {
                            team && <ListItemButton disabled={isLoading} onClick={() => addUser(user.email)} sx={{ maxWidth: 50 }}>
                                <Add></Add>
                            </ListItemButton>
                        }

                    </ListItem>
                </React.Fragment>
            ))}
        </>
    )
}

export default UserNotInTeam