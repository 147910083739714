import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// @mui
import { Container, Box, CircularProgress, Divider, LinearProgress, Button, Typography, Alert } from "@mui/material";
// hooks
import useLocales from "../hooks/useLocales";
import useAuth from "../hooks/useAuth";
// _mock_
import { _examens } from "../_mock";
// redux
import { useDispatch, useSelector } from "../redux/store";
import { getLoggedUser } from "../redux/slices/user";
import { getHorse, getHorses } from "../redux/slices/horse";
import { getExamen, getArchiveFromExamen, setPinned } from "../redux/slices/examen";
import html2canvas from 'html2canvas';

// components
import Page from "../components/Page";
import { EqsWindow } from "../sections/eqs";
import { EcgWindow } from "../sections/ecg";
// sections
import { TeamNoTeam } from "../sections/team";
import { OrganisationNoOrganisation } from "../sections/organisation";
import { setBreadcrumb } from "src/redux/slices/display";
import { get, isEmpty, isNull } from "lodash";
import { formatDate, getOutils } from "src/utils/dashboard";
import BreadcrumbsHorse from "src/components/horses/BreadcrumbsHorse";
import Error from "src/components/global/Error";
import SideBar from "src/components/exam/SideBar";
import '../styles/loadingWrapper.css'
import { Condition } from "src/classes/Condition";
import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getVetCase } from 'src/redux/slices/examen'
import { ExamExtractor } from "src/utils/ExamExtractor";
import dayjs from "dayjs";
import { useRef } from "react";
import SideBarEcg from "src/components/exam/SideBarEcg";
import { CreateNewFolderRounded } from "@mui/icons-material";
import { PATH_DASHBOARD } from "src/routes/paths";
import AddVetCaseModal from "src/components/horses/AddVetCaseModal";
import AddExamToCase from "src/components/dashboard/AddExamToCase";
import { Examen } from "src/classes/Examen";
import EndingSoonBanner from "src/components/global/EndingSoonBanner";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import AddToFolderModal from "src/components/exam/AddToFolderModal";
// ----------------------------------------------------------------------


//La page contennant le graphique ecg
export default function PageExamEquimetre() {
  const { translate } = useLocales();

  const dispatch = useDispatch();


  const { user, userInOrganisation } = useSelector((state) => state.user);
  let user_org_id = get(userInOrganisation, 'id', null)
  let org_id = get(userInOrganisation, 'organisation.id', null)
  let id = get(userInOrganisation, 'restricted', false) ? user_org_id : org_id
  const { teams } = useSelector((state) => state.team);

  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const { horse, error: horseError } = useSelector((state) => state.horse);
  const { examen, isLoading, error } = useSelector((state) => state.examen);
  const [conditionLoading, setConditionLoading] = useState(false);
  const [vetModal, setVetModal] = useState(false)
  const [exams, setExams] = useState([]);
  const [addToCaseModal, setAddToCaseModal] = useState(false)
  const [examsInVetCases, setExamsInVetCases] = useState([]);
  const [vetCase, setVetCase] = useState(null)
  const navigate = useNavigate()
  const windowRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [clipboardExportLoading, setClipboardExportLoading] = useState(false);
  const [pngExportLoading, setPngExportLoading] = useState(false);

  const [openAddToFolder, setOpenAddToFolder] = useState(false)

  //url query params
  const { horseId = "", examenId = "" } = useParams();
  let selectedfolder = searchParams.get('selected')

  //Api mutations 
  const getExamMutation = useMutation((examId) => dispatch(getExamen(examId)))
  const getVetCaseMutation = useMutation(() => getVetCase(selectedfolder))
  const addExamMutation = useMutation((data) => Examen.addExamToVetCase(data))


  //If the selected folder in url params is not null we fetch the exams from the vet case
  useEffect(() => {
    (selectedfolder && selectedfolder !== 'null') && fetchVetCase()
  }, [selectedfolder])

  const fetchVetCase = () => {
    getVetCaseMutation.mutateAsync()
      .then(({ data }) => {
        setVetCase(data)
      })
  }


  //Queries
  const { isLoading: isHorsesLoading, data: horsesData, isError } = useQuery(
    ['horses', id],
    () => dispatch(getHorses(id)),
    {
      enabled: !isNull(id),
      staleTime: 10 * 60 * 1000
    }
  )
  const _horses = get(horsesData, 'data.hydra:member', [])

  //if Examen Id in the url we fetch it and add it to previous exams list
  useEffect(() => {
    setExams([])
    if (examenId) {
      getExamMutation.mutateAsync(examenId)
        .then(({ data }) => {

          setExams(prev => [...prev, data])
        })
    }
  }, [examenId])


  //if horse Id in the url we fetch it and add it to previous exams list
  useEffect(() => {
    dispatch(getHorse(horseId));
  }, [horseId])

  //We populate breadcrumbs with dynamic data from exam ; horse and vetCase
  useEffect(() => {
    if (!selectedfolder || selectedfolder === 'null') {
      if (examen?.exam_in_vet_cases?.length === 1) {
        navigate(PATH_DASHBOARD.horses.examenEquimetreView(
          horseId,
          examen.external_id,
          get(examen, 'exam_in_vet_cases[0].vet_case.id'),
        ))
      }
    }


    let breadcrumbs = [{
      text: translate('nav.allHorses'),
      link: '/horses',
      isDisabled: false
    },
    {
      text: get(horse, 'name', ''),
      link: `/horses/${get(horse, 'external_id')}/list-view`,
      isDisabled: false,
      sectionTitle: translate('grid.horseName')

    }, {
      text: `${getOutils(get(examen, '@type'))} - ${ExamExtractor.getLastExam(examen)}`,
      link: `/horses/${get(horse, 'external_id')}`,
      isDisabled: true,
      sectionTitle: translate('pinnedConditions.exam')

    }]

    if (vetCase) {
      breadcrumbs.splice(2, 0, {
        text: get(vetCase, 'case_number', translate('general.unamed_folder')),
        link: `/horses/${get(horse, 'external_id')}/folder/${get(vetCase, 'id')}`,
        isDisabled: false,
        sectionTitle: translate('nav.folder')

      })
    }




    dispatch(setBreadcrumb(breadcrumbs))
  }, [examen, horse, vetCase,selectedfolder])

  useEffect(() => {
    dispatch(getLoggedUser());
  }, [dispatch, teams]);


  //Custom screenShot name
  const setEcgScreenshotName = () => {
    try {
      let elements = []
      let xMs = windowRef.current.getExposedChartArea()
      let point1 = get(xMs, '[0]', 0)
      let point2 = get(xMs, `[${xMs.length - 1}]`, 0)
      let hrse = String(horse?.name)
      hrse = hrse.replace('.', '-')
      hrse = hrse.replace(/ /g, "-");
      hrse = hrse.replace('/', '-')
      elements.push(hrse)

      let msValue = dayjs(get(examen, 'creation_date')).valueOf() + xMs[0]
      elements.push(formatDate(msValue * 0.001, 'DD-MM-YYYY-HH:mm').toString())


      let rangeMs = (point2 - point1) < 10000 ? (point2 - point1) : (point2 - point1) * 0.001
      elements.push(rangeMs + 'ms.png')

      return elements.join('_')
    } catch (error) {
      console.error({ error });
      return 'ecgScreenshot.png'
    }

  }

  const takeScreenShot = (copy = false) => {
    windowRef.current.exposeZoomButton(false)
    windowRef.current.toggleBoost(false)
    if (copy) {
      setClipboardExportLoading(true);
    }
    else {
      setPngExportLoading(true);
    }

    setTimeout(() => {
      let el = document.querySelector('#ecgChart')
      html2canvas(el, { allowTaint: true, scale: 2, useCORS: true }).then((canvas) => {
        let canvaDataUrl = canvas.toDataURL();
        if (!copy) {
          var a = document.createElement("a"); //Create <a>
          a.href = canvaDataUrl; //Image Base64 Goes here
          a.download = setEcgScreenshotName(); //File name Here
          a.click();
        } else {

          const promiseCanvasToBlob = async () => {
            return new Promise((resolve, reject) => {
              try {
                canvas.toBlob((blob) => {
                  resolve(blob)
                }, 'image/png')

              } catch (error) {
                console.error(error);
                reject(error)
              }
            }
            )

          }

          setTimeout(() => {


            navigator.clipboard.write([
              new window.ClipboardItem({
                'image/png': promiseCanvasToBlob()
              })
            ]).then(
              () => {
                enqueueSnackbar(translate('snackbar.copySuccess'))
              }
            ).catch(err => {
              console.error(err);
              enqueueSnackbar(translate('general.error'), {
                variant: 'error'
              })
            });
          }, 0);




        }

        windowRef.current.exposeZoomButton(true)
        windowRef.current.toggleBoost(true)
        setPngExportLoading(false);
        setClipboardExportLoading(false);

      })
    }, 500)

  }

  //Getting id exam in vet case
  const idExamInVetCase = useMemo(() => {

    if (isEmpty(examsInVetCases)) return {
      rawId: null,
      id: null,
    }
    let found = examsInVetCases.find(e => get(e, 'exam.external_id') === examenId)

    return {
      rawId: get(found, '@id'),
      id: get(found, 'id'),
    }
  }, [examsInVetCases, examenId])

  const skipAddToVet = (vetcase) => {
    setAddToCaseModal(false)
    navigate(PATH_DASHBOARD.horses.examenEquimetreView(
      horse.external_id,
      examenId,
      get(vetcase, 'id'),
    ))
  }
  const addExamToCase = (caseId) => {
    addExamMutation.mutateAsync({
      'vet_case': caseId,
      'exam': get(examen, '@id')
    }).then(res => {

      enqueueSnackbar(translate('snackbar.caseExamCreateSuccess'))
      setAddToCaseModal(false)
      navigate(PATH_DASHBOARD.horses.examenEquimetreView(
        horse.external_id,
        examenId,
        get(res, 'data.vet_case.id'),
      ))
    }).catch(err => {
      enqueueSnackbar(translate('general.error'), { variant: 'error' })
    })
  }

  const _organisations = !get(userInOrganisation, 'restricted', false) ? user.user_in_organisations.filter(
    (_organisations) => _organisations.accepted
  ) : (user?.limited_access_organisations ?? []);



  //if selected folder is null we block the user from certain actions
  const isActionsAllowed = idExamInVetCase?.rawId

  const minusWidthValue = !open ? '210px' : '60px'

  // CONDITIONS
  if (_organisations.length === 0) {
    return (
      <Page title={translate("nav.dashboard")}>
        <OrganisationNoOrganisation />
      </Page>
    );
  }


  if (getExamMutation.isError || isError || getVetCaseMutation.isError) {
    return <Error buttonText={'nav.backToPreviousScreen'} onClick={() => navigate(-1)} />
  }
  const _teams = user.user_in_teams.filter(
    (_team) => _team.team.organisation.id === userInOrganisation?.organisation?.id
  );

  if (_teams.length === 0 && !get(userInOrganisation, 'restricted', false)) {
    return (
      <Page title={translate("nav.dashboard")}>
        <TeamNoTeam />
      </Page>
    );
  }

  //If page is loading show circular progress
  if (isLoading || getExamMutation.isLoading || getVetCaseMutation.isLoading) {
    return (<Box display='flex' justifyContent='center' alignItems='center' marginTop={3}>
      <CircularProgress />
    </Box>)
  }
  return (
    <Page title={`ECG ${get(horse, 'name', '')} ${ExamExtractor.getLastExam(examen)}`}>
      <Container maxWidth={false} disableGutters={true} style={{ position: 'relative' }}>
        <EndingSoonBanner></EndingSoonBanner>
        <Box margin={2}>

          {/* breadcrumbs component */}
          <BreadcrumbsHorse >
          </BreadcrumbsHorse>
        </Box>


        <AddExamToCase
          open={addToCaseModal}
          handleClose={() => setAddToCaseModal(false)}
          items={[]}
          loading={addExamMutation.isLoading}
          onCancel={() => setAddToCaseModal(false)}
          title={translate('modal.addToCase')}
          handleSubmit={addExamToCase}
          item={examen}
          defaultHorse={horse}
          skipAddToVet={skipAddToVet}
          showHint={!isActionsAllowed}
        ></AddExamToCase>

        <AddToFolderModal
          exam={examen}
          open={openAddToFolder}
          handleClose={() => { setOpenAddToFolder(false) }}
          onConfirm={vetcase => {
            navigate(PATH_DASHBOARD.horses.examenEquimetreView(
              horseId,
              examen.external_id,
              vetcase.id,
            ))
            setOpenAddToFolder(false)
          }}
        >

        </AddToFolderModal>
        {/* End Modal Sections */}
        <Divider></Divider>
        <Box display='flex'>
          {/* Left side panel that contains exams for that specific vet case */}
          <SideBarEcg
            selected={examenId}
            horse={horse}
            horseId={horseId}
            vetCase={vetCase}
            onToggleChange={(value) => { setOpen(value) }}
            toggle={open}
            onExamOnVetCasesLoaded={data => {
              setExamsInVetCases(data)
            }}></SideBarEcg>
          <Box width={`calc(100% - ${minusWidthValue})`} >
            {conditionLoading ?
              <Box className="wrapper">
                <CircularProgress  ></CircularProgress>
              </Box> :
              <>
                {
                  !isActionsAllowed &&
                  <Alert Alert severity="info" sx={{ m: 1, alignItems: 'center' }}
                    action={
                      <Box alignItems={'center'} display={'flex'} gap={1} flexWrap={'wrap'}>
                        {
                          examen?.exam_in_vet_cases?.length > 1 && <Button onClick={() => setOpenAddToFolder(true)} size='small' startIcon={<Icon icon='teenyicons:folders-solid'></Icon>}>{translate('general.selectFolder')}</Button>}
                        <Button size="small" onClick={() => setAddToCaseModal(true)} startIcon={<CreateNewFolderRounded></CreateNewFolderRounded>}>{translate('modal.addToCase')}</Button>

                      </Box>
                    }
                  >
                    <Typography variant='inherit' fontSize={13}> {translate('addToFolderHint')}</Typography>
                  </Alert>
                }


                {/*                 Ecg Window Component that contain Ecg graph*/}
                <EcgWindow open={open}
                  ref={windowRef}
                  examInVetCase={idExamInVetCase}
                  exams={exams}
                  isActionsAllowed={isActionsAllowed}
                  takeScreenshot={takeScreenShot}
                  pngExportLoading={pngExportLoading}
                  clipboardExportLoading={clipboardExportLoading}
                  openCaseModal={() => setAddToCaseModal(true)} />
              </>
            }
          </Box>


        </Box>
      </Container>
    </Page >
  );
}


// ----------------------------------------------------------------------
