import { Box } from '@mui/material'
import { get, isEmpty } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Condition } from 'src/classes/Condition'
import { Examen } from 'src/classes/Examen'
import Comment from 'src/components/report/Comment'
import DataTable from 'src/components/report/DataTable'
import Graph from 'src/components/report/Graph'
import Note from 'src/components/report/Note'
import useLocales from 'src/hooks/useLocales'
import Master from 'src/layouts/dashboard/report/Master'
import { getExamen } from 'src/redux/slices/examen'

function Page({ conditions, exam, seuils, title, comments, clinique, results, pageIndex, totalPages }) {



    return (
        <Master
            pageIndex={pageIndex}
            totalPages={totalPages}
            clinique={clinique}>
            <Box >
                <h3 style={{ color: 'indigo', mt: 5, padding: '16px' }}>{title}</h3>
                <Graph results={results} formatedConditions={conditions} ></Graph>
                {
                    !isEmpty(comments) && <Comment comments={comments}></Comment>
                }
                {/* Change To Dynamic if needed  */}
                <DataTable seuils={seuils} linesMode={'mean'} option={'up'} conditions={conditions}></DataTable>
                <Note></Note>
            </Box>
        </Master>

    )
}

export default memo(Page)