import React from 'react'
import Footer from 'src/components/report/Footer'
import Header from 'src/components/report/Header'

function Master({ children, clinique, pageIndex, totalPages }) {
    return (
        <>
            <Header clinique={clinique}></Header>
            <>
                {children}
            </>
            <Footer
                pageIndex={pageIndex}
                totalPages={totalPages}
            ></Footer>
        </>
    )
}

export default Master