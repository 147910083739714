import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Box,
  Grid,
  Card,
  Stack,
  Typography,
  Button,
  DialogActions,
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { createOrganisation } from "../../redux/slices/user";
// hooks
import useLocales from "../../hooks/useLocales";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import {
  FormProvider,
  RHFTextField,
  RHFRadioGroup,
} from "../../components/hook-form";
import { get } from "lodash";
import { useState } from "react";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function NewOrganisationForm({ onCancel, isDialog }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { user, isLoading } = useSelector((state) => state.user);

  const AUTHORIZATION_OPTION = [
    {
      name: translate("organisationAuthorization.auth1.name"),
      subtitle: translate("organisationAuthorization.auth1.subtitle"),
      value: "AUTO_ADD_EMAIL_DOMAIN",
    },
    {
      name: translate("organisationAuthorization.auth2.name"),
      subtitle: translate("organisationAuthorization.auth2.subtitle"),
      value: "MUST_INVITE",
    },
  ];

  const CreateOrganisationSchema = Yup.object().shape({
    name: Yup.string().required(translate("yup.name.required")),
  });

  const defaultValues = {
    name: "",
    description: "",
    email_domain: "",
    join_policy: "AUTO_ADD_EMAIL_DOMAIN",
    must_be_admin_to_create_cases: false
  };

  const methods = useForm({
    resolver: yupResolver(CreateOrganisationSchema),
    defaultValues,
    shouldUnregister: false,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true)
    let newOrganisation = {
      name: data.name,
      description: data.description,
      email_domain: data.email_domain,
      join_policy: data.join_policy,
      must_be_admin_to_create_cases: data.must_be_admin_to_create_cases,
    }
    try {


      await dispatch(createOrganisation(newOrganisation));
      enqueueSnackbar(translate("snackbar.createSuccess"));

      if (isDialog) {
        onCancel();
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
      enqueueSnackbar(get(error, 'response.data.hydra:description'), {
        variant: 'error'
      });
      setLoading(false)
      reset(newOrganisation)
    }
  };

  useEffect(() => {
    if (!isDialog) {
      if (user.user_in_organisations.length > 0) {
        navigate(PATH_DASHBOARD.root, { replace: true });
      }
    }
  }, [user.user_in_organisations]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3, overflowY: 'auto', position: 'relative', maxHeight: '80vh', }}>
        <Typography color='black' variant="h6">{translate('modal.addOrg')}</Typography>
        <Divider sx={{ my: 2 }}></Divider>

        <p>{loading}</p>
        <LabelStyle>{translate("settings.organisationParameters")}</LabelStyle>
        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <RHFTextField name="name" label={translate("settings.organisationName")} />
          <RHFTextField
            name="description"
            multiline
            rows={4}
            label={translate("settings.organisationDescription")}
          />
          <RHFTextField
            name="email_domain"
            label={translate("settings.organisationDomains")}
          />
        </Stack>

        <Box
          sx={{
            display: "grid",
            rowGap: 3,
            columnGap: 2,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            },
            mt: 3,
          }}
        >
          <LabelStyle>{translate("organisationAuthorization.title")}</LabelStyle>
          <RHFRadioGroup
            name="join_policy"
            options={AUTHORIZATION_OPTION}
            sx={{
              "& .MuiFormControlLabel-root": { mr: 6 },
            }}
          />
          <LabelStyle>{translate("nav.folders")}</LabelStyle>
          <FormControlLabel
            control={
              <Controller
                name={'must_be_admin_to_create_cases'}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={translate('restrictFolderCreation')}
          />
        </Box>


        {isDialog && (
          <DialogActions>
            <Box sx={{ flexGrow: 1 }} />

            <Button variant="outlined" color="inherit" onClick={onCancel}>
              {translate("nav.cancel")}
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              {translate("nav.save")}
            </LoadingButton>
          </DialogActions>
        )}

        {!isDialog && (
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              {translate("nav.save")}
            </LoadingButton>
          </Stack>
        )}
      </Card>
    </FormProvider>
  );
}
