import PropTypes from "prop-types";
import * as Yup from "yup";
import merge from "lodash/merge";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useLocales from "../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { createTeam, updateTeam, deleteTeam } from "../../redux/slices/team";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFRadioGroup,
} from "../../components/hook-form";
import { useMutation } from "react-query";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function TeamAdvanced({ team, onCancel }) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const deleteMutation = useMutation(() => dispatch(deleteTeam(team.id)))
  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { userInOrganisation } = useSelector((state) => state.user);

  const TeamSchema = Yup.object().shape({
    nameToDelete: Yup.string()
      .required(translate("yup.name.required"))
      .matches(team?.name || "----------"),
  });

  const defaultValues = {
    nameToDelete: "",
  };

  const methods = useForm({
    resolver: yupResolver(TeamSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    deleteMutation.mutateAsync()
      .then(result => {
        if (result.isAxiosError) {
          enqueueSnackbar(translate("general.error"), { variant: 'error' });
        } else {
          enqueueSnackbar(translate("snackbar.updateSuccess"));
          onCancel();
        }

      })
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3, minHeight: "60vh" }}>
        <LabelStyle>{translate("settings.deleteTeam")}</LabelStyle>
        <Stack spacing={3} alignItems="left" sx={{ mt: 3 }}>
          <RHFTextField
            disabled={userInOrganisation.type === "MEMBER"}
            name="nameToDelete"
            label={translate("settings.deleteTeamConfirm")}
          />
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {translate("settings.deleteTeamMessage")}
          </Typography>
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            disabled={userInOrganisation.type === "MEMBER"}
            type="submit"
            variant="contained"
            loading={deleteMutation.isLoading}
            color="error"
          >
            {translate("settings.deleteTeam")}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
