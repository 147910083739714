import { Box, Divider, Stack } from '@mui/material'
import React from 'react'
import TableSection from './TableSection'
import useLocales from 'src/hooks/useLocales'

function TableMatiers({ pages }) {
    const { translate } = useLocales()
    return (
        <Box px={5}>
            <p style={{ marginBottom: 8 }}>{translate('repport.table.title')}</p>
            <Stack spacing={1} mt={1} px={3}>
                {pages.map((page, index) => (
                    <TableSection title={translate(page.title)} number={index + 2}></TableSection>

                ))}

                <TableSection title={translate("repport.table.conclusion")} number={pages?.length + 2}></TableSection>
                <TableSection title={translate('repport.table.supplementary.title')} number={pages?.length + 3}></TableSection>

            </Stack>


        </Box>
    )
}

export default TableMatiers