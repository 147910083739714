import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo } from "react";
// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack, Typography, Button, FormControlLabel, Checkbox, DialogTitle, Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { updateOrganisation, getOrganisation, getAuth, updateStoreFromUser, closeModalOrganisation, openModalOrganisation } from "../../redux/slices/user";
// hooks
import useLocales from "../../hooks/useLocales";
// utils
import axios from "../../utils/axios";
import { fData } from "../../utils/formatNumber";
// components
import {
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
  RHFRadioGroup,
} from "../../components/hook-form";

import '../../styles/organisation.css'
import { get, isEmpty } from "lodash";
import { Add, LogoutOutlined } from "@mui/icons-material";
import { useState } from "react";
import DeleteModal from "src/components/dashboard/DeleteModal";
import { useMutation } from "react-query";
import { deleteUserVetCase, getVetCasesRestricted } from "src/redux/slices/examen";
import { useNavigate } from "react-router";
import { DialogAnimate } from "src/components/animate";
import NewOrganisationForm from "./NewOrganisationForm";
import { style } from "src/components/dashboard/PinModal";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function OrganisationGeneral() {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { translate } = useLocales();
  const [open, setOpen] = useState(false)
  const [userLoading, setUserLoading] = useState(false)

  const { userInOrganisation, organisation, isOpenModalOrganisation } = useSelector(
    (state) => state.user
  );


  const handleCloseModalOrganisation = () => {
    dispatch(closeModalOrganisation());
  };


  const handleAddOrganisation = () => {
    dispatch(openModalOrganisation());
  };
  let user_org_id = get(userInOrganisation, 'id', null)

  const deleteUserInVetCaseMutation = useMutation((ids) => deleteUserVetCase(ids))
  const getUserVetCaseMutation = useMutation(() => getVetCasesRestricted(user_org_id))
  const getUserInVetCaseIds = async () => {
    let { data } = await getUserVetCaseMutation.mutateAsync()
    let cases = get(data, 'hydra:member', [])
    let ids = []
    !isEmpty(cases) && cases.map(vetCase => {
      let _ids = vetCase?.user_in_vet_cases.map(c => c?.id)
      ids.push(..._ids)
    })

    return ids
  }

  const formatIds = (ids = []) => {
    let str = ''
    if (!isEmpty(ids)) {
      str = String(ids[0])
      if (ids.length > 1) {
        for (let index = 1; index < ids.length; index++) {
          if (index == 1) {
            str = str.concat('?additional-id[]=' + ids[index])
          } else {
            str = str.concat('&additional-id[]=' + ids[index])
          }
        }
      }
    }
    return str
  }

  const getUser = async () => {
    try {
      setUserLoading(true)
      const user = await getAuth()


      let last_org = get(user.data, 'last_organisation.id')
      if (last_org) {
        localStorage.setItem("last_user_in_organisation", last_org)
      }


      dispatch(updateStoreFromUser(user.data))
      return user
    } catch (err) {
      enqueueSnackbar(translate('general.error'), {
        variant: 'error'
      })
      return err
    } finally {
      setUserLoading(false)

    }

  }
  const leaveOrg = async () => {
    let ids = await getUserInVetCaseIds()
    let strIds = formatIds(ids)


    deleteUserInVetCaseMutation.mutateAsync(strIds)
      .then(async (result) => {
        enqueueSnackbar(translate('snackbar.leaveSuccessOrg'), {
          variant: 'success'
        })
        localStorage.removeItem('last_user_in_organisation')
        await getUser()
        navigate('/dashboard')
      }).catch(err => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      }).finally(() => {
        setOpen(false)

      })
  }
  const AUTHORIZATION_OPTION = [
    {
      name: translate("organisationAuthorization.auth1.name"),
      subtitle: translate("organisationAuthorization.auth1.subtitle"),
      value: "AUTO_ADD_EMAIL_DOMAIN",
    },
    {
      name: translate("organisationAuthorization.auth2.name"),
      subtitle: translate("organisationAuthorization.auth2.subtitle"),
      value: "MUST_INVITE",
    },
  ];

  const UpdateOrganisationSchema = Yup.object().shape({
    name: Yup.string().required(translate("yup.name.required")),
  });

  const defaultValues = useMemo(
    () => ({
      name: organisation?.name || "",
      photoURL: organisation?.image?.content_url || "",
      description: organisation?.description || "",
      email_domain: organisation?.email_domain || "",
      join_policy: organisation?.join_policy || "AUTO_ADD_EMAIL_DOMAIN",
      must_be_admin_to_create_cases: organisation?.must_be_admin_to_create_cases || false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisation]
  );

  const methods = useForm({
    resolver: yupResolver(UpdateOrganisationSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newOrganisation = {
        name: data.name,
        description: data.description,
        email_domain: data.email_domain,
        join_policy: data.join_policy,
        must_be_admin_to_create_cases: data.must_be_admin_to_create_cases
      };

      if (typeof data.photoURL === "object") {
        const formData = new FormData();
        formData.append("file", data.photoURL);
        const uploadedImage = await axios.post("/uploaded_images", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        newOrganisation.image = uploadedImage.data["@id"];
      }

      dispatch(updateOrganisation(organisation.id, newOrganisation));
      enqueueSnackbar(translate("snackbar.updateSuccess"));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          "photoURL",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const ORG_ID = useMemo(() => {
    return !get(userInOrganisation, 'restricted', false) ? userInOrganisation?.organisation?.id : userInOrganisation?.id
  }, [userInOrganisation])
  console.log({ ORG_ID });
  useEffect(() => {
    dispatch(getOrganisation(ORG_ID));
  }, [dispatch, ORG_ID]);

  useEffect(() => {
    reset(defaultValues);
  }, [organisation]);


  return (
    <>
      <Box display={'flex'} justifyContent={'end'} mb={2}>
        <Button onClick={handleAddOrganisation} variant="contained" startIcon={<Add></Add>}>{translate('modal.addOrg')}</Button>
      </Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: "center" }}>
              <RHFUploadAvatar
                disabled={userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted}
                name="photoURL"
                accept="image/*"
                maxSize={3145728}

                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: "auto",
                      display: "block",
                      textAlign: "center",
                      color: "text.secondary",

                    }}
                  >
                    {translate('user.allowedImg')}
                    <br /> {translate('user.maxSize')} {fData(3145728)}
                  </Typography>
                }
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <LabelStyle>
                {translate("settings.organisationParameters")}
              </LabelStyle>
              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <RHFTextField
                  name="name"
                  label={translate("settings.organisationName")}
                  disabled={userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted}

                />
                <RHFTextField
                  name="description"
                  multiline
                  rows={4}
                  label={translate("settings.organisationDescription")}
                  disabled={userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted}
                />
                <RHFTextField
                  name="email_domain"
                  label={translate("settings.organisationDomains")}
                  disabled={userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted}
                />
              </Stack>

              <Box
                sx={{
                  display: "grid",
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                  mt: 3,
                }}
              >
                <LabelStyle>
                  {translate("organisationAuthorization.title")}
                </LabelStyle>
                <RHFRadioGroup
                  disabled={userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted}
                  name="join_policy"
                  options={AUTHORIZATION_OPTION}
                  sx={{
                    "& .MuiFormControlLabel-root": { mr: 6 },

                  }}
                />
                <LabelStyle>{translate("nav.folders")}</LabelStyle>
                <FormControlLabel
                  style={{
                    color: userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted ? 'gray' : 'black',
                    cursor: userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted ? 'not-allowed' : 'pointer',
                  }}
                  control={
                    <Controller
                      name={'must_be_admin_to_create_cases'}
                      control={control}
                      render={({ field: props }) => (
                        <Checkbox
                          disabled={userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted}
                          {...props}
                          checked={props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={translate('restrictFolderCreation')}
                />
              </Box>
              {
                userInOrganisation?.restricted && <Button size="small" sx={{ mt: 2 }} onClick={() => setOpen(true)} startIcon={<LogoutOutlined></LogoutOutlined>} variant="outlined" color="error">{translate('leaveOrg')}</Button>
              }
              <DeleteModal title={translate('leaveOrg')} subTitle={translate('modal.leaveConfirmation')} onCancel={() => setOpen(false)} handleClose={() => setOpen(false)} open={open} deleteTextButton='team:leave' onDelete={leaveOrg} loading={deleteUserInVetCaseMutation.isLoading || getUserVetCaseMutation.isLoading || userLoading}></DeleteModal>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  disabled={userInOrganisation.type === "MEMBER" || userInOrganisation?.restricted}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ cursor: userInOrganisation.type === "MEMBER" && 'not-allowed !important' }}
                >
                  {translate("nav.save")}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>

      <Modal
        open={isOpenModalOrganisation}
        onClose={handleCloseModalOrganisation}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          borderRadius: 1,
        }}>
          <>
            <NewOrganisationForm
              onCancel={handleCloseModalOrganisation}
              isDialog={true}
            />

          </>
        </Box>
      </Modal >
    </>
  );
}
