import { Accordion } from '@material-ui/core'
import { AccordionSummary, Box, Button, CircularProgress, Collapse, Container, Stack } from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { get, isEmpty, uniqBy } from 'lodash'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { Condition } from 'src/classes/Condition'
import { Examen } from 'src/classes/Examen'
import useLocales from 'src/hooks/useLocales'
import { EqsGraphView } from 'src/sections/eqs'
import DataTable from '../DataTable'
import { SEUILS } from 'src/sections/eqs/Seuils'
import { Icon } from '@iconify/react'

function ExamVisualiser({ examen, conditions }) {
    const { translate } = useLocales()
    const [conditionResult, setConditionResult] = useState([]);
    const [formatedConditions, setFormatedConditions] = useState([]);
    const conditionMutations = useMutation((data) => Examen.getConditionData(data))
    const [open, setOpen] = useState(false)
    //Const
    const variablesOptions = [
        {
            title: translate('examEnums.variables.amplitude'),
            subtitle: translate('examEnums.variables.amplitudeDesc'),
            value: "up",
        },
        {
            title: translate('examEnums.variables.max'),
            subtitle: translate('examEnums.variables.maxDesc'),
            value: "max",
        },
        {
            title: translate('examEnums.variables.min'),
            subtitle: translate('examEnums.variables.minDesc'),
            value: "min",
        },
    ];
    const [variablesMode, setVariablesMode] = useState(JSON.stringify(variablesOptions[0]));

    useEffect(() => {

        if (!examen) return
        getConditions(conditions)


    }, [examen?.external_id, conditions.length])

    useEffect(() => {
        let conditions = new Condition(conditionResult, false)
        conditions.setConditionsColor()
            .setTeteData()
            .setGarrotData()
            .setCroupeData()
        setFormatedConditions(conditions.getData())

    }, [conditionResult])


    const getConditions = (data) => {
        if (!isEmpty(data)) {
            (async () => {
                let temp = await Promise.all(uniqBy(data, 'uuid').map(async (item) => {
                    try {
                        let response = await conditionMutations.mutateAsync({ uuid: item?.uuid, exam_id: examen?.external_id })

                        let data = get(Object.values(response.data), '[0]', {})

                        if (data.hasOwnProperty('error') && !isEmpty(data?.error)) {
                            console.log('error');
                            return 0;
                        }

                        return { ...item, condition: data }
                    } catch (err) {
                        // setConditionError(item)
                    }
                }))
                setConditionResult(temp.filter(el => el))
            })()

        } else {
            setConditionResult([])
        }
    }


    const nbFoulee = useMemo(() => {
        return get(conditionResult, '[0].condition.nbStrides', 0)
    })

    const handleChangeVariablesMode = (value) => {
        setVariablesMode(value);
    };

    if (isEmpty(conditions)) {
        return <></>
    }

    if (conditionMutations.isLoading) {

        return <Box display={'flex'} justifyContent={'center'} my={2}>
            <CircularProgress size={15}></CircularProgress>
        </Box>
    }

    return (
        <>
            
                <Stack spacing={2}>
                    <EqsGraphView
                        rawConditions={conditionResult}
                        isComparaison={true}
                        results={formatedConditions}
                        linesMode={'mean'}
                        nbFoulee={nbFoulee}
                        variablesOptions={variablesOptions}
                        handleChangeVariablesMode={handleChangeVariablesMode}
                        variablesMode={null}
                    />
                    <DataTable linesMode={'mean'} option={'up'} conditions={formatedConditions}></DataTable>
                </Stack>
        </>
    )
}

export default memo(ExamVisualiser)