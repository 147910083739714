import { useLocale } from 'antd/es/locale'
import React from 'react'
import useLocales from 'src/hooks/useLocales'

const highlightedWords = ["antérieur droit", 'antérieur gauche', 'postérieur gauche', 'postérieur droit', 'left forelimb', 'right forelimb', 'left hindlimb', 'right hindlimb']

function Comment({ comments }) {
    const { translate } = useLocales()


    const renderComment = (comment) => {

        let modified = comment

        highlightedWords.forEach(word => {
            modified = modified.replace(
                new RegExp(`(${word})`, 'gi'), // Regex to match the phrase, case-insensitive
                `<span style="color: #503168;">${word}</span>`
            );
        })

        return modified
    }

    return (
        <div className='wrapper_padding' >
            <h4>{translate('label.comment')} : </h4>

            <ul>
                {
                    comments.map((comment, i) => (
                        <li className='comment' key={i} style={{ fontSize: '14px', position: 'relative', listStyleType: 'none', ':before': {}, textAlign: 'justify' }}>
                            {`• `} <span dangerouslySetInnerHTML={{ __html: renderComment(comment) }}></span>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Comment