import React, { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AuthContext } from 'src/contexts/JWTContext'



function JwtGuard({ children }) {
    //Key passed in query Params
    const TOKEN_KEY = 'login_token'

    const [searchParams, setSearchParams] = useSearchParams()
    const authContext = useContext(AuthContext)

    //Token from query params
    const token = searchParams.get(TOKEN_KEY)

    useEffect(() => {
        if (!token) return

        (async () => {
            //we set the token in the session then we delete it from url
            await authContext.updateSession(token)
            
            searchParams.delete(TOKEN_KEY)
            setSearchParams(searchParams)
        })()
    }, [token])

    return (
        <>
            {children}
        </>
    )
}

export default JwtGuard