import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../redux/store";
// components
import Iconify from "../../components/Iconify";
import { TableMoreMenu } from "../../components/table";
import Label from "../../components/Label";
// utils
import createAvatar from "../../utils/createAvatar";

// ----------------------------------------------------------------------

OrganisationTeamTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,

  onEditTeam: PropTypes.func,
  onRequestTeam: PropTypes.func,
  onJoinTeam: PropTypes.func,
  onInviteInTeam: PropTypes.func,
  onLeaveTeam: PropTypes.func,
};

export default function OrganisationTeamTableRow({
  row,
  selected,
  onSelectRow,
  teamClicked,
  onEditTeam,
  onRequestTeam,
  onJoinTeam,
  onInviteInTeam,
  onLeaveTeam,
}) {
  const {
    id,
    name,
    confidentiality_policy,
    membership_type_to_accept_requests,
    user_in_teams,
    limited_access_users,
  } = row;

  const theme = useTheme();

  const [openMenu, setOpenMenuActions] = useState(null);

  const { user } = useSelector((state) => state.user);

  const userInTeam = user_in_teams.filter(
    (_userInTeam) => _userInTeam.user["@id"] === user["@id"]
  );

  const { translate } = useLocales();

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ display: "flex", alignItems: "center",cursor:'pointer' }} onClick={()=>teamClicked(row)}>
        <Avatar alt={name} color={createAvatar(name).color} sx={{ mr: 2 }}>
          {createAvatar(name).name}
        </Avatar>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell>

      <TableCell align="center">
        {user_in_teams && user_in_teams.length}
      </TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (confidentiality_policy === "PUBLIC_IN_ORGANISATION" &&
              "success") ||
            (confidentiality_policy ===
              "ORGANISATION_MEMBER_CAN_REQUEST_ACCESS" &&
              "info") ||
            (confidentiality_policy === "PRIVATE" && "error")
          }
        >
          {translate(confidentiality_policy)}
        </Label>
      </TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (membership_type_to_accept_requests === "MEMBER" && "success") ||
            (membership_type_to_accept_requests === "ADMIN" && "error")
          }
        >
          {translate(membership_type_to_accept_requests)}
        </Label>
      </TableCell>

      <TableCell align="right">
      {userInTeam.length === 0 &&
                confidentiality_policy === "PUBLIC_IN_ORGANISATION" &&
          <Button onClick={onJoinTeam} variant="outlined">
            {translate("team.join")}
          </Button>
                }
      
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
            {
              userInTeam.length > 0  &&  (
                  <MenuItem
                    onClick={() => {
                      onEditTeam();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={"eva:edit-fill"} />
                    {translate("team.edit")}
                  </MenuItem>
              )
            }
             

              {userInTeam.length === 0 &&
                confidentiality_policy ===
                "ORGANISATION_MEMBER_CAN_REQUEST_ACCESS" && (
                  <MenuItem
                    onClick={() => {
                      onRequestTeam();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={"eva:message-square-outline"} />
                    {translate("team.request")}
                  </MenuItem>
                )}

              {userInTeam.length === 0 &&
                confidentiality_policy === "PUBLIC_IN_ORGANISATION" && (
                  <MenuItem
                    onClick={() => {
                      onJoinTeam();
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={"eva:shuffle-2-fill"} />
                    {translate("team.join")}
                  </MenuItem>
                )}

              {userInTeam.length > 0 && (
                <MenuItem
                  onClick={() => {
                    onInviteInTeam();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={"eva:person-add-fill"} />
                  {translate("team.invite")}
                </MenuItem>
              )}

              {userInTeam.length > 0 && (
                <MenuItem
                  onClick={() => {
                    onLeaveTeam();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={"eva:person-delete-outline"} />
                  {translate("team.leave")}
                </MenuItem>
              )}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
