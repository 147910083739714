import { Autocomplete, Chip, FormHelperText, TextField, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import useLocales from 'src/hooks/useLocales'

function MultiEmail({ onChange,onInputChange }) {
    const [emails, setEmails] = useState([])
    const [errors, setErrors] = useState([])
    const { translate } = useLocales()
    useEffect(() => {
        setErrors([])
        if (!isEmpty(emails)) {
            emails.forEach(email => {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                    setErrors(err => [...err, translate('yup.email.adressInvalid', { adress: email })])
                }
            })
        }
        onChange(emails, errors)
    }, [emails])

    return (
        <>
            <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                className='multiMailInput'
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                onChange={(e, val) => {
                    setEmails(val)
                }}
                renderInput={(params) => (
                    <TextField
                        error={errors.length > 0}
                        {...params}
                        variant="outlined"
                        onChange={e => onInputChange(e.target.value)}
                        label="Emails"
                        placeholder="Emails"
                    />
                )}
            />
            {
                errors.map(error => (
                    <Typography variant='caption' color='red'>{error}</Typography>
                ))
            }
            <FormHelperText>{translate('multiMailHint')}</FormHelperText>
        </>
    )
}

export default MultiEmail