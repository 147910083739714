import { Alert, Box } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useLocales from 'src/hooks/useLocales'

function EndingSoonBanner() {
    const { user } = useSelector(state => state.user)
    const [open,setOpen] = useState(true)
    const { translate } = useLocales()
    if (!user?.ecg_trial_end_date) return <></>

    if(!open) return <></>
    return (
        <Alert severity='warning' sx={{ position: 'fixed', top: '60px', width: '100vw', left: 0, zIndex: 100, paddingBottom: 0 }} onClose={()=>setOpen(false)}>
            {translate('general.trialEnd', { date: dayjs(user?.ecg_trial_end_date).format('DD/MM/YYYY') })}

        </Alert>
    )
}

export default EndingSoonBanner