import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// assets
import arioneoLogo from '../assets/icon_logo.png'

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const BACKGROUND_COLOR = theme.palette.mode === 'dark' ? 'white' : ''

  const logo = (
    <Box sx={{ width: 60, height: 60, backgroundColor: BACKGROUND_COLOR, ...sx }}>
      <img
      style={{mixBlendMode:'multiply'}}
        src={`${arioneoLogo}?w=161&fit=crop&auto=format`}
        srcSet={`${arioneoLogo}?w=161&fit=crop&auto=format&dpr=2 2x`}
        alt="arioneoLogo"
        loading="lazy"
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
