import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
  Radio,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";

// ----------------------------------------------------------------------

RHFRadioGroup.propTypes = {
  name: PropTypes.string,
  subtitle: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
};

export default function RHFRadioGroup({
  name,
  subtitle,
  options,
  getOptionLabel,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} {...other}>
            {options.map((option, index) => (
              <div key={option.name}>
                <FormControlLabel
                  disabled={other.disabled}
                  key={option.name}
                  value={option.value}
                  control={<Radio />}
                  label={
                    getOptionLabel?.length ? getOptionLabel[index] : option.name
                  }
                />
                {option.subtitle && (
                  <FormHelperText sx={{ px: 3.5, mt: 0, mb: 1 }}>
                    {option.subtitle}
                  </FormHelperText>
                )}
              </div>
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
