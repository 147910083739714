import { get } from "lodash"
import { LinesMode } from "src/sections/eqs/EqsWindow"
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import AnnotationsFactory from "highcharts/modules/annotations";
highchartsMore(Highcharts);
AnnotationsFactory(Highcharts);


export class Visualizer {
    constructor(dataSet = [], linesMode, variablesMode, selectedConditions = [], showChartDetails = true) {
        this.dataSet = dataSet
        this.linesMode = linesMode
        this.variablesMode = variablesMode
        this.selectedConditions = selectedConditions
        this.markers = []
        this.lines = []
        this.courbes = []
        this.annotationLabels = []
        this.annotations = []
        this.showChartDetails = showChartDetails
    }

    isCurrentCondition(condition) {
        let selectedCondition = get(this.selectedConditions, '[0].uuid')
        return condition?.uuid == selectedCondition
    }

    init() {
        this.dataSet.forEach(dataset => {

            const data = dataset?.data?.mean?.filter(Number).map((str) => {
                return Number(str) * 100;
            }) || [];

            const dataRangeInf = dataset?.data?.inf?.filter(Number).map((str) => {
                return Number(str) * 100;
            }) || [];

            const dataRangeSup = dataset?.data?.sup?.filter(Number).map((str) => {
                return Number(str) * 100;
            }) || [];

            const dataRange = dataRangeInf.map(function (e, i) {
                return [e, dataRangeSup[i]];
            });





            if (this.selectedConditions.length == 1 && this.isCurrentCondition(dataset)) {
                if (this.variablesMode && JSON.parse(this.variablesMode).value === "up") {
                    const markers = [
                        {
                            xMin: get(dataset, 'tg.min', 0),
                            xMax: get(dataset, 'tg.max', 0),
                            value: get(dataset, 'vg.up.mean', 0),
                        },
                        {
                            xMin: get(dataset, 'td.min', 0),
                            xMax: get(dataset, 'td.max', 0),
                            value: get(dataset, 'vd.up.mean', 0),
                        },
                    ];
                    markers.map(({ xMin, xMax, value }) => {
                        if (xMin < 151 && xMax < 151) {
                            //VERTICAL
                            this.markers.push({
                                data: [
                                    [xMin, data[xMin]],
                                    [xMin, (data[xMin] + data[xMax]) / 2],
                                    {
                                        x: xMin,
                                        y: data[xMax],
                                        marker: {
                                            symbol: "triangle",
                                            lineColor: dataset.color,
                                            lineWidth: 2,
                                            radius: 4,
                                        },
                                    },
                                ],
                                type: "line",
                                dashStyle: "Solid",
                                lineWidth: 1.5,
                                color: dataset.color,
                                opacity: 1,
                                marker: {
                                    enabled: true,
                                    radius: 0,
                                },
                            });
                            //HORIZONTAL
                            this.markers.push({
                                data: [
                                    [xMin, data[xMax]],
                                    [xMax, data[xMax]],

                                ],
                                type: "line",
                                dashStyle: "Dash",
                                lineWidth: 1.5,
                                color: dataset.color,
                                opacity: 1,
                                marker: {
                                    enabled: true,
                                    radius: 0,
                                },
                            });
                            //LABELS
                            this.annotationLabels.push({
                                point: {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: xMin,
                                    y: (data[xMin] + data[xMax]) / 2,
                                },
                                // x: 30,
                                text: Highcharts.numberFormat(value, 1) + " cm",
                            });
                        }
                    });
                    this.annotations.push(
                        {
                            draggable: "",
                            labels: this.annotationLabels,
                            labelOptions: {
                                style: {
                                    fontWeight: "bold",
                                    color: "black",
                                },
                                shape: {
                                    type: "rect",
                                },
                                align: "left",
                                verticalAlign: "middle",
                                justify: false,
                                crop: true,
                                y: 0,
                                color: "black",
                                backgroundColor: dataset.color,
                                borderColor: dataset.color,
                            },
                        })

                } else if (this.variablesMode && JSON.parse(this.variablesMode).value === "max") {

                    const xG = get(dataset, 'tg.max', 0);
                    const xD = get(dataset, 'td.max', 0);

                    if (xD < 151 && xD < 151) {
                        const yG = data[xG];
                        const yD = data[xD];
                        //HORIZONTAL 1
                        this.markers.push({
                            data: [
                                [xG, yG],
                                [xD, yG],
                            ],
                            type: "line",
                            dashStyle: "Dash",
                            lineWidth: 1.5,
                            color: dataset.color,
                            opacity: 1,
                            marker: {
                                enabled: true,
                                radius: 0,
                            },
                        });
                        //HORIZONTAL 2
                        this.markers.push({
                            data: [
                                [xG, yD],
                                [xD, yD],
                            ],
                            type: "line",
                            dashStyle: "Dash",
                            lineWidth: 1.5,
                            color: dataset.color,
                            opacity: 1,
                            marker: {
                                enabled: true,
                                radius: 0,
                            },
                        });
                        //LABELS
                        this.annotationLabels.push({
                            point: {
                                xAxis: 0,
                                yAxis: 0,
                                x: (xG + xD) / 2,
                                y: (yG + yD) / 2,
                            },
                            text: Highcharts.numberFormat(Math.abs(yD - yG), 1) + " cm",
                        });

                        this.annotations.push(
                            {
                                draggable: "",
                                labels: this.annotationLabels,
                                labelOptions: {
                                    style: {
                                        fontWeight: "bold",
                                        color: "black",
                                    },
                                    shape: {
                                        type: "rect",
                                    },
                                    align: "center",
                                    verticalAlign: "middle",
                                    justify: false,
                                    crop: true,
                                    y: 0,
                                    color: "black",
                                    backgroundColor: dataset.color,
                                    borderColor: dataset.color,
                                },
                            },
                        )
                    }
                } else if (this.variablesMode && JSON.parse(this.variablesMode).value === "min") {

                    const xG = get(dataset, 'tg.min', 0);
                    const xD = get(dataset, 'td.min', 0);

                    if (xD < 151 && xD < 151) {
                        const yG = data[xG];
                        const yD = data[xD];
                        //HORIZONTAL 1
                        this.markers.push({
                            data: [
                                [xG, yG],
                                [xD, yG],
                            ],
                            type: "line",
                            dashStyle: "Dash",
                            lineWidth: 1.5,
                            color: dataset.color,
                            opacity: 1,
                            marker: {
                                enabled: true,
                                radius: 0,
                            },
                        });
                        //HORIZONTAL 2
                        this.markers.push({
                            data: [
                                [xG, yD],
                                [xD, yD],
                            ],
                            type: "line",
                            dashStyle: "Dash",
                            lineWidth: 1.5,
                            color: dataset.color,
                            opacity: 1,
                            marker: {
                                enabled: true,
                                radius: 0,
                            },
                        });
                        //LABELS
                        this.annotationLabels.push({
                            point: {
                                xAxis: 0,
                                yAxis: 0,
                                x: (xG + xD) / 2,
                                y: (yG + yD) / 2,
                            },
                            text: Highcharts.numberFormat(Math.abs(yD - yG), 1) + " cm",
                        });

                        this.annotations.push(
                            {
                                draggable: "",
                                labels: this.annotationLabels,
                                labelOptions: {
                                    style: {
                                        fontWeight: "bold",
                                        color: "black",
                                    },
                                    shape: {
                                        type: "rect",
                                    },
                                    align: "center",
                                    verticalAlign: "middle",
                                    justify: false,
                                    crop: true,
                                    y: 0,
                                    color: "black",
                                    backgroundColor: dataset.color,
                                    borderColor: dataset.color,
                                },
                            },
                        )
                    }
                }
            }



            if (this.dataSet.length === 1) {
                if (this.linesMode === LinesMode.Mean) {
                    this.courbes.push({
                        data: dataRange,
                        type: "arearange",
                        lineWidth: 0,
                        linkedTo: ":previous",
                        color: dataset.color,
                        zIndex: 0,
                        marker: {
                            enabled: false,
                        },
                    });
                } else {
                    for (let i = 0; i < dataset.nbStrides; i++) {
                        const strideData = dataset?.data?.strides[i]?.filter(Number).map((str) => {
                            return Number(str) * 100;
                        }) || [];
                        const serie = {
                            data: strideData,
                            name: dataset.name,
                            type: dataset.type,
                            color: dataset.color,
                            opacity: 0.4,
                            lineWidth: 1,
                            animation: false,
                            marker: {
                                enabled: false,
                            },
                        };
                        this.lines.push(serie);
                    }
                }
            }

            this.courbes.push({
                data: data,
                name: dataset.name,
                type: dataset.type,
                color: dataset.color,
                opacity: 1,
                lineWidth: 3,
                marker: {
                    enabled: false,
                },
            })
        })
    }

    set Coubres(courbes) {
        this.courbes.push(courbes)
    }

    getAnnotations() {
        return this.annotations
    }

    getData() {
        return this.showChartDetails ? [...this.courbes, ...this.markers, ...this.lines] : this.courbes
    }
    getLines() {
        return [...this.courbes, ...this.lines]
    }
}