import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Iconify from '../Iconify'
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover'
import useLocales from 'src/hooks/useLocales'

function Header() {
  const { translate } = useLocales()
  return (
    <Box className='home_header' display={'flex'} top={0} justifyContent={'space-between'} alignItems={'center'} height={'60px'} px={{ md: 15, xs: 2 }} position={'fixed'} width={'100%'}>
      <a className='styled_button' target='_blank' rel='noreferrer' href='https://vet.arioneo.com'>
        {translate('discover')} <Iconify icon={'line-md:arrow-right'}></Iconify></a>
      <Box display={'flex'} gap={3} alignItems={'center'}>
        <Typography component={'a'} href='https://vet.arioneo.com/equisymknowledgebase/' sx={{textDecoration:'none'}} target='_blank' textTransform={'uppercase'} fontSize={{md:14,xs:12}} fontWeight={500} color={'white'}>{translate('assistance')}</Typography>
        <LanguagePopover></LanguagePopover>

        <a className='styled_button' href='/auth/login'>
          <Iconify icon={'ic:sharp-account-circle'}></Iconify>
          {translate('auth.login')}
        </a>
      </Box>
    </Box>
  )
}

export default Header