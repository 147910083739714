import { AdminPanelSettingsOutlined, Close, PushPin } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import useLocales from 'src/hooks/useLocales';
import { setPinned } from 'src/redux/slices/examen';
import { PATH_DASHBOARD } from 'src/routes/paths';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};
function UpdateUserModal({ open, handleClose, item, onSubmit, loading }) {
    const { translate } = useLocales()
    const [error, setError] = useState(false)
    const [role, setRole] = useState("")
    const updateUser = () => {
            onSubmit({
                type: role,
            })

    }

    useEffect(() => {
        setRole(item?.type)
    }, [item])

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <AdminPanelSettingsOutlined color='primary' fontSize='small' />
                        <Typography color='primary' variant='h6'>{translate("modal.updateUser")}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>

                {/* Body */}
                <Grid container spacing={2} marginTop={1}>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>

                            <Select
                                value={role}
                                label="Role"
                                onChange={e => setRole(e.target.value)}
                            >
                                <MenuItem value={'MEMBER'}>{translate('MEMBER')}</MenuItem>
                                <MenuItem value={'ADMIN'}>{translate('ADMIN')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} display='flex' justifyContent='end' flexDirection='row' gap={1}>
                        <LoadingButton loading={loading} onClick={updateUser} sx={{ marginTop: '0 !important' }} variant='contained'>{translate('gridOptions.update')}</LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
UpdateUserModal.defaultProps = {
    open: false,
    handleClose: () => { },
}
export default UpdateUserModal