import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useState, useEffect } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Stack,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";
// _mock_
import { _notifications } from "../../../_mock";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import {
  acceptUserInOrganisations,
  declineUserInOrganisations,
} from "../../../redux/slices/user";
import {
  acceptUserInTeams,
  declineUserInTeams,
} from "../../../redux/slices/team";
// components
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";
import { get, isEmpty } from "lodash";
import useLocales from "src/hooks/useLocales";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState(_notifications);
  const { translate } = useLocales()
  const dispatch = useDispatch();

  const { user, userInOrganisation, organisation } = useSelector(
    (state) => state.user
  );

  const pendingUserInOrganisations = user?.user_in_organisations?.filter(
    (item) => item?.accepted === false
  );

  let userInTeams = get(user, 'user_in_teams', []) || []

  const pendingUserInTeams = isEmpty(userInTeams) ? [] : userInTeams.filter(
    (item) =>
      item.accepted === false &&
      item.team.organisation.id === userInOrganisation.organisation.id &&
      (item.team.confidentiality_policy === "PUBLIC_IN_ORGANISATION" ||
        item.team.confidentiality_policy === "PRIVATE")
  );

  const pendingRequestUserInTeams = organisation?.teams.map((team) => {
    return team.user_in_teams.filter(
      (item) =>
        item.accepted === false && //Invitation pas encore acceptée
        item.user["@id"] !== user["@id"] && //Ou je ne suis pas concerné
        team.confidentiality_policy ===
        "ORGANISATION_MEMBER_CAN_REQUEST_ACCESS" && //Qui nécéssite une validation
        (team.membership_type_to_accept_requests === "MEMBER" || //Soit par tous les membres
          userInOrganisation.type === "ADMIN") //Soit si je suis admin
    );
  });

  const pendings = [];

  !isEmpty(pendingUserInOrganisations) && pendingUserInOrganisations.map((item) => {
    pendings.push({
      createdAt: new Date(),
      id: item.id,
      isUnRead: false,
      title: `Vous avez été invité à rejoindre la clinique ${item.organisation?.name}`,
      description: "",
      type: "user_in_organisations",
      avatar: "",
    });
  });

  !isEmpty(pendingUserInTeams) && pendingUserInTeams.map((item) => {
    pendings.push({
      createdAt: new Date(),
      id: item.id,
      isUnRead: false,
      title: `Vous avez été invité à rejoindre l'équipe ${item.team?.name}`,
      description: "",
      type: "user_in_teams",
      avatar: "",
      withButtons: true,
    });
  });

  !isEmpty(pendingRequestUserInTeams) && pendingRequestUserInTeams?.map((_item) => {
    _item.map((item) => {
      const teams = organisation?.teams.filter(
        (_team) => _team["@id"] === item.team
      );

      pendings.push({
        createdAt: new Date(),
        id: item.id,
        isUnRead: false,
        title: `${item.user.firstname} ${item.user.lastname}`,
        description: `souhaite rejoindre l'équipe ${teams[0].name}`,
        type: "user_in_teams",
        avatar: "",
      });
    });
  });

  const totalUnRead = pendings.length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  useEffect(() => { }, [user, organisation]);

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="bi:bell" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('settings.tabNotifications')}</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {translate('settings.notificationHint', { count: totalUnRead })}
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "60vh" } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                {translate('settings.pendingNotifications')}
              </ListSubheader>
            }
          >
            {pendings.map((item) => (
              <NotificationItem key={item.name} notification={item} />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 10).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List> */}
        </Scrollbar>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            {translate('general.viewAll')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
    withButtons: PropTypes.bool,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  const dispatch = useDispatch();

  const { organisation } = useSelector((state) => state.user);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Stack direction="column" spacing={0.5}>
            {title}
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  notification.type === "user_in_organisations" &&
                    dispatch(acceptUserInOrganisations(notification.id));
                  notification.type === "user_in_teams" &&
                    dispatch(
                      acceptUserInTeams(notification.id, organisation.id)
                    );
                }}
              >
                Accepter
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  notification.type === "user_in_organisations" &&
                    dispatch(declineUserInOrganisations(notification.id));
                  notification.type === "user_in_teams" &&
                    dispatch(
                      declineUserInTeams(notification.id, organisation.id)
                    );
                }}
              >
                Refuser
              </Button>
            </Stack>
          </Stack>
        }
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 1, width: 16, height: 16 }}
            />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
