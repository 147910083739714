import { Button, Divider, Stack } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import useLocales from 'src/hooks/useLocales';
import { isEmpty } from 'lodash';

function RegisterProvider({ withDivider, data, handleClick }) {

  const { translate } = useLocales()

  return (
    <Stack spacing={1} marginTop={1} direction='column'>
      <Stack direction={'row'} spacing={1}>
        {
          data.map(e => (
            <Button onClick={()=>handleClick(e.link)} variant='contained'>{translate(e.title)}</Button>
          ))
        }
      </Stack>

    </Stack>
  )
}
RegisterProvider.defaultProps = {
  withDivider: true,
  data: []
}

RegisterProvider.propTypes = {
  withDivider: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    link: PropTypes.string.isRequired,
  }))
}

export default RegisterProvider