import { ArticleOutlined, SaveAltOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Chip, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal, Typography } from '@mui/material'
import axios from 'axios';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React from 'react'
import useLocales from 'src/hooks/useLocales';
import axiosInstance from 'src/utils/axios';
import Iconify from '../Iconify';

DownloadReportModal.defaultProps = {
    items: []
}
function DownloadReportModal({ loading, open, handleClose, title, subTitle, onCancel, items }) {
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();

    const download = (link) => {
        axios.get(link, {
            responseType: 'blob'
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            let name = 'rapport.pdf'
            try {
                let headerName = response.headers
                console.log({ headerName });
                name = headerName
            } catch (err) {
                name = 'rapport.pdf'
            }

            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch(err => {
            enqueueSnackbar(translate('general.error'), {
                variant: 'error'
            })
        });
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography fontWeight={600} alignItems='center' display='flex' gap={1} id="modal-modal-title" variant="h6" >
                    <Iconify color='primary' icon='solar:download-outline' width={21} height={21} />
                    {title}
                </Typography>
                <Typography id="modal-modal-title" marginLeft={4} marginTop={1} variant="body2" >
                    {subTitle}
                </Typography>
                <List dense>
                    {items.map(item => (
                        <ListItem key={item.id} sx={{ marginBottom: 2 }} disablePadding secondaryAction={
                            <a href={item.pdf_url}>
                                <IconButton>
                                    <SaveAltOutlined fontSize='small' color='primary'></SaveAltOutlined>
                                </IconButton>
                            </a>

                        }>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: 'limegreen' }}>
                                    <ArticleOutlined sx={{ color: 'white' }}></ArticleOutlined>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ flex: 2 }} primary={dayjs(item.creation_date).format('DD/MM/YYYY HH:mm')}
                                secondary={<Chip label={translate('general.published')} size='small' variant='outlined' color='primary'></Chip>}

                            />
                        </ListItem>
                    ))}

                </List>
                <Box width='100%' marginTop={5} display='flex' justifyContent='end' alignItems='center' gap={2}>
                    <Button disabled={loading} onClick={onCancel} variant='outlined'>{translate('nav.cancel')}</Button>
                </Box>
            </Box>

        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '500px',
    p: 2,
    borderRadius: 1
};

export default DownloadReportModal