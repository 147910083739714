import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Examen } from 'src/classes/Examen';
import useLocales from 'src/hooks/useLocales';

function EqsVideoDownload({ uuid, onComplete }) {
    const { translate } = useLocales()
    const [progress, setProgress] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [color, setColor] = useState('secondary')

    const controller = new AbortController();

    const cancelDownload = () => {
        setIsLoading(false)
        controller.abort()
        setProgress(0)
    }

    const { enqueueSnackbar } = useSnackbar();
    const startDownload = () => {
        setProgress(0)

        if (!uuid) {
            enqueueSnackbar(translate('examVideo.selectCondition'), {
                variant: 'warning'
            })
        } else {
            //STart download
            setIsLoading(true)
            Examen.getConditionVideo(uuid, {
                signal: controller.signal,
                onDownloadProgress: progressEvent => {
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(percentage);
                    if (percentage === 100) {

                    }
                }
            }).then(res => {
                //Send link and close modal
                enqueueSnackbar(translate('examVideo.successDownload'), {
                    variant: 'success',
                })
                setTimeout(() => {
                    onComplete(res.data.url)
                }, 1000)
            }).catch(err => {
                enqueueSnackbar(translate('examVideo.noVideo'), {
                    variant: 'error'
                })
                setProgress(0)

            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    return (
        <Box display={'flex'} flexDirection='column' gap={3} justifyContent='center' alignItems={'center'} position={'absolute'} width={'100%'} height='100%' bgcolor={'white'} zIndex={11} top={150}>
            <CircularProgressWithLabel color={color} value={progress} />
            <Typography fontWeight={'bold'}>{isLoading ? translate('examVideo.downloading') : translate('examVideo.downloadToContinue')}</Typography>
            <Button variant='text' onClick={isLoading ? cancelDownload : startDownload}>{!isLoading ? translate('examVideo.startDownload') : translate('examVideo.stopDownload')}</Button>
        </Box>
    )
}



//Circular progress with value
function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box display={'flex'} justifyContent='center' alignItems={'center'}>
                <CircularProgress thickness={2} sx={{ zIndex: 1 }} color={props.color} size={120} variant="determinate" {...props} />
                <CircularProgress thickness={2} sx={{ color: 'lightgrey', position: 'absolute' }} value={100} size={120} variant="determinate" />
            </Box>

            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography fontWeight={'bold'} variant="body1" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default EqsVideoDownload