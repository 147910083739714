import { ArticleOutlined, CreateNewFolder, Refresh, SaveAltOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Avatar, Box, Button, Chip, CircularProgress, Divider, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, Select, Typography } from '@mui/material'
import axios from 'axios';
import dayjs from 'dayjs';
import { get, isNull } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import useLocales from 'src/hooks/useLocales';
import { getVetCases, getVetCasesRestricted } from 'src/redux/slices/examen';
import AddVetCaseModal from '../horses/AddVetCaseModal';
import { getHorses } from 'src/redux/slices/horse';
import { Examen } from 'src/classes/Examen';
import Iconify from '../Iconify';


AddExamToCase.defaultProps = {
    items: [],
    showHint: false,
}
function AddExamToCase({ showHint, loading, open, handleClose, title, subTitle, onCancel, items, handleSubmit, casesLoading, item, defaultHorse, skipAddToVet }) {
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();
    const [selectedCase, setSelectedCase] = useState(null)
    const { userInOrganisation, user } = useSelector((state) => state.user);
    const [vetCases, setVetCases] = useState([])
    const [vetModal, setVetModal] = useState(false)
    const query = useQueryClient()
    const dispatch = useDispatch()
    let org_id = get(userInOrganisation, 'organisation.id', null)
    let user_org_id = get(userInOrganisation, 'id', null)

    let id = get(userInOrganisation, 'restricted', false) ? user_org_id : org_id

    //Queries
    const { isLoading: isHorsesLoading, data: horsesData } = useQuery(
        ['horses', id],
        () => dispatch(getHorses(id)),
        {
            enabled: !isNull(id),
            staleTime: 10 * 60 * 1000
        }
    )
    //------------------------------------------------
    let userTeams = get(user, 'user_in_teams', [])

    const _teams = userTeams.filter(
        (_team) => _team?.team?.organisation?.id === userInOrganisation?.organisation?.id
    )
    const _horses = get(horsesData, 'data.hydra:member', [])
    //End Queries


    const checkExam = (vetCase, exam) => {
        let examsIds = vetCase?.exam_in_vet_cases.map(e => get(e, 'exam.@id'))
        if (examsIds.includes(get(exam, '@id'))) {
            skipAddToVet(vetCase)
        }
        query.refetchQueries({ queryKey: ['exams', id] })
        query.refetchQueries(['vet_cases', defaultHorse?.external_id])
        query.refetchQueries(['horse_exams', defaultHorse?.external_id])
        query.refetchQueries(['vet_case', defaultHorse?.external_id])


    }

    const { isLoading, data: vetCaseData, isFetching, refetch } = useQuery(
        ['vet_cases', defaultHorse?.external_id],
        () => Examen.getHorseVetCases({ id: defaultHorse?.external_id, excludeVetCasesContainingExam: item?.external_id }),
        {
            enabled: open,
            staleTime: 10 * 60 * 1000,
            onSuccess: (data) => {
                let _vetCases = get(data, 'data.hydra:member', [])
                setVetCases(_vetCases)
            }
        }
    )

    useEffect(() => {
        if (open && item?.external_id) {
            refetch()
        }
    }, [open, item?.external_id])

    const openCreateVetCase = () => {
        setVetModal(true)
    }

        

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            keepMounted={false}
        >
            <Box sx={style}>
                <Typography alignItems='center' display='flex' gap={1} id="modal-modal-title" variant="h6" fontWeight={600}>
                    <Iconify width={21} height={21} icon='hugeicons:folder-add'></Iconify>
                    {title}
                </Typography>
                <Typography id="modal-modal-title" marginLeft={4} marginTop={1} variant="body2" component="h2">
                    {subTitle}
                </Typography>
                {
                    (isLoading || isFetching)
                        ? <Box display={'flex'} my={2} justifyContent={'center'} alignItems={'center'}><CircularProgress size={20}></CircularProgress></Box>
                        :
                        <>
                            {
                                showHint && <Alert Alert severity="info" sx={{ m: 1, alignItems: 'center' }}>
                                    <Typography variant='inherit' fontSize={13}> {translate('addToFolderHint')}</Typography>
                                </Alert>
                            }
                            <FormControl fullWidth >
                                <InputLabel id="demo-simple-select-label">{translate('nav.folders')}</InputLabel>
                                <Select

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedCase}
                                    label={translate('nav.folders')}
                                    onChange={(e) => { setSelectedCase(e.target.value) }}
                                >
                                    {vetCases.map(item => (
                                        <MenuItem value={get(item, '@id')}>{item?.case_number ?? translate('general.unamed_folder')}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </>

                }
                {/* Add new VetCase modal  */}
                <Button onClick={openCreateVetCase} size='small' sx={{ mt: 1 }}>{translate('addCase.createNewCase')}</Button>
                <AddVetCaseModal defaultHorse={defaultHorse} items={[item]} onRequestRefresh={(vetcase) => checkExam(vetcase, item)}
                    teams={_teams} horses={_horses} open={vetModal} handleClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            setVetModal(false)
                        }
                    }} />


                <Box width='100%' marginTop={5} display='flex' justifyContent='end' alignItems='center' gap={2}>
                    <Button disabled={loading} onClick={() => {
                        setSelectedCase(null)
                        onCancel()
                    }} color='error'>{translate('nav.cancel')}</Button>
                    <LoadingButton disabled={isNull(selectedCase)} type='submit' loading={loading} onClick={() => handleSubmit(selectedCase)} color='primary' variant='contained'>{translate('nav.save')}</LoadingButton>

                </Box>
            </Box>

        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '500px',
    p: 2,
    borderRadius: 1
};

export default AddExamToCase