export const SEUILS = [
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.up",
      "seuilG_inf": -53.1,
      "seuilG": -36.1,
      "seuilG_sup": -24.5,
      "seuilD_inf": 15.5,
      "SeuilD": 24.3,
      "seuilD_sup": 46.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.max",
      "seuilG_inf": -22.6,
      "seuilG": -9.8,
      "seuilG_sup": -4,
      "seuilD_inf": -1.1,
      "SeuilD": 4,
      "seuilD_sup": 7.2,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.min",
      "seuilG_inf": -41,
      "seuilG": -29.8,
      "seuilG_sup": -24,
      "seuilD_inf": -3.5,
      "SeuilD": 6.4,
      "seuilD_sup": 11.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.up",
      "seuilG_inf": -13,
      "seuilG": -10.4,
      "seuilG_sup": -8.4,
      "seuilD_inf": 6.6,
      "SeuilD": 9.8,
      "seuilD_sup": 16.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.max",
      "seuilG_inf": -5.7,
      "seuilG": -2.4,
      "seuilG_sup": 0.1,
      "seuilD_inf": 3.3,
      "SeuilD": 5.5,
      "seuilD_sup": 8.4,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.min",
      "seuilG_inf": -13.5,
      "seuilG": -10.2,
      "seuilG_sup": -8.2,
      "seuilD_inf": -1,
      "SeuilD": 2.6,
      "seuilD_sup": 6.4,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.up",
      "seuilG_inf": 6.7,
      "seuilG": 10.6,
      "seuilG_sup": 14.9,
      "seuilD_inf": -7.8,
      "SeuilD": -2.5,
      "seuilD_sup": 0.5,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.max",
      "seuilG_inf": -1.8,
      "seuilG": 1.3,
      "seuilG_sup": 3.5,
      "seuilD_inf": -6.9,
      "SeuilD": -5.3,
      "seuilD_sup": -3.8,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.min",
      "seuilG_inf": -2.3,
      "seuilG": 4.5,
      "seuilG_sup": 6.9,
      "seuilD_inf": 1.5,
      "SeuilD": 3.8,
      "seuilD_sup": 6.9,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.up",
      "seuilG_inf": -39.1,
      "seuilG": -19.1,
      "seuilG_sup": 3.1,
      "seuilD_inf": -9.8,
      "SeuilD": 3.8,
      "seuilD_sup": 14.6,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.max",
      "seuilG_inf": -14.1,
      "seuilG": -4.2,
      "seuilG_sup": 6.1,
      "seuilD_inf": -14.7,
      "SeuilD": -4.9,
      "seuilD_sup": 1.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.min",
      "seuilG_inf": -51.4,
      "seuilG": -25,
      "seuilG_sup": -14.7,
      "seuilD_inf": 3.1,
      "SeuilD": 7.6,
      "seuilD_sup": 14.6,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.up",
      "seuilG_inf": -6,
      "seuilG": 2.8,
      "seuilG_sup": 6.3,
      "seuilD_inf": -18.8,
      "SeuilD": -10.6,
      "seuilD_sup": -5.7,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.max",
      "seuilG_inf": 2.9,
      "seuilG": 6.1,
      "seuilG_sup": 9.1,
      "seuilD_inf": -3.5,
      "SeuilD": -0.4,
      "seuilD_sup": 3.4,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.min",
      "seuilG_inf": -6.4,
      "seuilG": -1,
      "seuilG_sup": 1.2,
      "seuilD_inf": -9.2,
      "SeuilD": -6,
      "seuilD_sup": -3.8,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.up",
      "seuilG_inf": -20,
      "seuilG": -12,
      "seuilG_sup": -8.2,
      "seuilD_inf": 12.3,
      "SeuilD": 15.9,
      "seuilD_sup": 20.7,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.max",
      "seuilG_inf": -14.6,
      "seuilG": -8.6,
      "seuilG_sup": -3.2,
      "seuilD_inf": 2.2,
      "SeuilD": 6.3,
      "seuilD_sup": 8.7,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "LDD",
      "Figure": 1,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.min",
      "seuilG_inf": -6.9,
      "seuilG": -1.2,
      "seuilG_sup": 4.4,
      "seuilD_inf": 3.4,
      "SeuilD": 10.6,
      "seuilD_sup": 15.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.up",
      "seuilG_inf": -14.4,
      "seuilG": 1.2,
      "seuilG_sup": 14.2,
      "seuilD_inf": 42.9,
      "SeuilD": 50.5,
      "seuilD_sup": 69.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.max",
      "seuilG_inf": -11.8,
      "seuilG": 2.5,
      "seuilG_sup": 13.9,
      "seuilD_inf": -11.9,
      "SeuilD": 7,
      "seuilD_sup": 11.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.min",
      "seuilG_inf": -20.3,
      "seuilG": -7.7,
      "seuilG_sup": 14.3,
      "seuilD_inf": 22.1,
      "SeuilD": 28.1,
      "seuilD_sup": 38.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.up",
      "seuilG_inf": -22.6,
      "seuilG": -12.5,
      "seuilG_sup": -7.5,
      "seuilD_inf": 5,
      "SeuilD": 9.2,
      "seuilD_sup": 14.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.max",
      "seuilG_inf": -24.5,
      "seuilG": -21.2,
      "seuilG_sup": -15.1,
      "seuilD_inf": -17.6,
      "SeuilD": -13.7,
      "seuilD_sup": -10.2,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.min",
      "seuilG_inf": 7.1,
      "seuilG": 10.9,
      "seuilG_sup": 14.3,
      "seuilD_inf": 23,
      "SeuilD": 27.3,
      "seuilD_sup": 37.6,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.up",
      "seuilG_inf": 8.9,
      "seuilG": 16.2,
      "seuilG_sup": 24,
      "seuilD_inf": 6.5,
      "SeuilD": 12.8,
      "seuilD_sup": 21.8,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.max",
      "seuilG_inf": 2.8,
      "seuilG": 4.8,
      "seuilG_sup": 12.4,
      "seuilD_inf": -10,
      "SeuilD": -5,
      "seuilD_sup": -2.6,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.min",
      "seuilG_inf": 11.9,
      "seuilG": 16,
      "seuilG_sup": 22.4,
      "seuilD_inf": 4.7,
      "SeuilD": 12.1,
      "seuilD_sup": 15.2,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.up",
      "seuilG_inf": -27.9,
      "seuilG": 4.8,
      "seuilG_sup": 24.2,
      "seuilD_inf": 19.6,
      "SeuilD": 36.7,
      "seuilD_sup": 56.2,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.max",
      "seuilG_inf": 3.3,
      "seuilG": 12.1,
      "seuilG_sup": 20.1,
      "seuilD_inf": -4.3,
      "SeuilD": 15.2,
      "seuilD_sup": 31.5,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.min",
      "seuilG_inf": -45.2,
      "seuilG": -1.6,
      "seuilG_sup": 31.2,
      "seuilD_inf": 11.3,
      "SeuilD": 22,
      "seuilD_sup": 33.2,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.up",
      "seuilG_inf": 3.6,
      "seuilG": 10.9,
      "seuilG_sup": 18.7,
      "seuilD_inf": -6.1,
      "SeuilD": -0.7,
      "seuilD_sup": 13.4,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.max",
      "seuilG_inf": -25,
      "seuilG": -17,
      "seuilG_sup": -11.3,
      "seuilD_inf": -16.8,
      "SeuilD": -14.5,
      "seuilD_sup": -4.6,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.min",
      "seuilG_inf": 10.3,
      "seuilG": 20.4,
      "seuilG_sup": 25.4,
      "seuilD_inf": 6.2,
      "SeuilD": 13.1,
      "seuilD_sup": 19.1,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.up",
      "seuilG_inf": -2.8,
      "seuilG": 2.1,
      "seuilG_sup": 13.5,
      "seuilD_inf": 12.3,
      "SeuilD": 23.8,
      "seuilD_sup": 31.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.max",
      "seuilG_inf": -16.3,
      "seuilG": -10.2,
      "seuilG_sup": -6.3,
      "seuilD_inf": -0.2,
      "SeuilD": 5.7,
      "seuilD_sup": 9.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDD",
      "Figure": 7,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.min",
      "seuilG_inf": 5.7,
      "seuilG": 10.2,
      "seuilG_sup": 20.6,
      "seuilD_inf": 14.4,
      "SeuilD": 21.4,
      "seuilD_sup": 25.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.up",
      "seuilG_inf": -68.4,
      "seuilG": -51.7,
      "seuilG_sup": -40,
      "seuilD_inf": -47,
      "SeuilD": -19.4,
      "seuilD_sup": -4.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.max",
      "seuilG_inf": -41.5,
      "seuilG": -25.5,
      "seuilG_sup": -21.8,
      "seuilD_inf": -8,
      "SeuilD": -2.6,
      "seuilD_sup": 6.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "headSI.min",
      "seuilG_inf": -33.3,
      "seuilG": -24.9,
      "seuilG_sup": -9,
      "seuilD_inf": -24.7,
      "SeuilD": -2.7,
      "seuilD_sup": 6.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.up",
      "seuilG_inf": -11,
      "seuilG": -7.8,
      "seuilG_sup": 0.4,
      "seuilD_inf": 6.5,
      "SeuilD": 12.7,
      "seuilD_sup": 21.7,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.max",
      "seuilG_inf": 2.1,
      "seuilG": 9.4,
      "seuilG_sup": 15.1,
      "seuilD_inf": 6.5,
      "SeuilD": 15.5,
      "seuilD_sup": 19,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "withersSI.min",
      "seuilG_inf": -29.9,
      "seuilG": -22.5,
      "seuilG_sup": -17.7,
      "seuilD_inf": -13.6,
      "SeuilD": -11.5,
      "seuilD_sup": -9.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.up",
      "seuilG_inf": -10.3,
      "seuilG": -4.3,
      "seuilG_sup": 1.8,
      "seuilD_inf": -17.1,
      "SeuilD": -9.7,
      "seuilD_sup": -3.2,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.max",
      "seuilG_inf": 2.7,
      "seuilG": 5.2,
      "seuilG_sup": 7.4,
      "seuilD_inf": -6.2,
      "SeuilD": -1.7,
      "seuilD_sup": 1.2,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "ANT",
      "Indice": "croupSI.min",
      "seuilG_inf": -20.3,
      "seuilG": -10.8,
      "seuilG_sup": -7.2,
      "seuilD_inf": -7.2,
      "SeuilD": -4.6,
      "seuilD_sup": 5.2,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.up",
      "seuilG_inf": -43.3,
      "seuilG": -38.5,
      "seuilG_sup": -22.9,
      "seuilD_inf": -24.3,
      "SeuilD": -7.2,
      "seuilD_sup": 21,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.max",
      "seuilG_inf": -38,
      "seuilG": -22.7,
      "seuilG_sup": -15.1,
      "seuilD_inf": -18.4,
      "SeuilD": -5,
      "seuilD_sup": 13.6,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "headSI.min",
      "seuilG_inf": -47.4,
      "seuilG": -31.4,
      "seuilG_sup": -20.2,
      "seuilD_inf": -29.8,
      "SeuilD": -16.5,
      "seuilD_sup": -5,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.up",
      "seuilG_inf": 4.5,
      "seuilG": 13.5,
      "seuilG_sup": 28.8,
      "seuilD_inf": -28.8,
      "SeuilD": -13.9,
      "seuilD_sup": -4.7,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.max",
      "seuilG_inf": 17.2,
      "seuilG": 20.6,
      "seuilG_sup": 27.1,
      "seuilD_inf": 2.3,
      "SeuilD": 10.2,
      "seuilD_sup": 16.3,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "withersSI.min",
      "seuilG_inf": -15,
      "seuilG": -12,
      "seuilG_sup": -10.6,
      "seuilD_inf": -32.8,
      "SeuilD": -23,
      "seuilD_sup": -12.9,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.up",
      "seuilG_inf": -23.3,
      "seuilG": -14.4,
      "seuilG_sup": 0.1,
      "seuilD_inf": 2.4,
      "SeuilD": 9.7,
      "seuilD_sup": 15.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.max",
      "seuilG_inf": -8.8,
      "seuilG": -3.1,
      "seuilG_sup": 5.2,
      "seuilD_inf": 6.6,
      "SeuilD": 10.9,
      "seuilD_sup": 13.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGD",
      "Figure": 6,
      "Sol": 1,
      "Type_seuil": "POST",
      "Indice": "croupSI.min",
      "seuilG_inf": -18.8,
      "seuilG": -13.9,
      "seuilG_sup": -7.4,
      "seuilD_inf": -7.3,
      "SeuilD": 0.4,
      "seuilD_sup": 5.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "headSI.up",
      "seuilG_inf": -44.4,
      "seuilG": -34.4,
      "seuilG_sup": -20.5,
      "seuilD_inf": 7,
      "SeuilD": 19,
      "seuilD_sup": 26.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "headSI.max",
      "seuilG_inf": -3.3,
      "seuilG": 2.6,
      "seuilG_sup": 8,
      "seuilD_inf": 15.3,
      "SeuilD": 19,
      "seuilD_sup": 26.6,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "headSI.min",
      "seuilG_inf": -47.1,
      "seuilG": -31.5,
      "seuilG_sup": -13.9,
      "seuilD_inf": -1.8,
      "SeuilD": 6.5,
      "seuilD_sup": 19.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "withersSI.up",
      "seuilG_inf": -1.4,
      "seuilG": 3.6,
      "seuilG_sup": 12.3,
      "seuilD_inf": 10.8,
      "SeuilD": 16.4,
      "seuilD_sup": 19.6,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "withersSI.max",
      "seuilG_inf": -25.4,
      "seuilG": -18.6,
      "seuilG_sup": -12.2,
      "seuilD_inf": -13.1,
      "SeuilD": -10.7,
      "seuilD_sup": -8.5,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "withersSI.min",
      "seuilG_inf": 15.1,
      "seuilG": 19.6,
      "seuilG_sup": 26.5,
      "seuilD_inf": 27.9,
      "SeuilD": 31.5,
      "seuilD_sup": 36.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "croupSI.up",
      "seuilG_inf": 6.2,
      "seuilG": 17,
      "seuilG_sup": 22.7,
      "seuilD_inf": 3.4,
      "SeuilD": 9.6,
      "seuilD_sup": 13.5,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "croupSI.max",
      "seuilG_inf": -7.4,
      "seuilG": -2.2,
      "seuilG_sup": 1.4,
      "seuilD_inf": -9.6,
      "SeuilD": -6,
      "seuilD_sup": -2.4,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "croupSI.min",
      "seuilG_inf": 17.6,
      "seuilG": 21.4,
      "seuilG_sup": 24.2,
      "seuilD_inf": 16.5,
      "SeuilD": 21.2,
      "seuilD_sup": 27.3,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "headSI.up",
      "seuilG_inf": -15.7,
      "seuilG": -0.2,
      "seuilG_sup": 30.6,
      "seuilD_inf": 1.5,
      "SeuilD": 17,
      "seuilD_sup": 41.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "headSI.max",
      "seuilG_inf": -16.7,
      "seuilG": 4.6,
      "seuilG_sup": 15.6,
      "seuilD_inf": 7.8,
      "SeuilD": 15,
      "seuilD_sup": 21.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "headSI.min",
      "seuilG_inf": -67.3,
      "seuilG": -27.9,
      "seuilG_sup": 1.8,
      "seuilD_inf": -8.5,
      "SeuilD": 7.2,
      "seuilD_sup": 35.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "withersSI.up",
      "seuilG_inf": 12.1,
      "seuilG": 16.5,
      "seuilG_sup": 21.3,
      "seuilD_inf": -5.3,
      "SeuilD": 4.6,
      "seuilD_sup": 17.5,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "withersSI.max",
      "seuilG_inf": -14.6,
      "seuilG": -10.6,
      "seuilG_sup": -7.3,
      "seuilD_inf": -25.2,
      "SeuilD": -18.2,
      "seuilD_sup": -14.3,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "withersSI.min",
      "seuilG_inf": 25.8,
      "seuilG": 28.5,
      "seuilG_sup": 32.7,
      "seuilD_inf": 10.8,
      "SeuilD": 20.1,
      "seuilD_sup": 30.2,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "croupSI.up",
      "seuilG_inf": -8.4,
      "seuilG": -0.1,
      "seuilG_sup": 9.8,
      "seuilD_inf": 30.1,
      "SeuilD": 38.1,
      "seuilD_sup": 54.4,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "croupSI.max",
      "seuilG_inf": -14,
      "seuilG": -12,
      "seuilG_sup": -8.2,
      "seuilD_inf": -1.6,
      "SeuilD": 1.2,
      "seuilD_sup": 2.7,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MDS",
      "Figure": 7,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "croupSI.min",
      "seuilG_inf": -9.3,
      "seuilG": 4.3,
      "seuilG_sup": 11.7,
      "seuilD_inf": 24.2,
      "SeuilD": 29,
      "seuilD_sup": 38.4,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "headSI.up",
      "seuilG_inf": -28.6,
      "seuilG": -18.3,
      "seuilG_sup": -9.5,
      "seuilD_inf": -2,
      "SeuilD": 19.8,
      "seuilD_sup": 31.7,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "headSI.max",
      "seuilG_inf": -27.1,
      "seuilG": -16.6,
      "seuilG_sup": -10.6,
      "seuilD_inf": -6.1,
      "SeuilD": -1,
      "seuilD_sup": 2.8,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "headSI.min",
      "seuilG_inf": -23.6,
      "seuilG": -10.1,
      "seuilG_sup": -1.1,
      "seuilD_inf": 25.6,
      "SeuilD": 31.6,
      "seuilD_sup": 50.7,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "withersSI.up",
      "seuilG_inf": -17.5,
      "seuilG": -12.7,
      "seuilG_sup": -1.8,
      "seuilD_inf": -14.3,
      "SeuilD": -2,
      "seuilD_sup": 2.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "withersSI.max",
      "seuilG_inf": -0.5,
      "seuilG": 6.4,
      "seuilG_sup": 9.5,
      "seuilD_inf": 16.2,
      "SeuilD": 19.9,
      "seuilD_sup": 26.4,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "withersSI.min",
      "seuilG_inf": -29.1,
      "seuilG": -24.9,
      "seuilG_sup": -16.7,
      "seuilD_inf": -18.3,
      "SeuilD": -15,
      "seuilD_sup": -10.1,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "croupSI.up",
      "seuilG_inf": -11.3,
      "seuilG": -5.8,
      "seuilG_sup": -3.3,
      "seuilD_inf": -36.2,
      "SeuilD": -20.9,
      "seuilD_sup": -15.7,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "croupSI.max",
      "seuilG_inf": 5.1,
      "seuilG": 7.2,
      "seuilG_sup": 11.2,
      "seuilD_inf": -2.5,
      "SeuilD": -0.3,
      "seuilD_sup": 1.5,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "ANT",
      "Indice": "croupSI.min",
      "seuilG_inf": -19.4,
      "seuilG": -12.9,
      "seuilG_sup": -9,
      "seuilD_inf": -25.6,
      "SeuilD": -18.6,
      "seuilD_sup": -15.6,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "headSI.up",
      "seuilG_inf": -10.2,
      "seuilG": 7,
      "seuilG_sup": 43.3,
      "seuilD_inf": -10.3,
      "SeuilD": 7.8,
      "seuilD_sup": 19.9,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "headSI.max",
      "seuilG_inf": -13.2,
      "seuilG": -4,
      "seuilG_sup": 15.7,
      "seuilD_inf": -25.1,
      "SeuilD": -10.3,
      "seuilD_sup": 10.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "headSI.min",
      "seuilG_inf": -27.4,
      "seuilG": -0.8,
      "seuilG_sup": 27.5,
      "seuilD_inf": -2.4,
      "SeuilD": 16.6,
      "seuilD_sup": 30.5,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "withersSI.up",
      "seuilG_inf": -17.3,
      "seuilG": -2.2,
      "seuilG_sup": 4.1,
      "seuilD_inf": -21.4,
      "SeuilD": -13.7,
      "seuilD_sup": -7.9,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "withersSI.max",
      "seuilG_inf": 15.3,
      "seuilG": 19.9,
      "seuilG_sup": 25.2,
      "seuilD_inf": 4,
      "SeuilD": 9.6,
      "seuilD_sup": 13.5,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "withersSI.min",
      "seuilG_inf": -26,
      "seuilG": -18.3,
      "seuilG_sup": -14.5,
      "seuilD_inf": -30.4,
      "SeuilD": -22.9,
      "seuilD_sup": -16.5,
      "Actif": 0
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "croupSI.up",
      "seuilG_inf": -24.7,
      "seuilG": -22.4,
      "seuilG_sup": -19.9,
      "seuilD_inf": -1.1,
      "SeuilD": 0.8,
      "seuilD_sup": 2.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "croupSI.max",
      "seuilG_inf": -5.8,
      "seuilG": -3.7,
      "seuilG_sup": -0.3,
      "seuilD_inf": -1.6,
      "SeuilD": 8.4,
      "seuilD_sup": 13.3,
      "Actif": 1
  },
  {
      "Version": "2022_08_22",
      "Condition": "MGS",
      "Figure": 6,
      "Sol": 2,
      "Type_seuil": "POST",
      "Indice": "croupSI.min",
      "seuilG_inf": -28.6,
      "seuilG": -23.6,
      "seuilG_sup": -17.3,
      "seuilD_inf": -20.9,
      "SeuilD": -12.7,
      "seuilD_sup": -8.5,
      "Actif": 1
  }
]