import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Iconify from '../Iconify'
import { useSnackbar } from 'notistack'
import useLocales from 'src/hooks/useLocales'

function AdminBanner() {
    const { user } = useSelector(state => state.user)
    const { enqueueSnackbar } = useSnackbar();
    const {translate} = useLocales()
    const copyPageWithToken = () => {
        let link = window.location.href
        let token = localStorage.getItem('accessToken')

        navigator.clipboard.writeText(link.concat(`${link.includes('?') ? '&' : '?'}login_token=${token}`))
        enqueueSnackbar(translate('snackbar.copySuccess'),{
            autoHideDuration:1000
        })

    }
    return (
        <Box sx={{ width: '100vw', height: 50, position: 'fixed', left: 0, top: 60, zIndex: 999 }} className='admin_banner' px={{ md: 5, xs: 1 }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography color={'white'} variant='subtitle2' alignItems={'center'} display={'flex'} gap={1}>
                <Iconify icon='fluent:plug-connected-20-regular' width={20} height={20}></Iconify>
                Logged in as {user?.email}</Typography>

            <Button onClick={copyPageWithToken} sx={{ color: 'white' }} startIcon={<Iconify icon='icon-park-outline:copy-link' width={20} height={20}></Iconify>}>Copy page link</Button>
        </Box>
    )
}

export default AdminBanner