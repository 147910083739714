import { Avatar, Box, Button, Card, CardContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useLocales from 'src/hooks/useLocales'
import Iconify from '../Iconify'
import dayjs from 'dayjs'
import { get, isEmpty, lowerCase } from 'lodash'
import { Icon } from '@iconify/react'

import { useNavigate } from 'react-router'

function ExternalSevicesCard() {

  const { translate } = useLocales()
  const { user } = useSelector(state => state.user)
  const navigate = useNavigate()


  const isExternalAccountsHasEqs = useMemo(() => {
    if (isEmpty(user.user_external_services)) return false
    return user.user_external_services.some(({ service }) => lowerCase(service) === 'equisym')
  }, [user])

  const isExternalAccountsHasEqm = useMemo(() => {
    if (isEmpty(user.user_external_services)) return false
    return user.user_external_services.some(({ service }) => lowerCase(service) === 'equimetre')
  }, [user])


  const renderButton = () => {

    if ((isExternalAccountsHasEqm && isExternalAccountsHasEqs) || (!isExternalAccountsHasEqm && !isExternalAccountsHasEqs)) return <></>

    if (!isExternalAccountsHasEqs && isExternalAccountsHasEqm) {
      return (
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <Button  onClick={() => navigate('/settings/account?tab=settings.tabExternalLinks')}>{translate('settings.addEqs')}</Button>
        </Box>
      )
    } else {

      return (<Box display={'flex'} justifyContent={'center'} mt={2}>
        <Button  onClick={() => navigate('/settings/account?tab=settings.tabExternalLinks')}>{translate('settings.addEqm')}</Button>
      </Box>)
    }


  }


  return (
    <Card sx={{ minHeight: '100%', width: '100%' }}>
      <CardContent sx={{ width: '100%' }}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant='h5' display={'flex'} alignItems={'center'} gap={1}>
            {translate('settings.tabExternalLinks')}
            <Icon style={{ width: 15, height: 15, color: 'gray', cursor: 'pointer' }} onClick={() => navigate('/settings/account?tab=settings.tabExternalLinks')} icon='pajamas:external-link'></Icon>
          </Typography>

        </Box>
        <Divider sx={{ my: 2 }}></Divider>

        <List dense sx={{ maxHeight: 200, overflowY: 'scroll' }}>
          {user.user_external_services.map((externalLink) => (
            <ListItem >
              <ListItemAvatar  >
                <Avatar sx={{ borderRadius: 1, bgcolor: '#17a2b8' }}>
                  <Iconify style={{ color: 'white' }} icon='hugeicons:link-square-01' ></Iconify>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={externalLink.external_login_identifier}
                secondary={
                  <Box display={'flex'} flexDirection={'row'} gap={0.5} alignItems={'center'} mt={0.5}>
                    <Typography fontSize={12} display={'flex'} gap={0.5} alignItems={'center'}>
                      <Iconify icon={'formkit:time'}></Iconify>
                      {dayjs(get(externalLink, 'creation_date')).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    <Divider flexItem orientation='vertical'></Divider>
                    <Typography fontSize={12} display={'flex'} gap={0.5} alignItems={'center'}>
                      <Iconify icon={'fluent-mdl2:dataflows'}></Iconify>
                      {externalLink.service}
                    </Typography>
                  </Box>
                }
              ></ListItemText>
            </ListItem>
          ))}
        </List>

        {renderButton()}

      </CardContent>
    </Card>
  )
}

export default ExternalSevicesCard