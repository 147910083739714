import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Stack,
  Link,
} from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../redux/store";
// hooks
import useLocales from "../../hooks/useLocales";
// utils
import axios from "../../utils/axios";
// components
import Label from "../../components/Label";
import Iconify from "../../components/Iconify";
import { TableMoreMenu } from "../../components/table";

// ----------------------------------------------------------------------

TeamMemberTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function TeamMemberTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { type, user, accepted } = row;
  const [fetchedUser, setFetchedUser] = useState(null);

  const { userInOrganisation } = useSelector((state) => state.user);

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const distantUser = await axios.get(`${user["@id"]}`);
        setFetchedUser(distantUser.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <TableRow hover selected={selected}>
      
      <TableCell sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt={user.firstname}
          src={fetchedUser?.image?.content_url}
          sx={{ mr: 2 }}
        />
        <Stack direction="column">
          <Typography variant="subtitle2" noWrap>
            {user.firstname} {user.lastname}
          </Typography>
          <Typography variant="body2" noWrap>
            {user.email}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">
        {type && (
          <Stack direction="column">
            <Typography variant="subtitle2" noWrap>
              {translate("general.limitedAccessMember")}
            </Typography>
            <Link href="#" color="inherit">
              {translate("team.addLimiterAccessMember")}
            </Link>
          </Stack>
        )}
        {!accepted && (
          <Typography variant="body2" noWrap>
            {translate("team.waitingAcceptance")}
          </Typography>
        )}
      </TableCell>

      <TableCell align="right">
        {accepted && (
          <IconButton
            onClick={() => {
              onDeleteRow();
            }}
          >
            <Iconify icon={"eva:close-fill"} width={20} height={20} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
