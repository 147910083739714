import { Warning } from '@mui/icons-material';
import { Box, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material'
import { get, isEmpty, isEqual, uniqBy } from 'lodash';
import React, { useEffect, useMemo } from 'react'
import useLocales from 'src/hooks/useLocales';
import { formatDate } from 'src/utils/dashboard';
import { dateComparator } from 'src/utils/examDashboard';
import { useLongPress } from 'use-long-press';
export default function DuplicateConditionList({ conditions, loading, onCheck,reset }) {
    const [checkedConditions, setCheckedConditions] = React.useState([]);
    const [action, setAction] = React.useState('click')
    const { translate } = useLocales()

    useEffect(() => {
        setCheckedConditions([])
    }, [conditions])

    useEffect(()=>{
        if(reset === 'condition'){
            setCheckedConditions([])
        }
    },[reset])

    useEffect(() => {
        onCheck(checkedConditions,'duplicateCondition')
    }, [checkedConditions])

    const sortConditions = useMemo(() => {
        if (!isEmpty(conditions)) {
            return uniqBy([...conditions].sort((a, b) => dateComparator(
                formatDate(get(a, 'timestamp_start.unix', null))
                , formatDate(get(b, 'timestamp_start.unix', null)
                ))
            ), 'comparable')
        } else {
            return conditions
        }
    }, [conditions])


    const handleToggle = (value) => {
        //Get all conditions with the same figure ground
        let values = conditions.filter(el => el?.comparable === value?.comparable)

        let uuids = [...checkedConditions].map(e => e?.uuid)
        uuids.includes(value.uuid) ? setCheckedConditions([]) : setCheckedConditions([...values])


    }

    const isChecked = (value) => {
        return checkedConditions.map(e => e?.comparable).includes(value?.comparable)
    }
    const formatText = (data) => {
        let gait = data?.gait ? translate(`gait.${data?.gait}`).concat('/') : ''
        let figure = data?.figure ? translate(data.figure === 'CUSTOM' ? data?.figure_custom : `figure.${data?.figure}`).concat('/') : ''
        let ground = translate(`ground.${data?.ground}`) || ''

        return gait.concat(figure, ground)
    }

    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {sortConditions.map((value) => (
                <ListItem
                    key={value?.uuid}
                    disableGutters
                    disablePadding
                    divider
                >
                    <ListItemButton dense onClick={() => handleToggle(value)}>
                        <ListItemIcon>
                            <Checkbox
                                key={value?.uuid}
                                checked={isChecked(value)}
                                color='secondary'
                                onClick={(e) => handleToggle(value)}
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText secondaryTypographyProps={{ fontSize: 12, fontWeight: 'bold', }} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} primary={formatText(value)} secondary={'_'} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    )
}
DuplicateConditionList.defaultProps = {
    conditions: []
}