import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Close, FolderDelete, Search, Share, TopicOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { Checkbox, Chip, Divider, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material';
import { useMutation } from 'react-query';
import { difference, get, isEmpty } from 'lodash';
import useLocales from 'src/hooks/useLocales';
import { useState } from 'react';
import { addExamVetCase, deleteExamInVetCase } from 'src/redux/slices/examen';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import DeleteModal from './DeleteModal';
import { formatDate, getOutils, getOutilsColor } from 'src/utils/dashboard';
import { useCallback } from 'react';
import { dateComparatorDesc } from 'src/utils/examDashboard';
import { Examen } from 'src/classes/Examen';
import { ExamExtractor } from 'src/utils/ExamExtractor';
import Iconify from '../Iconify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};
function ManageExamModal({ open, handleClose, vetCase, exams, refreshVetCase, horseExams, loading }) {
    const theme = useTheme();
    const { translate } = useLocales()
    const [value, setValue] = React.useState(0);

    const [exam, setExam] = useState([])
    const [autoAdd, setAutoAdd] = useState(get(vetCase, 'auto_add_future_horse_exams', false))
    const [itemToRemove, setItemToRemove] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const { enqueueSnackbar } = useSnackbar()

    const examMutation = useMutation((vet_case) => addExamVetCase({ vet_case: vetCase['@id'], list_exams: exam }))
    const updateCaseMutation = useMutation(() => Examen.patchCase({ "auto_add_future_horse_exams": autoAdd }, vetCase?.id))

    const patchCase = async () => {
        return updateCaseMutation.mutateAsync()
            .then(async (res) => {
                enqueueSnackbar(translate('snackbar.updateSuccess'), {
                    variant: 'success'
                })
                refreshVetCase()
                return res
            }).catch(err => {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'error'
                })
                return err
            })
    }


    const addExam = async () => {

        if (isEmpty(exam)) {
            await patchCase()
            handleClose()
            return
        }

        examMutation.mutateAsync()
            .then(async (res) => {
                enqueueSnackbar(translate('snackbar.caseExamCreateSuccess'), {
                    variant: 'success'
                })
                await updateCaseMutation.mutateAsync()
                refreshVetCase()
                setExam([])
            }).catch(err => {
                enqueueSnackbar(translate('snackbar.examAlreadyExistInVet'), {
                    variant: 'warning'
                })
                setValue(1)
            })
    }

    const sortedHorseExam = useCallback(() => {
        let _exams = exams?.map(e => e?.exam) ?? []

        let arr = difference(horseExams, _exams)


        return arr.sort((a, b) => dateComparatorDesc(
            formatDate(get(a, 'timestamp_start.unix', null))
            , formatDate(get(b, 'timestamp_start.unix', null)
            ))
        )
    }, [horseExams, exams])



    const renderExam = (ids) => {
        let founds = [...sortedHorseExam()].filter((exam) => ids.includes(get(exam, '@id')))

        return (
            <>
                {founds.map((f, i) => (
                    <>
                        <Chip size='small' sx={{ backgroundColor: getOutilsColor(get(f, '@type')).background, color: getOutilsColor(get(f, '@type')).text }} label={getOutils(get(f, '@type'))} />
                        <Typography variant='caption'>{ExamExtractor.getLastExam(f)} {i !== founds.length - 1 ? ' - ' : ' '}</Typography>
                    </>
                ))}

            </>
        )
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <Iconify color='primary' icon='proicons:file-add' width={21} height={21} />
                        <Typography fontWeight={600} variant='h6'>{translate('addExams')}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>
                <Divider sx={{ mt: 1, mb: 2 }}></Divider>
                <Box >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{translate('general.exams')}</InputLabel>
                        <Select
                            label="Examens"
                            defaultValue=""
                            disabled={loading}
                            multiple
                            value={exam}
                            onChange={(e) => {
                                setExam(e.target.value)
                            }}
                            renderValue={renderExam}
                            endAdornment={exam && <IconButton onClick={() => setExam(null)}>
                                <Close fontSize='small'></Close>
                            </IconButton>
                            }
                        >
                            {sortedHorseExam().map(examen =>
                                <MenuItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={examen?.external_id} value={get(examen, '@id')}>
                                    <Chip size='small'
                                        sx={{ backgroundColor: getOutilsColor(get(examen, '@type')).background, color: getOutilsColor(get(examen, '@type')).text }}
                                        label={getOutils(get(examen, '@type'))} />
                                    <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                                        <Typography variant='caption'>{ExamExtractor.getLastExam(examen)}</Typography>
                                        <Typography fontWeight={500} variant='caption'>{ExamExtractor.getDescription(examen)}</Typography>
                                    </Box>
                                </MenuItem>)}

                        </Select>
                    </FormControl>
                    <Box display={'flex'} flexDirection={'column'}>
                        <FormControlLabel control={<Checkbox onChange={e => {
                            e.target.checked ? setExam(sortedHorseExam().map(exam => get(exam, '@id'))) : setExam([])
                        }}></Checkbox>} label={translate('selectAll')}></FormControlLabel>
                        <FormControlLabel control={<Checkbox defaultChecked={get(vetCase, 'auto_add_future_horse_exams', false)} value={autoAdd} onChange={e => setAutoAdd(e.target.checked)}></Checkbox>} label={translate('autoAddExam')}></FormControlLabel>
                    </Box>
                    <Box display='flex' marginTop={3} justifyContent='end'>
                        <LoadingButton loading={examMutation.isLoading || updateCaseMutation.isLoading} onClick={addExam} variant='contained'>{translate('nav.add')}</LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default ManageExamModal

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingBlock: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

