import { useMemo, useState } from "react";

// @mui
import { Box, CircularProgress } from "@mui/material";
//components
import EqsTopbar from "./EqsTopbar";
import EqsGraphView from "./EqsGraphView";
import EqsVideoView from "./EqsVideoView";
import { useEffect } from "react";
import { get } from "lodash";
import useLocales from "src/hooks/useLocales";




export const LinesMode = {
  Single: "single",
  Mean: "mean",
  All: "all",
};

export const View = {
  Graph: "graph",
  Video: "video",
};

//Raw conditions : Unmodified conditions 

export default function EqsWindow({ conditions, handleViewChange, rawConditions, onVariableModeChange, isComparaison, loading }) {
  const [view, setView] = useState(View.Graph);
  const [results, setResults] = useState([]);
  const [selectedThreshold, setSelectedThreshold] = useState(null);
  const { translate } = useLocales()
  // ----------------------------------------------------------------------

  const thresholdOptions = [
    {
      title: translate('examEnums.none'),
      value: null
    },
    {
      title: translate('examEnums.ba'),
      value: 'ba'
    },
    {
      title: translate('examEnums.bp'),
      value: 'bp'
    },
  ]

  const variablesOptions = [
    {
      title: translate('examEnums.variables.amplitude'),
      subtitle: translate('examEnums.variables.amplitudeDesc'),
      value: "up",
    },
    {
      title: translate('examEnums.variables.max'),
      subtitle: translate('examEnums.variables.maxDesc'),
      value: "max",
    },
    {
      title: translate('examEnums.variables.min'),
      subtitle: translate('examEnums.variables.minDesc'),
      value: "min",
    },
  ];



  const locationOptions = [
    {
      title: translate('examEnums.tete'),
      subtitle: "",
      value: "head",
    },
    {
      title: translate('examEnums.garrot'),
      subtitle: "",
      value: "withers",
    },
    {
      title: translate('examEnums.croupe'),
      subtitle: "",
      value: "croup",
    },
  ];

  // ----------------------------------------------------------------------

  useEffect(() => {
    setResults(conditions)
  }, [conditions])

  const [linesMode, setLinesMode] = useState(LinesMode.Mean);
  const [variablesMode, setVariablesMode] = useState(null);
  const [locationMode, setLocationMode] = useState(JSON.stringify(locationOptions[0]));

  const nbFoulee = useMemo(() => {
    return get(rawConditions, '[0].condition.nbStrides', 0)
  })




  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      handleViewChange(newView)
    }
  };

  const handleChangeLinesMode = (value) => {
    setLinesMode(value);
    onVariableModeChange(value)
  };

  const handleChangeVariablesMode = (value) => {
    setVariablesMode(value);
  };

  useEffect(() => {
    onVariableModeChange(linesMode, variablesMode)
  }, [linesMode, variablesMode])

  const handleChangeLocationMode = (event, newValue) => {
    if (newValue) {
      setLocationMode(newValue);
    }
  };
  const getStytle = () => {
    if (view === View.Video) {
      return {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 10,
      }
    } else {
      return {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: -10,
        visibility: 'hidden'

      }
    }
  }

  return (
    <Box position={'relative'} >
      {
        loading && <Box position={'absolute'} width={'100%'} height={'100vh'} bgcolor={'white'} zIndex={10} display={'flex'} justifyContent={'center'} alignItems={'self-start'} mt={3}>
          <CircularProgress></CircularProgress>
        </Box>
      }
      {/* BOX TOPBAR */}
      <EqsTopbar
        conditions={rawConditions}
        view={view}
        handleChangeView={handleChangeView}
        linesMode={linesMode}
        handleChangeLinesMode={handleChangeLinesMode}
        variablesOptions={variablesOptions}
        variablesMode={variablesMode}
        handleChangeVariablesMode={handleChangeVariablesMode}
        locationOptions={locationOptions}
        locationMode={locationMode}
        handleChangeLocationMode={handleChangeLocationMode}
        thresholdOptions={thresholdOptions}
        onThresholdChange={val => setSelectedThreshold(val)}
        selectedThreshold={selectedThreshold}
      ></EqsTopbar>
      {/* BOX GRAPH + CURSORS */}
      <Box position={'relative'} >
        {view === View.Graph && (
          <EqsGraphView
            rawConditions={rawConditions}
            nbFoulee={nbFoulee}
            isComparaison={isComparaison}
            handleChangeVariablesMode={handleChangeVariablesMode}
            results={results}
            linesMode={linesMode}
            variablesOptions={variablesOptions}
            variablesMode={variablesMode}
            selectedThreshold={selectedThreshold}
          ></EqsGraphView>
        )}

        <EqsVideoView
          style={getStytle()}
          results={get(rawConditions, '[0].condition', {})}
          uuid={get(rawConditions, '[0].video.uuid', null)}
          linesMode={linesMode}
          variablesOptions={variablesOptions}
          variablesMode={variablesMode}
          handleChangeVariablesMode={handleChangeVariablesMode}
          locationOptions={locationOptions}
          locationMode={locationMode}
          handleChangeLocationMode={handleChangeLocationMode}
        ></EqsVideoView>
      </Box>


    </Box>
  );
}
EqsWindow.defaultProps = {
  conditions: [],
  rawConditions: [],
}