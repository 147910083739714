import { useEffect, useState } from "react";
// @mui
import {
  MenuItem,
  Box,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  IconButton,
  Popover,
  Menu,
  MenuList,
  ListItemIcon,
} from "@mui/material";
//components
import Iconify from "../../components/Iconify";
import { LinesMode, View } from "./EqsWindow";
import useLocales from "src/hooks/useLocales";
import { isNull } from "lodash";
import { useMemo } from "react";
import { Check, MultipleStopOutlined } from "@mui/icons-material";

export default function EqsTopbar({
  conditions,
  view,
  handleChangeView,
  linesMode,
  handleChangeLinesMode,
  variablesOptions,
  variablesMode,
  handleChangeVariablesMode,
  locationOptions,
  locationMode,
  handleChangeLocationMode,
  thresholdOptions,
  onThresholdChange,
  selectedThreshold
}) {
  const [variablesModeOpen, setVariablesModeOpen] = useState(false);
  const { translate } = useLocales()

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClickVariablesMode = (event) => {
    setVariablesModeOpen(event.currentTarget);
  };

  useEffect(() => {
    if (conditions.length > 1 && isNull(variablesMode)) {
      handleChangeVariablesMode(JSON.stringify(variablesOptions[0]))
    }
  }, [conditions.length])
  const handleCloseVariablesMode = () => {
    setVariablesModeOpen(null);
  };

  const getTitle = useMemo(() => {
    if (linesMode === LinesMode.All) {
      return translate('examEnums.indiceUnitaire')
    }

    let variable = JSON.parse(variablesMode)

    let found = variablesOptions.find(el => el.value === variable?.value)

    return found?.subtitle ?? translate('examEnums.indice')





  }, [variablesMode, linesMode])

  return (
    <Box
      sx={{
        display: "flex",
        px: 1,
        py: 0,
        bgcolor: "background.paper",
        borderRadius: 1,
        height: "64px",
        alignItems: 'center',
        justifyContent: 'space-evenly'
      }}
    >
      {/* VIEW */}
      <Box
        justifyContent="center"
        sx={{
          p: 0,
          m: 0,
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={view}
          exclusive
          onChange={handleChangeView}
        >
          <ToggleButton value={View.Graph}>
            <Iconify icon={"entypo:line-graph"} />
          </ToggleButton>
          {conditions.length < 2 && conditions.length !== 0 && <ToggleButton disabled={isNull(conditions?.video?.uuid)} value={View.Video}>
            <Iconify icon={"entypo:video"} />
          </ToggleButton>}
        </ToggleButtonGroup>
      </Box>
      {/* TITLE */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ p: 0, m: 0, flexGrow: 1 }}
      >
        <Typography variant="subtitle1" component="h1" paragraph sx={{ m: 0 }}>
          {view === View.Graph && getTitle}
          {view === View.Video && variablesMode && variablesMode.subtitle}
        </Typography>
      </Box>
      {/* OPTIONS */}
      <Box
        sx={{
          p: 0,
          m: 0,
          mt: 1
        }}
      >
        {view === View.Graph && conditions.length < 2 && (
          <IconButton
            size="large"
            onClick={() => {
              switch (linesMode) {
                case LinesMode.All:
                  handleChangeLinesMode(LinesMode.Mean);
                  break;
                case LinesMode.Mean:
                  handleChangeLinesMode(LinesMode.All);
                  break;
                default:
                  break;
              }
              handleChangeVariablesMode(null);
            }}
          >
            {linesMode === LinesMode.All && (
              <Iconify
                icon={"fluent:sound-wave-circle-24-filled"}
                style={{ color: "primary" }}
              />
            )}
            {linesMode === LinesMode.Mean && (
              <Iconify icon={"fluent:sound-wave-circle-24-regular"} />
            )}
          </IconButton>
        )}

        {view === View.Video && (
          <ToggleButtonGroup
            color="primary"
            value={locationMode}
            exclusive
            onChange={handleChangeLocationMode}
          >
            {locationOptions.map((option, index) => (
              <ToggleButton key={index} value={JSON.stringify(option)}>{option.title}</ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
        {
          view === View.Graph &&
          <IconButton color={!isNull(selectedThreshold) ? 'secondary' : 'default'} aria-describedby={id} variant="contained" onClick={handleClick}>
            <MultipleStopOutlined></MultipleStopOutlined>
          </IconButton>
        }
        <IconButton size="large" onClick={handleClickVariablesMode}>
          {variablesMode !== null && (
            <Iconify
              icon={"fluent:filter-dismiss-20-regular"}
              style={{ color: "primary" }}
            />
          )}
          {variablesMode === null && (
            <Iconify icon={"fluent:filter-20-regular"} />
          )}
        </IconButton>
        <Popover
          open={Boolean(variablesModeOpen)}
          anchorEl={variablesModeOpen}
          onClose={handleCloseVariablesMode}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Stack spacing={0.75}>
            {variablesOptions.map((option) => (
              <MenuItem
                key={option.value}
                selected={JSON.stringify(option) === variablesMode}
                onClick={() => {
                  if (variablesMode === JSON.stringify(option)) {
                    handleChangeVariablesMode(null);
                  } else {
                    handleChangeVariablesMode(JSON.stringify(option));
                    handleChangeLinesMode(LinesMode.Mean);
                  }
                  handleCloseVariablesMode();
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Stack direction="column">
                    <Typography variant="body1" display="block" gutterBottom>
                      {option.title}
                    </Typography>
                    <Typography variant="body2" display="block" gutterBottom>
                      {option.subtitle}
                    </Typography>
                  </Stack>
                  {option === variablesMode && (
                    <Iconify
                      icon="entypo:check"
                      width={20}
                      height={20}
                      sx={{ mr: 1, color: "primary" }}
                    />
                  )}
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        </Popover>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuList>
            {thresholdOptions.map(option => (
              <MenuItem selected={option.value === selectedThreshold} key={option.value} onClick={e => {
                onThresholdChange(option.value)
                handleClose()
              }} >
                <Typography variant="body1" display="block">{option.title}</Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Popover>


      </Box>
    </Box>
  );
}
EqsTopbar.defaultProps = {
  conditions: []
}