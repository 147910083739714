import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Checkbox, CircularProgress, Collapse, Container, IconButton, ListItem, ListItemIcon, ListItemText, Stack, Tooltip } from '@mui/material'
import { Divider } from 'antd'
import { log } from 'deck.gl'
import { get, isEmpty } from 'lodash'
import React, { memo, useState } from 'react'
import { useMutation } from 'react-query'
import { Condition } from 'src/classes/Condition'
import { Examen } from 'src/classes/Examen'
import useLocales from 'src/hooks/useLocales'
import { formatDate } from 'src/utils/dashboard'
import DataTable from '../DataTable'
import { EqsChart, EqsCursor } from 'src/sections/eqs'
import Iconify from 'src/components/Iconify'

function ConditionView({ condition, isSelected, handleInputChange, examen }) {

  //Hooks
  const { translate } = useLocales()
  const conditionMutations = useMutation((data) => Examen.getConditionData(data))
  const [isError, setIsError] = useState(false)
  //Data
  const [open, setOpen] = useState(false)
  const [selectedCondition, setSelectedCondition] = useState(null)
  const [rawCondition, setRawCondition] = useState(null)

  //Const

  const variablesOptions = [
    {
      title: translate('examEnums.variables.amplitude'),
      subtitle: translate('examEnums.variables.amplitudeDesc'),
      value: "up",
    },
    {
      title: translate('examEnums.variables.max'),
      subtitle: translate('examEnums.variables.maxDesc'),
      value: "max",
    },
    {
      title: translate('examEnums.variables.min'),
      subtitle: translate('examEnums.variables.minDesc'),
      value: "min",
    },
  ];


  //fns
  const formatText = (data) => {
    let figure = data?.figure ? translate(data.figure === 'CUSTOM' ? data?.figure_custom : `figure.${data?.figure}`).concat(' - ') : ''
    let ground = translate(`ground.${data?.ground}`) || ''

    return figure.concat(ground)
  }

  const viewCondition = (condition, skipOpen = true) => {
    if (!skipOpen) {
      setOpen(!open)
    }
    if (selectedCondition) return
    //get Condition
    conditionMutations.mutateAsync({ uuid: condition?.uuid, exam_id: examen?.external_id })
      .then(result => {
        let data = get(Object.values(result.data), '[0]', {})
        //Check if Loaded condition has error or not loaded succefully
        if (data.hasOwnProperty('error') && !isEmpty(data?.error)) {
          setIsError(true)
          handleInputChange(condition, false)
          return
        }
        let formatedCondition = formatConditions([{ ...examen?.conditions.find(e => e?.uuid === condition?.uuid), condition: data }])
        setSelectedCondition(formatedCondition)
        setRawCondition(condition)
      })
  }

  const formatConditions = (temp) => {
    if (isEmpty(temp)) return []
    let conditions = new Condition(temp, false)
    conditions
      .setConditionsColor()
      .setTeteData()
      .setGarrotData()
      .setCroupeData()
    return conditions.getData()
  }

  //Renders
  return (
    <>
      <ListItem >
        <ListItemText primaryTypographyProps={{ fontSize: '14px' }} secondary={formatDate(condition?.timestamp_start?.unix, 'HH:mm')} primary={formatText(condition)}></ListItemText>

        {
          isError ?
            <ListItemIcon>
              <Iconify icon='ph:warning-fill' width={20} height={20} sx={{ color: 'orange' }}></Iconify>
            </ListItemIcon>
            : <>
              <ListItemIcon>
                <Checkbox
                  onChange={(_, checked) => {
                    handleInputChange(condition, checked)
                    // viewCondition(condition, true)
                  }}
                  defaultChecked={isSelected}></Checkbox>
              </ListItemIcon>
            </>
        }


      </ListItem>

      <Collapse
        in={open}
      >
        {
          !isError && <>
            <Divider></Divider>
            <Container maxWidth='md'>

              {/* Show Loading */}
              {conditionMutations.isLoading ?
                <Box display={'flex'} justifyContent={'center'} mt={5}>
                  <CircularProgress size={18}></CircularProgress>
                </Box>
                : <Box>
                  <>
                    <DataTable seuils={[]} linesMode={'mean'} option={'up'} conditions={selectedCondition}></DataTable>
                    <ViewChart selectedCondition={selectedCondition} condition={rawCondition} variablesOptions={variablesOptions}></ViewChart>
                  </>
                </Box>
              }
            </Container>
          </>
        }


      </Collapse>
    </>
  )
}

export const ViewChart = memo(function Chart({ selectedCondition, condition, variablesOptions }) {

  if (isEmpty(selectedCondition) || !condition) return <></>
  return (
    <Stack direction={'row'} spacing={2} >
      <Box>
        {selectedCondition?.map((dataSet) => (
          <>
            <EqsChart
              selectedConditions={[condition]}
              isComparaison={false}
              key={dataSet.key}
              dataSet={dataSet.data}
              linesMode={'mean'}
              variablesMode={JSON.stringify(variablesOptions[0])}
              title={dataSet.key}
              fill={true}
              showChartDetails={false}
            />

          </>
        ))}
      </Box>


      <Box position={'relative'}>
        <img src="/horse_back.png" style={{ position: 'absolute', height: '100%', left: '50%', transform: 'translateX(-50%)', aspectRatio: 0.5 }}></img>
        {selectedCondition.map((dataSet, index) => (
          <>
            <Box height={'190px'} sx={{ aspectRatio: '1/1' }} display={'flex'} justifyContent={'space-between'} flexDirection={'column'} py={3} key={dataSet.key} position={'relative'}>

              <EqsCursor
                multi={false}
                key={'up'}
                type={'up'}
                dataSet={dataSet.data}
                selectedThreshold={null}
                linesMode={'mean'}
                color="#36c7b6"
                height={'27px'}
                isActive
              />



            </Box>

          </>
        ))}
      </Box>

    </Stack>
  )
})

export default memo(ConditionView)