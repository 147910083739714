import {
    Modal, Box, Typography, Stack, Autocomplete, TextField,
    CircularProgress,
    Button,
    IconButton
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { findIndex, get } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { applyFilters, getOutils, isFiltersEmpty, populateFilters, filters } from 'src/utils/dashboard';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useLocales from 'src/hooks/useLocales';
import { Close, RestartAlt } from '@mui/icons-material';

function FilterModal({ examens, open, handleClose, filters, isLoading, onSubmit, uniqKey }) {
    //Init modal filter fields
    const [fields, setFields] = useState([])
    const [mounted, setMounted] = useState(true)

    useEffect(() => {
        open && setMounted(true)
    }, [open])

    //date default Value
    let date = fields && fields?.find(e => e?.field === 'createdAt')?.value || null

    const { translate } = useLocales()
    const dispatch = useDispatch()
    useEffect(() => {
        if (examens?.length !== 0) {
            setFields(populateFilters(examens, filters))
            onSubmit(examens)
        }

    }, [examens])



    const updateValue = (value, key) => {
        let temp = [...fields]
        var index = findIndex(temp, { field: key });
        let found = temp.find(e => e?.field === key)
        temp.splice(index, 1, { ...found, value })
        setFields(temp)
    }



    const filter = () => {
        try {
            if (isFiltersEmpty(fields)) return onSubmit(examens)
            let data = applyFilters(fields, examens, uniqKey)
            onSubmit(data)
        } catch (error) {
            console.log(error);
        } finally {
            handleClose()
        }

    }

    //Use custom label
    const getLabel = (key, value) => {
        switch (key) {
            case 'toolName':
                return getOutils(value)
            default:
                return value || ''
        }
    }

    //Style for modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: 1
    };

    const clearFilters = () => {


        onSubmit(examens)
        setFields(() => populateFilters(examens, filters))
        setMounted(false)
        handleClose()

    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            keepMounted={mounted}
        >
            <Box sx={style}>
                <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom={2}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        {translate('filters')}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <Close></Close>
                    </IconButton>
                </Box>

                {isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 5 }}>
                        <CircularProgress />
                    </Box> :
                    <Stack spacing={2}>
                        {fields.map(field => (
                            <Autocomplete
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                key={field?.field}
                                size="small"
                                loading={isLoading}
                                defaultValue=""
                                getOptionLabel={option => getLabel(field?.field, option)}
                                onChange={(e, val) => updateValue(val, field.field)}
                                options={field?.data || []}
                                hidden={get(field, 'hidden', false)}
                                renderInput={(params) => <TextField value={field?.value || ""} InputLabelProps={{ style: { fontSize: 12 } }}
                                    {...params} label={translate('grid.' + field.headerName)} />}
                            />
                        ))}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                            <DesktopDatePicker
                                label={translate('grid.date')}
                                inputFormat="DD/MM/YYYY"
                                value={date}
                                onChange={(val) => updateValue(val, 'createdAt')}
                                renderInput={(params) => <TextField size='small' inputProps={{ style: { fontSize: 12 } }}  {...params} />}

                            />
                        </LocalizationProvider>

                        <Box gap={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                            <Button startIcon={<RestartAlt fontSize='small' />} variant='outlined' onClick={clearFilters}>{translate('general.reset')}</Button>
                            <Button variant='contained' onClick={filter}>{translate('nav.search')}</Button>
                        </Box>
                    </Stack>

                }


            </Box>
        </Modal>
    )
}
FilterModal.defaultProps = {
    filters,
    examens: [],
    uniqKey: "id"
}
export default FilterModal