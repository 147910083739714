import React, { useEffect } from 'react'
import { getExamVetCaseV2 } from 'src/redux/slices/examen'
import { get, isEmpty, isUndefined } from 'lodash'
import { useQuery } from 'react-query'
import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material'
import { useState } from 'react'
import { Drafts, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material'
import { useMemo } from 'react'
import useLocales from 'src/hooks/useLocales'
import { ExamExtractor } from 'src/utils/ExamExtractor'
import { dateComparatorDesc } from 'src/utils/examDashboard'
import { formatDate } from 'src/utils/dashboard'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
function SideBarEcg({ vetCase, onExamOnVetCasesLoaded, horseId, horse, selected, onToggleChange,toggle }) {
    const { translate } = useLocales()
    const navigate = useNavigate()
    const [exams, setExams] = useState([])


    //GET EXAMS In Case
    const { data, isLoading, isError, refetch } = useQuery(['CaseExams', vetCase?.id],
        () => getExamVetCaseV2(vetCase?.id),
        {
            enabled: !isUndefined(vetCase?.id),
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
            onSuccess: ({ data }) => {
                onExamOnVetCasesLoaded(get(data, 'hydra:member'))
                setExams(get(data, 'hydra:member', []).map(e => e?.exam))
            }
        },

    )

    const getImage = useMemo(() => {
        if (horse?.picture) {
            return 'data:image/png;base64,' + horse.picture
        } else {
            return "https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"
        }
    }, [horse?.picture])
    const examClicked = (exam) => {
        let examType = ExamExtractor.getExamType(exam)
        let horse = ExamExtractor.getHorseId(exam)
        let externalId = exam.external_id
        if (examType === 'Equisym') {
            navigate(PATH_DASHBOARD.horses.examenView(
                horse,
                externalId,
            ))
        }

        if (examType === 'Equimetre') {
            navigate(PATH_DASHBOARD.horses.examenEquimetreView(
                horse,
                externalId,
                vetCase?.id
            ))
        }
    }
    return (
        <Box
            width={!toggle ? '220px' : '60px'}
            maxWidth={!toggle ? '220px' : '60px'}
            minWidth={!toggle ? '220px' : '60px'}
            borderRight='1px solid lightgray'
            sx={{ height: '100vh', transition: 'all 0.1s ease-in-out', position: 'relative', flex: 1 }}
        >
            {/* Toggle  */}
            <Box display={'flex'} alignItems={'center'} flexDirection={toggle ? 'column-reverse' : 'row'} justifyContent={'space-between'} py={2}>
                <Box display={'flex'} alignItems={'center'} gap={1} flex={1} maxWidth={!toggle ? '180px !important' : 'auto'}>
                    <Avatar
                        src={getImage}
                        sx={{ cursor: toggle && "pointer" }} onClick={() => onToggleChange(false)} size={10}></Avatar>
                    {!toggle && <Typography textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'} fontWeight={500} variant='body2' title={horse?.name} >{horse?.name}</Typography>}
                </Box>
                {!toggle ?
                    <IconButton onClick={() => onToggleChange(true)} color='primary'>
                        <KeyboardDoubleArrowLeft></KeyboardDoubleArrowLeft>
                    </IconButton> : <IconButton onClick={() => onToggleChange(false)} color='primary'>
                        <KeyboardDoubleArrowRight></KeyboardDoubleArrowRight>
                    </IconButton>
                }
            </Box>
            <Box
                style={{ overflowY: 'scroll', height: '100%', minHeight: '100%', visibility: toggle ? 'hidden' : 'initial' }}
            >
                <Typography variant='subtitle1'>{translate('general.exams')}</Typography>
                <ListExam selected={selected} onExamClick={(id) => examClicked(id)} exams={exams} loading={isLoading}></ListExam>

            </Box>
        </Box>
    )
}

const ListExam = ({ exams, loading, onExamClick, selected }) => {

    const sortExams = useMemo(() => {
        if (!isEmpty(exams)) {
            return [...exams].sort((a, b) => dateComparatorDesc(
                formatDate(get(a, 'timestamp_start.unix', null))
                , formatDate(get(b, 'timestamp_start.unix', null)
                ))
            )
        } else {
            return exams
        }
    }, [exams])
    if (loading) {
        return (
            <Box display={'flex'} flexDirection='column' px={2}>
                {[1, 2, 3, 4].map(e => <Skeleton key={e} width={'100%'} height={40} />)}
            </Box>
        )
    }
    return (
        <List>
            {sortExams.map(exam => (
                <React.Fragment key={exam?.external_id}>
                    <ListItem disablePadding >
                        <ListItemButton selected={exam.external_id === selected} onClick={() => onExamClick(exam)}>
                            <ListItemText secondaryTypographyProps={{ fontSize: 10 }} primaryTypographyProps={{ fontWeight: 600, fontSize: 13 }} primary={ExamExtractor.getLastExam(exam)} />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                </React.Fragment>

            ))}

        </List>
    )
}

export default SideBarEcg