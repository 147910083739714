import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, IconButton, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material'
import { get, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Examen } from 'src/classes/Examen'
import Iconify from '../Iconify'
import { ExamExtractor } from 'src/utils/ExamExtractor'
import useLocales from 'src/hooks/useLocales'
import { formatDate, getLastExamFromCases, getOutils, getOutilsColor } from 'src/utils/dashboard'
import { AccessTime } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import axiosInstance from 'src/utils/axios'
import { getUserLastReports } from 'src/redux/slices/user'
import { setReports } from 'src/redux/slices/blog'
import dayjs from 'dayjs'

function ReportsCard() {
    const { reports } = useSelector(state => state.blog)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { translate } = useLocales()
    const navigate = useNavigate()

    const reportsQuery = useQuery(['reports'], () => getUserLastReports(user?.id), {
        staleTime: 20 * 60 * 1000,
        cacheTime: 20 * 60 * 1000,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch(setReports(get(response, 'data.hydra:member', [])))
        }
    })



    return (
        <Card sx={{minHeight:'100%',minWidth:'100%'}}>
            <CardContent sx={{ width: '100%' }}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='h5'>
                        {translate('general.myReports')}
                    </Typography>

                </Box>
                <Divider sx={{ my: 2 }}></Divider>

                <List dense sx={{ maxHeight: 200, overflowY: 'scroll' }}>

                    {reportsQuery.isLoading || reportsQuery.isFetching ? Array.from(Array(2)).map(i => (
                        <ListItem disableGutters key={i}>
                            <Skeleton height={50} width={'100%'}></Skeleton>
                        </ListItem>
                    )) : <>
                        {isEmpty(reports)?
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Typography variant='body2'>{translate('general.emptyReports')}</Typography>
                        </Box>
                        : reports.map(report => (
                            <ListItem key={report.id}>
                                <ListItemAvatar  >
                                    <Avatar sx={{ borderRadius: 1 }}>
                                        <Iconify icon='fluent-mdl2:report-document' ></Iconify>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Link target='_blank' color='black' href={PATH_DASHBOARD.horses.view(report?.vet_case?.horse?.external_id)}>{report?.vet_case?.horse?.name ?? translate('general.unamed')}</Link>}
                                    secondary={
                                        <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'} mt={0.5}>
                                            <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
                                                <Typography fontSize={12} display={'flex'} gap={0.5} alignItems={'center'}>

                                                    <Iconify icon={'formkit:time'}></Iconify>
                                                    {dayjs(get(report, 'creation_date')).format('DD/MM/YYYY HH:mm')}
                                                </Typography>
                                                <Divider flexItem orientation='vertical'></Divider>
                                                <Typography fontSize={12} display={'flex'} gap={0.5} alignItems={'center'}>
                                                    <Iconify icon={'lucide:hospital'}></Iconify>
                                                    {get(report,'vet_case.team.organisation.name')}
                                                </Typography>
                                                {/* <Link target='_blank' color='GrayText' href={PATH_DASHBOARD.horses.folderView(report?.vet_case?.horse?.external_id, report?.vet_case?.id)}>{report?.vet_case?.case_number ?? translate('general.unamed_folder')}</Link> */}
                                            </Box>
                                        </Box>}
                                >

                                </ListItemText>
                                <ListItemIcon>
                                    <Link download href={report?.pdf_url} target='_blank'>
                                        <IconButton>
                                            <Iconify icon='humbleicons:download-alt'></Iconify>
                                        </IconButton>
                                    </Link>
                                </ListItemIcon>
                            </ListItem>
                        ))}
                    </>}

                </List>
            </CardContent>
        </Card >
    )
}

export default ReportsCard