import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import { getVetCase } from 'src/redux/slices/examen'
import ColumnExamView from './ColumnExamView'
import { Box, CircularProgress } from '@mui/material'
import Error from 'src/components/global/Error'
import { useDispatch } from 'react-redux'
import { setTabsVisibility } from 'src/redux/slices/display'
import { get } from 'lodash'

function Folder() {
    const params = useParams()
    const dispatch = useDispatch()
    const [vetCase, setVetCase] = useState(null)
    const navigate = useNavigate()
    const mutation = useMutation((id) => getVetCase(id), {
        onSuccess: ({ data }) => {
            setVetCase(data)
        }
    })

    useEffect(() => {
        dispatch(setTabsVisibility(false))

        return () => dispatch(setTabsVisibility(true))
    }, [])


    useEffect(() => {
        if (!params?.id) return

        mutation.mutateAsync(params.id)
    }, [params?.id])

    

    if (mutation.isLoading || !vetCase) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' marginTop={3}>
                <CircularProgress />
            </Box>
        )
    }

    if (mutation.isError) {
        return <Error onClick={() => mutation.mutateAsync(params?.id)} />
    }


    return (
        <>
            <ColumnExamView refreshVetCase={() => mutation.mutate(params?.id)} item={vetCase} onBack={() => navigate(`/horses/${get(vetCase, 'horse.external_id')}/list-view`)} />
        </>
    )
}

export default Folder