import axios from "axios";
import { useNavigate } from "react-router";
import { store } from "src/redux/store";
// config
import { HOST_API } from "../config";

// ----------------------------------------------------------------------

const headers = {
  'access-control-allow-origin': '*',
}


//we want to set debug email on dev env
//if (process.env.REACT_APP_ENV === 'dev') headers['X-DVET-DEBUG-EMAIL-RECIPIENT'] = process.env.REACT_APP_DEBUG_MAIL


const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error?.response?.status) {
      window.localStorage.clear()
      store.dispatch({ type: 'LOGOUT' })
      return Promise.reject(error);
    }
    else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
