import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import Cover from './Cover'
import './style.css'
import Page from './PageLd'
import Conclustion from './Conclustion'
import MoreInformations from './MoreInformations'
import { useSearchParams } from 'react-router-dom'
import { setSession } from 'src/utils/jwt'
import axios from 'axios'
import { saveUser, updateStoreFromUser } from 'src/redux/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { Examen } from 'src/classes/Examen'
import { getHorse } from 'src/redux/slices/horse'
import { flatten, get, isEmpty, uniq } from 'lodash'
import { getExamen, getVetCase } from 'src/redux/slices/examen'
import useLocales from 'src/hooks/useLocales'
import { Condition } from 'src/classes/Condition'
function Repport() {
    //State
    const [vetCase, setVetCase] = useState(null)
    const [report, setReport] = useState(null)
    const [results, setResults] = useState([])
    const [conclusion, setConclusion] = useState('')
    const [exam, setExam] = useState(null)
    //Hooks
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()
    const { translate } = useLocales()
    // //Mutations
    const conditionMutations = useMutation((data) => Examen.getConditionData(data))
    const getExamMutation = useMutation((exam) => dispatch(getExamen(exam)))
    const getReportMutation = useMutation(() => Examen.getRapport(REPORT_ID))
    const getVetCaseMutation = useMutation((id) => getVetCase(id))
    //Get Token
    const TOKEN = searchParams.get('token')
    //Get report id
    const REPORT_ID = searchParams.get('reportId')

    const isLoading = getReportMutation.isLoading || getVetCaseMutation.isLoading || conditionMutations.isLoading || getExamMutation.isLoading

    //Effects 

    useEffect(() => {
        if (!TOKEN) return

        setSession(TOKEN)
        const id = localStorage.getItem("id");

        axios.get(`/users/${id}`).then(result => {
            saveUser(result.data);

            
          let last_org = get(result.data, 'last_organisation.id')
          if (last_org) {
            localStorage.setItem("last_user_in_organisation", last_org)
          }


            updateStoreFromUser(result.data);
            dispatch({
                type: "LOGIN",
            });
        });

    }, [TOKEN])

    useEffect(() => {
        if (REPORT_ID) {
            getReportMutation.mutateAsync()
                .then(result => {
                    setReport(result.data)
                    setConclusion(get(result.data, 'data.conclusion', ''))
                    handleConditions(get(result.data, 'data.sections', []), get(result.data, 'exams[0].external_id'))
                    getVetCaseMutation.mutateAsync(get(result.data, 'vet_case.id'))
                        .then(({ data }) => {
                            setVetCase(data)
                        })
                }).catch(err => {
                    console.log({ err });
                })
        }
    }, [REPORT_ID])

    const formatConditions = (temp) => {
        if (isEmpty(temp)) return []
        let conditions = new Condition(temp, false)
        conditions
            .setConditionsColor()
            .setTeteData()
            .setGarrotData()
            .setCroupeData()
        return conditions.getData()
    }


    const pages = useMemo(() => {
        if (!report || isEmpty(results)) return []
        return get(report, 'data.sections', []).map(item => {
            let filteredResults = results.filter(i => item.conditions.includes(i?.uuid))
            return {
                comments: item?.comments ?? [],
                conditions: formatConditions(filteredResults),
                exam: get(report, 'exams[0].external_id'),
                title: item?.label,
                key: item.section_key
            }
        })
    }, [report, results])

    //Functions
    const handleConditions = async (data, exam) => {
        if (!exam || isEmpty(data)) return

        //Fetch Exam
        let { data: examen } = await getExamMutation.mutateAsync(exam)

        setExam(examen)
        //Get condition ids
        let condition_ids = uniq(flatten(data.map(item => item?.conditions)))

        if (isEmpty(condition_ids)) return

        let promises = []
        condition_ids.forEach(condition => {
            promises.push(conditionMutations.mutateAsync({ uuid: condition, exam_id: exam }))
        })


        Promise.all(promises).then(result => {
            let tempData = []
            result.forEach(result => {
                let condition_id = Object.keys(result.data)
                let data = get(Object.values(result.data), '[0]', {})
                //Check if Loaded condition has error or not loaded succefully
                if (data.hasOwnProperty('error') && !isEmpty(data?.error)) {
                    return 0;
                }
                tempData.push({ ...examen?.conditions.find(e => e?.uuid === condition_id?.[0]), condition: data })
            })
            setResults(tempData)
        })
    }

    const totalPages = useMemo(() => {
        return (pages?.length ?? 0) + 3
    }, [pages])





    if (isLoading) {
        return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
            <CircularProgress></CircularProgress>
        </Box>
    }


    return (
        <Box className='report_wrapper'>

            <Cover
                pageIndex={1}
                totalPages={totalPages}
                examen={exam}
                clinique={get(vetCase, 'team.organisation.name')}
                pages={pages} vetCase={vetCase} report={report}></Cover>
            {
                pages.map((page, i) => (
                    <Page
                    
                        clinique={get(vetCase, 'team.organisation.name')}
                        key={page.key}
                        results={results}
                        title={translate(page.title)}
                        seuils={page.seuils}
                        conditions={page.conditions}
                        comments={page.comments}
                        pageIndex={i + 2}
                        totalPages={totalPages}
                    >
                    </Page>
                ))
            }
            <Conclustion
                conclusion={conclusion}
                pageIndex={pages.length + 2}
                totalPages={totalPages}
                clinique={get(vetCase, 'team.organisation.name')}
            ></Conclustion>
            <MoreInformations
                pageIndex={pages.length + 3}
                totalPages={totalPages}
                clinique={get(vetCase, 'team.organisation.name')}
            ></MoreInformations>

        </Box>
    )
}

export default Repport