import React from 'react'
import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
function BreadcrumbsHorse({ children }) {
    const { breadcrumbs } = useSelector(state => state.display)


    return (
        <Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs.map((e, i) => (
                    e.isDisabled ?
                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                            <Typography key={i} fontWeight={600} color="primary">{e.text}</Typography>
                           <Typography variant='caption'>{e?.sectionTitle}</Typography>
                            

                        </Box>
                        :

                        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                            <Link key={i} sx={{ fontWeight: '600' }} underline="hover" color="inherit" href={e.link}>
                                {e.text}
                            </Link>
                            {
                                e.sectionTitle && <Typography variant='caption'>{e.sectionTitle}</Typography>
                            }

                        </Box>



                ))}
            </Breadcrumbs>


            {children}

        </Box>

    )
}

export default BreadcrumbsHorse