import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { isEmpty } from "lodash";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  examens: [],
  filteredExamens: [],
  examen: null,
  archive: null,
  sortBy: "dateDesc",
  filters: {
    horseExternalId: "",
  },
  pinned: {
    title: '',
    observation: '',
    conditions: [],
  },
  videosUri: [],
  examCases: [],
  examCasesModal: false,
  selectedCasesHorse: null,

};

const slice = createSlice({
  name: "examen",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EXAMENS
    getExamensSuccess(state, action) {
      state.isLoading = false;
      state.examens = action.payload;
    },

    // GET EXAMEN
    getExamenSuccess(state, action) {
      state.isLoading = false;
      state.examen = action.payload;
    },

    //  SORT & FILTER EXAMENS
    sortByExamens(state, action) {
      state.sortBy = action.payload;
    },

    filterExamens(state, action) {
      state.filters.horseExternalId = action.payload.horseExternalId;
    },

    //Set Filtered Exams 
    setExams(state, { payload }) {
      state.filteredExamens = payload
    },
    setPinned(state, { payload }) {
      state.pinned = payload
    },
    setVideosUri(state, { payload }) {
      state.videosUri = payload
    },
    setExamCases(state, { payload }) {
      state.examCases = payload
    },
    setExamCasesModal(state, { payload }) {
      state.examCasesModal = payload
    },
    setSelectedCasesHorse(state, { payload }) {
      state.selectedCasesHorse = payload
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByExamens, filterExamens, setExams, getExamensSuccess, setPinned, setVideosUri, setExamCases, setExamCasesModal, setSelectedCasesHorse } = slice.actions;

// ----------------------------------------------------------------------

export function getExamens(organisationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`organisations/${organisationId}/exams`);
      // console.log(response.data["hydra:member"]);
      const result = response.data["hydra:member"].map((examen) => {
        const _examen = examen;
        _examen.type = examen["@type"];
        if (_examen.type == "ExamEquisym") {
          _examen.createdAt = new Date(_examen.timestamp_start.unix * 1000);
        }

        return _examen;
      });
      dispatch(slice.actions.getExamensSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}

// ----------------------------------------------------------------------

export function getExamen(external_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/exams/${external_id}/`);
      const _examen = response.data;
      _examen.type = response.data["@type"];
      dispatch(slice.actions.getExamenSuccess(_examen));
      return response
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}

// ----------------------------------------------------------------------

// export function getArchiveFromExamen(external_id) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/exams/${external_id}/archive`);
//       const _archive = response.data;
//       console.log("download");
//       saveAs(_archive, "tmp.zip");
//       //dispatch(slice.actions.getExamenSuccess(_examen));
//     } catch (error) {
//       console.error(error);
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// ----------------------------------------------------------------------
export async function addVetCase(data) {
  return await axios.post('/vet_cases', data)
}
export async function addUserVetCase(data) {
  return await axios.post('/user_in_vet_cases', data)
}
export async function deleteUserVetCase(ids) {
  return await axios.delete('/user_in_vet_cases/' + ids)
}

export async function addExamVetCase(data) {
  return await axios.post('/exam_in_vet_cases', data)
}

export async function deleteVetCase(id) {
  return await axios.delete(`/vet_cases/${id}`)
}

export async function getVetCase(id) {
  return await axios.get(`/vet_cases/${id}`)
}
export async function getVetCases(id, page = 1, search = '') {
  return await axios.get(`/user_in_organisations/${id}/vet_cases?page=${page}`.concat(search ? `&q=${search}` : ''))
}
export async function getVetCasesRestricted(id, page = 1, search = '') {
  return await axios.get(`/organisations/${id}/vet_cases?page=${page}`.concat(search ? `&q=${search}` : ''))
}
// ----------------------------------------------------------------------
export async function getExamVetCase(id) {
  return await axios.get(`/vet_cases/${id}/exams`)
}

//Request to get exam in vet case with more details in exam
export async function getExamVetCaseV2(id, options = []) {
  return await axios.get(`/vet_cases/${id}/exam_in_vet_cases?`
    .concat(!isEmpty(options) ? options.join('&') : '')
  )
}



export async function deleteExamInVetCase(id) {
  return await axios.delete(`/exam_in_vet_cases/${id}`)
}

export async function getExamInVetCase(id) {
  return await axios.get(`/exam_in_vet_cases/${id}`)
}
