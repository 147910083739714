import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem, CircularProgress, ListItemIcon, ListItemText, ListItem, List } from "@mui/material";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { switchToUserInOrganisationFromId } from "src/redux/slices/user";

// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import useLocales from "../../../hooks/useLocales";
// components
import MyAvatar from "../../../components/MyAvatar";
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";
import { useMutation, useQueryClient } from "react-query";
import { getExamens } from "src/redux/slices/examen";
import { get, isEmpty } from "lodash";
import OrganisationMenu from "./OrganisationMenu";
import { useMemo } from "react";

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function AccountPopover() {

  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { logout } = useAuth();

  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const { user, userInOrganisation } = useSelector((state) => state.user);

  const { isLoading } = useSelector(state => state.examen)

  const [open, setOpen] = useState(null);

  const MENU_OPTIONS = [
    {
      label: "settings.myAccount",
      linkTo: "/settings/account",
      icon: "carbon:user-avatar",
      memberOnly: false,
      isVisible: true,
    },
    {
      label: "settings.organisationManagement",
      linkTo: "/settings/organisation",
      icon: "fluent:people-team-28-regular",
      memberOnly: true,
      isVisible: !isEmpty(user?.user_in_organisations) || !isEmpty(user?.limited_access_organisations),

    },
    /*   {
        label: "settings.history",
        linkTo: "/settings/history",
        icon: "fluent:history-28-regular",
        memberOnly: false,
      }, */
    {
      label: "settings.help",
      linkTo: "/settings/help",
      icon: "tabler:help",
      memberOnly: false,
      isVisible: true,

    },
  ];

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  const MENU = useMemo(() => {
    return !get(userInOrganisation, 'restricted', false) ? MENU_OPTIONS : [...MENU_OPTIONS].filter(el => el.label !== 'settings.organisationManagement')
  }, [userInOrganisation])



  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  let userInOrg = get(user, 'user_in_organisations', []) || []

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstname} {user?.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

     

        <Divider sx={{ borderStyle: "dashed", pt: 1 }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            option.isVisible &&
            <MenuItem
              disabled={
                option.memberOnly === true &&
                (!isEmpty(userInOrg) && userInOrg.filter(
                  (_item) => _item?.accepted === true
                ).length === 0)
              }
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {translate(option.label)}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: "dashed" }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate("settings.logout")}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
