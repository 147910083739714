import { WarningAmberOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import useLocales from 'src/hooks/useLocales';


function DeleteModal({ loading, open, handleClose, title, subTitle, onCancel, onDelete, deleteTextButton }) {
    const { translate } = useLocales()

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography alignItems='center' display='flex' gap={1} id="modal-modal-title" variant="h6" component="h2">
                    <WarningAmberOutlined color='error' />   {title}
                </Typography>
                <Typography id="modal-modal-title" marginLeft={4} marginTop={1} variant="body2" component="h2">
                    {subTitle}
                </Typography>
                <Box width='100%' marginTop={5} display='flex' justifyContent='end' alignItems='center' gap={2}>
                    <Button disabled={loading} onClick={onCancel} variant='outlined'>{translate('nav.cancel')}</Button>
                    <LoadingButton loading={loading} onClick={onDelete} color='error'>{translate(deleteTextButton)}</LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}
DeleteModal.defaultProps = {
    deleteTextButton: 'nav.delete'
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1,
};

export default DeleteModal