import { Box, Typography, makeStyles } from '@mui/material'
import React from 'react'
import useLocales from 'src/hooks/useLocales'

function Header({clinique}) {
    const {translate} = useLocales()

    return (
        <Box width={'100%'} height={150} sx={{ backgroundImage: `url(${require('../../assets/eqsReportBanner.png')})`,backgroundSize:'cover',backgroundRepeat:'no-repeat' }} display={'flex'} alignItems={'center'}  px={5}>
            <Box>
                <p className='header_text'>{translate('repport.table.supplementary.titleHeader')}</p>
                <p className='header_text'>{clinique}</p>
            </Box>
        </Box>
    )
}

export default Header