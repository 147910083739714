import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ArrowForward, Close, ContentCopy, Share } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { Button, IconButton, OutlinedInput, Stack, TextField } from '@mui/material';
import { getTeam } from 'src/redux/slices/team';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import UserList from './UserList';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};
function ShareModal({ open, handleClose, vetCase }) {
    const theme = useTheme();
    const { horse } = useSelector(state => state.horse)

    const [value, setValue] = React.useState(0);
    const [teamUser, setTeamUser] = React.useState([])

    //Prepare Mutatuion
    let teamId = get(vetCase, 'team.id')
    const teamMutation = useMutation(() => getTeam(teamId), {
        onSuccess: ({ data }) => {
            setTeamUser(get(data, 'user_in_teams', []))
        }
    })

    const query = useQueryClient()
    useEffect(() => {
        if (teamId) {
            teamMutation.mutate()
        }
    }, [vetCase])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <Share color='primary' fontSize='small' />
                        <Typography color='primary' variant='h6'>Share</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>

                {/* Content */}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Share" />
                    <Tab label="Members" />
                </Tabs>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        {/* Email redirection */}
                        <Box gap={1} display='flex' alignItems='center'>
                            <Typography color='primary' variant='body1' fontWeight={600}>Mail</Typography>
                            <ArrowForward fontSize='small' color='primary' />
                        </Box>
                        <TextField inputProps={{ style: { fontSize: 12 } }} sx={{ marginBlock: 1 }} id="outlined-basic" placeholder="Email,member or team" fullWidth variant="outlined" />

                        <Typography color='primary' variant='body1' fontWeight={600}>Link</Typography>
                        <Typography variant='caption' fontWeight={600}>🔗 Tout utilisateur disposant du lien</Typography>

                        <OutlinedInput inputProps={{ style: { fontSize: 12 } }} sx={{ marginBlock: 1 }} id="outlined-basic" value='http://localhost:3000/dashboard' disabled fullWidth variant="filled"
                            endAdornment={
                                <IconButton>
                                    <ContentCopy />
                                </IconButton>
                            }
                        />

                        <Stack spacing={2} gap={1} flexDirection='row' justifyContent='flex-end' alignItems='center'>
                            <Button onClick={handleClose} variant='text'>Annuler</Button>
                            <Button sx={{ marginTop: '0 !important' }} variant='contained'>Terminé</Button>
                        </Stack>

                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <UserList onRemoveUser={()=>query.refetchQueries(['vet_case', horse.external_id])} teamUsers={teamUser} caseUsers={get(vetCase, 'user_in_vet_cases', [])} loading={teamMutation.isLoading} />
                    </TabPanel>
                </SwipeableViews>

                {/* End of Content */}
            </Box>
        </Modal>
    )
}

export default ShareModal

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingBlock: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}