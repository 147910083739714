import { m } from "framer-motion";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Typography,
  Container,
  Stack,
  DialogTitle,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useLocales from "../../hooks/useLocales";
import useAuth from "../../hooks/useAuth";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { openModalNewTeam, closeModalNewTeam } from "../../redux/slices/team";
// components
import {
  MotionContainer,
  varBounce,
  DialogAnimate,
} from "../../components/animate";
// assets
import { BookingIllustration } from "../../assets";
// sections
import { TeamForm } from "../../sections/team";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function OrganisationNoOrganisation() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { isOpenModalNewTeam, selectedTeam } = useSelector(
    (state) => state.team
  );

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  const handleAddTeam = () => {
    dispatch(openModalNewTeam());
  };

  const handleCloseModalNewTeam = () => {
    dispatch(closeModalNewTeam());
  };

  return (
    <RootStyle>
      <Container component={MotionContainer}>
        <Box sx={{ maxWidth: "75%", margin: "auto", textAlign: "center" }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
            {translate('settings.notInOrg')}
            </Typography>
          </m.div>
          <Typography sx={{ color: "text.secondary" }}>
          {translate('settings.suggestToJoin')}
          </Typography>

      {/*     <m.div variants={varBounce().in}>
            <BookingIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>
 */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            mt={5}
          >
            <Button
              to="/organisation/new"
              sx={{ textTransform: "none" }}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              {"Créer une clinique"}
            </Button>
            <Button
              to="/"
              onClick={handleLogout}
              size="large"
              variant="contained"
              component={RouterLink}
              color="error"
            >
              {translate("settings.logout")}
            </Button>
          </Stack>
        </Box>
      </Container>
    </RootStyle>
  );
}
