// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import './styles/global.css'
// Create a client
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from "@sentry/react";
import './components/exam/style.css'
import VetCasesModal from "./components/dashboard/VetCasesModal";
import { useDispatch, useSelector } from "react-redux";
import { setExamCasesModal } from "./redux/slices/examen";
// ----------------------------------------------------------------------

function App() {
  const { examCases, examCasesModal,selectedCasesHorse } = useSelector((state) => state.examen);
  const dispatch = useDispatch()
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              {/* <Settings /> */}
              <ScrollToTop />
                <Router />
                {process.env.REACT_APP_ENV === "dev" && <ReactQueryDevtools />}

                {/* Global Modals */}
                <VetCasesModal
                open={examCasesModal}
                handleClose={() => dispatch(setExamCasesModal(false))}
                cases={examCases}
                horse={selectedCasesHorse}
            />
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
export default Sentry.withProfiler(App)