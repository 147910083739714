import { get, isEmpty, max, min } from "lodash"
import { formatDate } from "src/utils/dashboard"

export class Condition {



    constructor(conditions = [], isVideo = false) {
        this.conditions = conditions
        this.COLORS = ['rgba(54,199,182,1)', 'rgba(4,139,181,1)', 'rgba(193,20,193,1)', 'rgba(79,31,122,1)', 'rgba(255,0,122,1)']
        this.teteDataSet = { key: 'examEnums.tete', data: [] }
        this.garrotDataSet = { key: 'examEnums.garrot', data: [] }
        this.croupeDataSet = { key: 'examEnums.croupe', data: [] }
        this.isVideo = isVideo
    }

    setConditions(conditions) {
        this.conditions = conditions
    }

    conditionsContainsColors() {
        return this.conditions.some(e => e?.color)
    }

    setConditionsColor() {
        if (!isEmpty(this.conditions) && !this.conditionsContainsColors()) {
            let temp = [...this.conditions].map((el, index) => {
                return {
                    ...el,
                    color: this.COLORS[index]
                }
            })

            this.setConditions(temp)

        }
        return this
    }

    getStaticDataFromCondition(condition) {
        const anteriorLeft = {
            title: "AG",
            start: get(condition, 'curves_appui.AG.mean.begin', 0),
            end: get(condition, 'curves_appui.AG.mean.end', 0),
        };
        const anteriorRight = {
            title: "AD",
            start: get(condition, 'curves_appui.AD.mean.begin', 0),
            end: get(condition, 'curves_appui.AD.mean.end', 0),
        };
        const anteriorOther = { start: 100, end: 150 };

        const posteriorLeft = {
            title: "PG",
            start: get(condition, 'curves_appui.PG.mean.begin', 0),
            end: get(condition, 'curves_appui.PG.mean.end', 0),
        };
        const posteriorRight = {
            title: "PD",
            start: get(condition, 'curves_appui.PD.mean.begin', 0) + 100,
            end: get(condition, 'curves_appui.PD.mean.end', 0) + 100,
        };
        const posteriorOther = { start: 0, end: posteriorLeft.start - 1 };

        return {
            anteriorLeft,
            anteriorRight,
            anteriorOther,
            posteriorLeft,
            posteriorRight,
            posteriorOther,
        }
    }

    setTeteData() {
        if (!isEmpty(this.conditions)) {
            this.conditions.forEach(({ condition, color, timestamp_start, figure, gait, ground,uuid }) => {
                let { anteriorLeft, anteriorRight, anteriorOther } = this.getStaticDataFromCondition(condition)

                let data = Object.assign({
                    name: "Tete",
                    data: get(condition, 'curves_depl["Tete"]', {}),
                    type: "spline",
                    color,
                    date: formatDate(timestamp_start?.unix, 'DD/MM HH:mm'),
                    unix: timestamp_start?.unix,
                    figure,
                    gait,
                    ground,
                    uuid,
                    nbStrides: get(condition, 'nbStrides', 0),
                    id: "tete",
                    stridesRange: {
                        left: anteriorLeft,
                        right: anteriorRight,
                        other: anteriorOther,
                    },
                    si: get(condition, 'SI.Tete', {}),
                    tg: get(condition, 'T_G.Tete', {}),
                    td: get(condition, 'T_D.Tete', {}),
                    vg: get(condition, 'V_G.Tete', {}),
                    vd: get(condition, 'V_D.Tete', {}),
                }, this.isVideo ? {
                    curvesVideo: get(condition, 'curves_video["Tete"]', {}),
                    curvesVideoFoulees: get(condition, 'curves_video["Tete"].foulee', {}),
                    curvesVideoD: get(condition, 'curves_video.AD.appui', {}),
                    curvesVideoG: get(condition, 'curves_video.AG.appui', {}),
                } : {})

                this.teteDataSet.data.push(data)
            })
        }
        return this

    }

    setGarrotData() {
        if (!isEmpty(this.conditions)) {
            this.conditions.forEach(({ condition, color, timestamp_start, figure, gait, ground,uuid }) => {

                let { anteriorLeft, anteriorRight, anteriorOther } = this.getStaticDataFromCondition(condition)

                let data = Object.assign({
                    name: "Garrot",
                    data: get(condition, 'curves_depl["Garrot"]', {}),
                    type: "spline",
                    color,
                    date: formatDate(timestamp_start?.unix, 'DD/MM HH:mm'),
                    unix: timestamp_start?.unix,
                    figure,
                    gait,
                    ground,
                    uuid,
                    nbStrides: get(condition, 'nbStrides', 0),
                    id: "garrot",
                    stridesRange: {
                        left: anteriorLeft,
                        right: anteriorRight,
                        other: anteriorOther,
                    },
                    si: get(condition, 'SI.Garrot', {}),
                    tg: get(condition, 'T_G.Garrot', {}),
                    td: get(condition, 'T_D.Garrot', {}),
                    vg: get(condition, 'V_G.Garrot', {}),
                    vd: get(condition, 'V_D.Garrot', {}),
                }, this.isVideo ? {
                    curvesVideo: get(condition, 'curves_video["Garrot"]', {}),
                    curvesVideoFoulees: get(condition, 'curves_video["Tete"].foulee', {}),
                    curvesVideoD: get(condition, 'curves_video.AD.appui', {}),
                    curvesVideoG: get(condition, 'curves_video.AG.appui', {}),
                } : {})

                this.garrotDataSet.data.push(data)
            })
        }
        return this
    }
    setCroupeData() {
        if (!isEmpty(this.conditions)) {
            this.conditions.forEach(({ condition, color, timestamp_start, figure, gait, ground,uuid }) => {

                let { posteriorLeft, posteriorRight, posteriorOther } = this.getStaticDataFromCondition(condition)

                let data = Object.assign({
                    name: "Croupe",
                    data: get(condition, 'curves_depl["Croupe"]', {}),
                    type: "spline",
                    id: "croupe",
                    color,
                    date: formatDate(timestamp_start?.unix, 'DD/MM HH:mm'),
                    unix: timestamp_start?.unix,
                    figure,
                    gait,
                    ground,
                    uuid,
                    nbStrides: get(condition, 'nbStrides', 0),
                    stridesRange: {
                        left: posteriorLeft,
                        right: posteriorRight,
                        other: posteriorOther,
                    },
                    si: get(condition, 'SI.Croupe', {}),
                    tg: get(condition, 'T_G.Croupe', {}),
                    td: get(condition, 'T_D.Croupe', {}),
                    vg: get(condition, 'V_G.Croupe', {}),
                    vd: get(condition, 'V_D.Croupe', {}),
                }, this.isVideo ? {
                    curvesVideo: get(condition, 'curves_video["Croupe"]', {}),
                    curvesVideoFoulees: get(condition, 'curves_video["Tete"].foulee', {}),
                    curvesVideoD: get(condition, 'curves_video.AD.appui', {}),
                    curvesVideoG: get(condition, 'curves_video.AG.appui', {}),
                } : {})

                this.croupeDataSet.data.push(data)
            })
        }
        return this

    }


    getData() {
        return [this.teteDataSet, this.garrotDataSet, this.croupeDataSet]
    }

    static getMinMax(conditions = []) {
        console.log({maxMinCond:conditions})
        if (isEmpty(conditions)) {
            return {
                min: 0,
                max: 0
            }
        } else {
            let mins = min(conditions.map(condition => {
                return condition?.data?.minY
            }))

            let maxs = max(conditions.map(condition => {
                return condition?.data?.maxY 
            }))
            return {
                max: maxs,
                min: mins
            }

        }
    }
}