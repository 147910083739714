import { Box, Card, CardContent, Divider, Grid, Skeleton, Typography } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setStats } from 'src/redux/slices/blog'
import axiosInstance from 'src/utils/axios'

function StatsCards({ lang }) {
    const { stats } = useSelector(state => state.blog)
    const dispatch = useDispatch()
    const query = useQuery('Stats', () => axiosInstance.get('/statistics'), {
        staleTime: 20 * 60 * 1000,
        cacheTime: 20 * 60 * 1000,
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch(setStats(response.data?.items ?? []))
        }
    })


    if (query.isLoading || query.isFetching) {
        return <Grid container spacing={4}>
            <Grid md={12} item sm={12}>
                <Skeleton variant='rectangular' width={'100%'} height={173} sx={{ borderRadius: 2 }}></Skeleton>
            </Grid>
        </Grid>
    }



    return (
        <Card sx={{ height: '100%' }}>
            <CardContent >
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} gap={3}>

                    {
                        stats.map(s => (
                            <Box >
                                <Typography color='InactiveCaptionText' variant='subtitle2'>{s.label}</Typography>
                                <Typography variant='h3'>{s.value}</Typography>
                            </Box>
                        ))
                    }
                </Box >

            </CardContent>
        </Card >
    )
}

export default memo(StatsCards)