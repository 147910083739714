import { capitalCase } from "change-case";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
  Collapse,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useResponsive from "../../hooks/useResponsive";
import useLocales from "../../hooks/useLocales";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
// sections
import { LoginForm } from "../../sections/auth/login";
import RegisterProvider from "src/components/auth/RegisterProvider";
import { providers } from "src/utils/authProvider";
import LanguagePopover from "src/layouts/dashboard/header/LanguagePopover";
import { useState } from "react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive("up", "sm");

  const [open, setOpen] = useState(false)

  const { translate } = useLocales();

  let navigate = useNavigate()

  const getSelectedProvider = (link) => {
    navigate(link)
  }

  return (
    <Page title={translate("auth.login")}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          <LanguagePopover></LanguagePopover>

        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate("auth.signInMessage")}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {translate("auth.enterDetails")}
                </Typography>
              </Box>



            </Stack>

            <Alert severity="info" sx={{ mb: 3 }}>
              {translate("auth.enterDetailsInformations")}
            </Alert>
            <Stack direction={'column'} gap={2}>
              <RegisterProvider withDivider data={providers} handleClick={getSelectedProvider} />

              {
                !open && <Button size="large" variant="outlined" onClick={() => setOpen(!open)}>{translate('auth.toSignIn')}</Button>
              }

              <Collapse in={open}>
                <LoginForm />
              </Collapse>
              <Box display={'flex'} justifyContent={'end'}>

                <a style={{ textDecoration: 'none', color: 'GrayText', fontSize: '14px' }} href={PATH_AUTH.register}>{translate('auth.createAccount')}</a>
              </Box>
            </Stack>


            {/*  Register provider for equisym and Equimetre  */}


          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
