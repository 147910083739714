import { Card, CardContent, CardMedia, Skeleton, Typography } from '@mui/material'
import axios from 'axios'
import { get } from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useMutation } from 'react-query'

function CardBlog({ item }) {
    const [image, setImage] = useState(null)
    const imageMutation = useMutation(() => axios.get(`https://vet.arioneo.com/wp-json/wp/v2/media/${item?.featured_media}`))

    useEffect(() => {
        imageMutation.mutateAsync()
            .then(result => {
                setImage(get(result.data, 'media_details.sizes.full.source_url'))
            })
    }, [item.featured_media])


    return (
        <a  href={item?.link} target='_blank' rel="noreferrer" style={{ textDecoration: 'none',height:'100%' }}>
            <Card sx={{height:'100%'}}>
                {
                    imageMutation.isLoading ?
                        <Skeleton variant="rectangular" sx={{ width: '100%' }} height={280} />

                        : <CardMedia
                            sx={{ height: 280 }}

                            image={image ?? 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'}

                        />
                }

                <CardContent sx={{ maxHeight: 400, overflowY: 'auto' }} >
                    <Typography gutterBottom variant="h5" component="div" dangerouslySetInnerHTML={{__html:item?.title?.rendered}}>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        <span dangerouslySetInnerHTML={{ __html: item?.excerpt?.rendered }}></span>
                    </Typography>
                </CardContent>
            </Card>
        </a>
    )
}

export default CardBlog