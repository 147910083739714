import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Typography, Button } from "@mui/material";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER, NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import PlusPopover from "./PlusPopover";
import NotificationsPopover from "./NotificationsPopover";

// components
import { NavSectionHorizontal } from "../../../components/nav-section";
//
import navConfig from "../navbar/NavConfig";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import useLocales from "src/hooks/useLocales";
import { LogoutOutlined } from "@mui/icons-material";
import DeleteModal from "src/components/dashboard/DeleteModal";
import { useMutation } from "react-query";
import { deleteUserVetCase, getVetCasesRestricted } from "src/redux/slices/examen";
import { useSnackbar } from "notistack";
import { getAuth } from "src/redux/slices/user";
import { saveUser } from "src/redux/slices/user";
import { updateStoreFromUser } from "src/redux/slices/user";
import MenuAddPopover from "./MenuAddPopover";

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isCollapse" && prop !== "isOffset",
})(({ isCollapse, isOffset, withNavbar, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: theme.customShadows.z4,
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    /*     width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(!withNavbar && {
          width: `calc(100% - 1px)`,
        }),
         ...(isCollapse && {
          width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),  */
    width: '100%',
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));



// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  withNavbar = false,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);
  const { updateUserLang } = useLocales()
  const { userInOrganisation, user } = useSelector(state => state.user)
  const [open, setOpen] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const isDesktop = useResponsive("up", "lg");
  const { translate } = useLocales()
  let user_org_id = get(userInOrganisation, 'id', null)
  const deleteUserInVetCaseMutation = useMutation((ids) => deleteUserVetCase(ids))
  const getUserVetCaseMutation = useMutation(() => getVetCasesRestricted(user_org_id))
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()
  const getUserInVetCaseIds = async () => {
    let { data } = await getUserVetCaseMutation.mutateAsync()
    let cases = get(data, 'hydra:member', [])
    let ids = []
    !isEmpty(cases) && cases.map(vetCase => {
      let _ids = vetCase?.user_in_vet_cases.map(c => c?.id)
      ids.push(..._ids)
    })

    return ids
  }

  const formatIds = (ids = []) => {
    let str = ''
    if (!isEmpty(ids)) {
      str = String(ids[0])
      if (ids.length > 1) {
        for (let index = 1; index < ids.length; index++) {
          if (index == 1) {
            str = str.concat('?additional-id[]=' + ids[index])
          } else {
            str = str.concat('&additional-id[]=' + ids[index])
          }
        }
      }
    }
    return str
  }

  const leaveOrg = async () => {
    let ids = await getUserInVetCaseIds()
    let strIds = formatIds(ids)


    deleteUserInVetCaseMutation.mutateAsync(strIds)
      .then(async (result) => {
        enqueueSnackbar(translate('snackbar.leaveSuccessOrg'), {
          variant: 'success'
        })
        localStorage.removeItem('last_user_in_organisation')
        await getUser()

      }).catch(err => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      }).finally(() => {
        setOpen(false)

      })
  }

  const getUser = async () => {
    try {
      setUserLoading(true)
      const user = await getAuth()


      let last_org = get(user.data, 'last_organisation.id')
      if (last_org) {
        localStorage.setItem("last_user_in_organisation", last_org)
      }


      dispatch(updateStoreFromUser(user.data))
    } catch (err) {
      enqueueSnackbar(translate('general.error'), {
        variant: 'error'
      })
    } finally {
      setUserLoading(false)

    }

  }


  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      withNavbar={withNavbar}
    >
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {isDesktop && <NavSectionHorizontal navConfig={navConfig} />}

        <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center' justifyContent='center'>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover />
          <DeleteModal title={translate('leaveOrg')} subTitle={translate('modal.leaveConfirmation')} onCancel={() => setOpen(false)} handleClose={() => setOpen(false)} open={open} deleteTextButton='team:leave' onDelete={leaveOrg} loading={deleteUserInVetCaseMutation.isLoading || getUserVetCaseMutation.isLoading || userLoading}></DeleteModal>
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
