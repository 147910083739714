import { useState, useEffect } from "react";

import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import AnnotationsFactory from "highcharts/modules/annotations";
import ReactPlayer from "react-player";

// @mui
import { Box, Stack, Typography } from "@mui/material";
//components
import EqsCursor from "./EqsCursor";
import { LinesMode } from "./EqsWindow";
// config
import { HEADER } from "../../config";
import { get } from "lodash";
import EqsVideoDownload from "./EqsVideoDownload";
import deepmerge from "deepmerge";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVideosUri } from "src/redux/slices/examen";
import { useMemo } from "react";

highchartsMore(Highcharts);
AnnotationsFactory(Highcharts);

const colors = {
  chart: {
    strideLeft: "rgba(249, 170, 245, 0.43)",
    strideLeftLabel: "rgb(156, 84, 152)",
    strideRight: "rgba(130, 207, 249, 0.43)",
    strideRightLabel: "rgb(71, 127, 157)",
    strideOther: "rgba(192, 192, 192, 0.25)",
    placeholderOrange: "rgba(255, 159, 10, 1.0)",
  },
};

// ----------------------------------------------------------------------

function EqsVideoView({
  results,
  uuid,
  linesMode,
  variablesOptions,
  variablesMode,
  handleChangeVariablesMode,
  locationOptions,
  locationMode,
  handleChangeLocationMode,
  style,
}) {
  const [playedSeconds, setPlayedSeconds] = useState(0.0);
  const [strideIndex, setStrideIndex] = useState(0);
  const [strides, setStrides] = useState([]);
  const [link, setLink] = useState(null);
  const [chartOptions, setChartOptions] = useState({});
  const { videosUri } = useSelector(state => state.examen)
  const dispatch = useDispatch()
  const teteDataSet = {
    name: "Tete",
    data: get(results, 'curves_depl["Tete"]', {}),
    type: "spline",
    id: "head",
    color: "#36c7b6",
    si: get(results, '["SI"]["Tete"]', {}),
    tg: get(results, '["T_G"]["Tete"]', {}),
    td: get(results, '["T_D"]["Tete"]', {}),
    vg: get(results, '["V_G"]["Tete"]', {}),
    vd: get(results, '["V_D"]["Tete"]', {}),
    curvesVideo: get(results, 'curves_video["Tete"]', []),
    curvesVideoFoulees: get(results, 'curves_video["Tete"].foulee', []),
    curvesVideoD: get(results, 'curves_video.AD.appui', []),
    curvesVideoG: get(results, 'curves_video.AG.appui', []),
  };

  const garrotDataSet = {
    name: "Garrot",
    data: get(results, 'curves_depl["Garrot"]', {}),
    type: "spline",
    id: "withers",
    color: "#36c7b6",
    si: get(results, '["SI"]["Garrot"]', {}),
    tg: get(results, '["T_G"]["Garrot"]', {}),
    td: get(results, '["T_D"]["Garrot"]', {}),
    vg: get(results, '["V_G"]["Garrot"]', {}),
    vd: get(results, '["V_D"]["Garrot"]', {}),
    curvesVideo: get(results, 'curves_video["Garrot"]', []),
    curvesVideoFoulees: get(results, 'curves_video["Tete"].foulee', []),
    curvesVideoD: get(results, 'curves_video.AD.appui', []),
    curvesVideoG: get(results, 'curves_video.AG.appui', []),
  };

  const croupeDataSet = {
    name: "Croupe",
    data: get(results, 'curves_depl["Croupe"]', {}),
    type: "spline",
    id: "croup",
    color: "#36c7b6",
    si: get(results, '["SI"]["Croupe"]', {}),
    tg: get(results, '["T_G"]["Croupe"]', {}),
    td: get(results, '["T_D"]["Croupe"]', {}),
    vg: get(results, '["V_G"]["Croupe"]', {}),
    vd: get(results, '["V_D"]["Croupe"]', {}),
    curvesVideo: get(results, 'curves_video["Croupe"]', []),
    curvesVideoFoulees: get(results, 'curves_video["Tete"].foulee', []),
    curvesVideoD: get(results, 'curves_video.AD.appui', []),
    curvesVideoG: get(results, 'curves_video.AG.appui', []),
  };

  const isUriExist = useMemo(() => {
    let found = videosUri.find(e => e?.uuid === uuid)
    setTimeout(()=>{
      if (found) {
        setLink(found?.url)
      }
    },30)
  
    return found
  }, [videosUri,uuid])

  useEffect(() => {
    setLink(null)
    setPlayedSeconds(0.0)
  }, [results])

  useEffect(() => {

    if (variablesMode === null) {
      handleChangeVariablesMode(JSON.stringify(variablesOptions[0]));
    }

    var dataSet = {};

    switch (locationMode) {
      case JSON.stringify(locationOptions[0]):
        dataSet = teteDataSet;
        break;
      case JSON.stringify(locationOptions[1]):
        dataSet = garrotDataSet;
        break;
      case JSON.stringify(locationOptions[2]):
        dataSet = croupeDataSet;
        break;
      default:
        dataSet = teteDataSet;
        break;
    }

    setStrides(dataSet.curvesVideoFoulees);

    const data = dataSet?.curvesVideo?.depl?.map(function (e, i) {
      return [i * 0.005, Number(e) * 100];
    });

    const dataSeries = [
      {
        data: data,
        name: dataSet.name,
        type: dataSet.type,
        color: "#36c7b6", //color
        opacity: 1,
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    ];

    const plotBands = [];

    dataSet?.curvesVideoFoulees?.map(function (foulee, i) {
      if (foulee !== dataSet.curvesVideoFoulees[i - 1] && foulee !== 0) {
        plotBands.push({
          from: i * 0.005,
          to: (i + 0.1) * 0.005,
          color: colors.chart.placeholderOrange,
          zIndex: 5,
          label: {
            text: foulee,
            align: "left",
            x: +10,
            style: {
              color: colors.chart.placeholderOrange,
              fontWeight: "bold",
            },
          },
        });
      }
    });

    var footOn = 0;
    var footOnStart = 0.0;
    var footOnStop = 0.0;
    for (var i = 0; i < dataSet.curvesVideoD.length; i++) {
      if (dataSet.curvesVideoD[i] == 1 && footOn === 0) {
        footOn = 1;
        footOnStart = i * 0.005;
      } else if (dataSet.curvesVideoD[i] == 0 && footOn === 1) {
        footOn = 0;
        footOnStop = i * 0.005;
        plotBands.push({
          from: footOnStart,
          to: footOnStop,
          color: colors.chart.strideRight,
          zIndex: 4,
        });
      }
    }

    footOn = 0;
    footOnStart = 0.0;
    footOnStop = 0.0;
    for (var i = 0; i < dataSet.curvesVideoG.length; i++) {
      if (dataSet.curvesVideoG[i] == 1 && footOn === 0) {
        footOn = 1;
        footOnStart = i * 0.005;
      } else if (dataSet.curvesVideoG[i] == 0 && footOn === 1) {
        footOn = 0;
        footOnStop = i * 0.005;
        plotBands.push({
          from: footOnStart,
          to: footOnStop,
          color: colors.chart.strideLeft,
          zIndex: 4,
        });
      }
    }

    setChartOptions({
      chart: {
        animation: false,
        marginLeft: 20, // Keep all charts left aligned
        marginRight: 0,
        spacingTop: 5,
        spacingBottom: 5,
        backgroundColor: null,
        style: {
          fontFamily: "Public Sans",
        },
      },
      stockTools: {
        gui: {
          enabled: false, // disable the built-in toolbar
        },
      },
      plotOptions: {
        series: {
          fillOpacity: 0.4,
          marker: {
            enabled: false,
          },
          states: {
            inactive: {
              enabled: false,
            },
            hover: {
              enabled: false,
            },
          },
        },
      },
      title: {
        text: dataSet.name,
        align: "left",
        margin: 0,
        style: {
          fontSize: "16px",
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        crosshair: false,
        tickLength: 0,
        tickInterval: 0.25,
        lineWidth: 1,
        labels: {
          format: "{value}",
          style: {
            fontSize: "8px",
          },
        },
        min: playedSeconds - 0.75,
        max: playedSeconds + 0.75,
        endOnTick: false,
        plotLines: [
          {
            color: "black",
            width: 2,
            value: playedSeconds,
            zIndex: 5,
          },
        ],
        plotBands: plotBands,
      },
      yAxis: [
        {
          title: "",
          crosshair: false,
          gridLineColor: "#e4e8eb",
          tickInterval: 1,
          min: dataSet.data.minY,
          max: dataSet.data.maxY,
          endOnTick: true,
          labels: {
            format: "{value}",
            style: {
              fontSize: "8px",
            },
          },
          opposite: false,
        },
      ],
      series: dataSeries,
    });
  }, [locationMode, results]);


  const handleProgress = (state) => {
    let playedSecond = state?.playedSeconds || 0.0
    setPlayedSeconds(playedSecond);

    setStrideIndex(strides[parseInt(playedSecond / 0.005)]);

    let updated = {
      xAxis: {
        crosshair: false,
        tickLength: 0,
        tickInterval: 0.25,
        lineWidth: 1,
        labels: {
          format: "{value}",
          style: {
            fontSize: "8px",
          },
        },
        min: playedSecond - 0.75,
        max: playedSecond + 0.75,
        endOnTick: false,
      },
    }

    let data = deepmerge(chartOptions, updated)
    //after deepmerge, because deepmerge seems to be adding the plotlines
    data.xAxis.plotLines = [
      {
        color: "black",
        width: 1,
        value: playedSecond,
        zIndex: 5,
      },
    ];

    setChartOptions(data);
  };

  return (
    <Box
      display="flex"
      position={'relative'}
      sx={{
        px: 1,
        py: 0,
        bgcolor: "background.paper",
        borderRadius: 1,
        ...style
      }}
    >
      {!isUriExist ? <EqsVideoDownload uuid={uuid} onComplete={url => {
        dispatch(setVideosUri([...videosUri, { uuid, url }]))
      }}></EqsVideoDownload> : <Stack spacing={0} sx={{ width: "100%" }}>
        <Box
          display="flex"
          sx={{
            p: 0,
            m: 0,
            height: `calc((100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 10px - 64px)/2)`,
            width: "100%",
          }}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: "100%", width: "100%" } }}
          />
        </Box>

        <Stack direction="row" spacing={6}>
          <Box
            display="flex"
            sx={{
              p: 0,
              m: 0,
              height: `calc((100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 10px - 64px)/2)`,
              width: "50%",
            }}
          >
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              url={link}
              controls={true}
              progressInterval={1}
              onReady={() => console.log("onReady")}
              onStart={() => console.log("onStart")}
              onError={(e) => console.log("onError", e)}
              onProgress={handleProgress}
            />
          </Box>

          <Box
            sx={{
              p: 0,
              m: 0,
              height: `calc(100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 8px - 64px)/2`,
            }}
          >
            <Stack spacing={0}>
              {locationOptions.map((option) => (
                <>
                  <Box
                    sx={{
                      px: 0,
                      py: 1,
                      m: 0,
                      height: `calc((100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 10px - 64px)/6)`,
                    }}
                  >
                    <Box
                      sx={{
                        p: 0,
                        m: 0,
                        height: `calc((100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 10px - 64px)/18)`,
                        opacity: 1,
                      }}
                    >
                      <Typography variant="body2">{option.title}:</Typography>
                    </Box>
                  </Box>
                </>
              ))}
            </Stack>
          </Box>

          <Box
            sx={{
              p: 0,
              m: 0,
              backgroundImage: "url(/horse_back.png)",
              height: `calc((100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 10px - 64px)/2)`,
              aspectRatio: "480 / 2055",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Stack spacing={0}>
              {[[teteDataSet], [garrotDataSet], [croupeDataSet]].map((dataSet) => (
                <>
                  <Box
                    sx={{
                      p: 0,
                      m: 0,
                      height: `calc((100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 10px - 64px)/6)`,
                    }}
                  >
                    {variablesMode && (
                      <EqsCursor
                        type={JSON.parse(variablesMode).value}
                        dataSet={dataSet}
                        linesMode={LinesMode.Single}
                        strideIndex={strideIndex}
                        color="#36c7b6"
                        isActive={true}
                        height={`calc((100vh - ${HEADER.MAIN_DESKTOP_HEIGHT}px - 10px - 64px)/18)`}
                      />
                    )}
                  </Box>
                </>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Stack>}

    </Box>
  );
}
export default memo(EqsVideoView)
