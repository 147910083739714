import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// pages
import Registered from "src/pages/auth/Registered";
// routes
import { PATH_DASHBOARD } from "../routes/paths";

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isRegistered } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  } else if (!isAuthenticated && isRegistered) {
    return <Registered />;
  }

  return <>{children}</>;
}
