import PropTypes from "prop-types";
import { Stack, InputAdornment, TextField, MenuItem } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

OrganisationMemberTableToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function OrganisationMemberTableToolbar({ filterName, onFilterName }) {
  const { translate } = useLocales();
  return (
    <Stack
      spacing={2}
      direction={{ xs: "column", sm: "row" }}
      sx={{ py: 2.5, px: 3 }}
    >
      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={translate("nav.searchdots")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={"eva:search-fill"}
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
