import { paramCase } from "change-case";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  Table,
  Button,
  TableBody,
  TableContainer,
  TablePagination,
  DialogTitle,
  Modal,
  Fade,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useLocales from "../../hooks/useLocales";
import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import {
  getTeam,
  selectTeam,
  deleteUserInTeams,
} from "../../redux/slices/team";
// components
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from "../../components/table";
import { DialogAnimate } from "../../components/animate";
// utils
import axios from "../../utils/axios";
// sections
import { TeamMemberTableRow } from "../team";
import { UserInvitationForm } from "../../sections/user";
import { isEmpty } from "lodash";

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function TeamMembers({ selectedTeam }) {
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const _userInTeams = selectedTeam.user_in_teams;

  const TABLE_HEAD = [
    { id: "name", label: translate('grid.name'), align: "left" },
    { id: "empty1", label: "" },
    { id: "empty2", label: "", align: "right", width: 60 },
  ];
  

  const _limitedAccessUsers = !isEmpty(selectedTeam?.limited_access_users) ? selectedTeam.limited_access_users.map((_user) => {
    const tmp = { user: _user, type: "limited_access_users" };
    return tmp;
  }) : [];

  const [tableData, setTableData] = useState([
    ..._userInTeams,
    ..._limitedAccessUsers,
  ]);
  const [filterName, setFilterName] = useState("");

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteRow = (userInTeam) => {
    try {
      dispatch(deleteUserInTeams(userInTeam.id));
      enqueueSnackbar(translate("snackbar.leaveSuccess"));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const handleInviteMember = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const denseHeight = 52;

  const isNotFound = !dataFiltered.length && !!filterName;

  return (
    <>
      <Card >
        <Box sx={{ p: 2, display: "flex", flexDirection: "row-reverse" }}>
          <Button
            variant="contained"
            onClick={handleInviteMember}
            sx={{ textTransform: "none" }}
            startIcon={<Iconify icon={"bxs:user-plus"} />}
          >
            {translate("nav.inviteMember")}
          </Button>
        </Box>

        <Scrollbar>
          <TableContainer
            sx={{ minHeight: "60vh", minWidth: 800, position: "relative" }}
          >
            <Table size={"small"}>
              <TableHeadCustom
                withCheckbox={false}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TeamMemberTableRow
                      key={row["@id"]}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row)}
                      onEditRow={() => handleEditRow(row.name)}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: "relative" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={translate("table.rowsperpage")}
          />
        </Box>
      </Card>

      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Fade in={isOpenModal}>
          <Box sx={style}>
            <DialogTitle>{translate('nav.inviteMember')}</DialogTitle>

            <UserInvitationForm onCancel={handleCloseModal} team={selectedTeam} />
          </Box>
        </Fade>

      </Modal>
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius:1,
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
