const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    stats: [],
    reports: [],
    exams: [],
    horses: [],
    blogs: []
}

const slice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setStats: (state, action) => {
            state.stats = action.payload
        },
        setReports: (state, action) => {
            state.reports = action.payload
        },
        setExams: (state, action) => {
            state.exams = action.payload
        },
        setHorses: (state, action) => {
            state.horses = action.payload
        },
        setBlogs: (state, action) => {
            state.blogs = action.payload
        },
    }
})

// Reducer
export default slice.reducer;

export const {
    setStats,
    setReports,
    setExams,
    setHorses,
    setBlogs,
} = slice.actions;