import { Box, Checkbox, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material'
import { get, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react'
import useLocales from 'src/hooks/useLocales';
import { ExamExtractor } from 'src/utils/ExamExtractor';
import { formatDate } from 'src/utils/dashboard';
import { dateComparatorDesc } from 'src/utils/examDashboard';
import { useLongPress } from 'use-long-press';
export default function ExamList({ exams, loading, onCheck, selectedExam, allowLongClickColor, showSelectAll, enableLongPress }) {
    const [checkedExams, setCheckedExams] = React.useState([]);

    const { translate } = useLocales()
    const COLORS = ['rgba(54,199,182,1)', 'rgba(4,139,181,1)', 'rgba(193,20,193,1)', 'rgba(79,31,122,1)', 'rgba(255,0,122,1)']

    const isChecked = (value) => {
        return checkedExams.map(e => e?.external_id).includes(value?.external_id)
    }

    useEffect(() => {
        if (!isEmpty(selectedExam)) {
            setCheckedExams(prev => [...prev, ...selectedExam])
        }
    }, [selectedExam])

    const handleToggle = (value, action) => {
        if (action === 'click') {
            isChecked(value) ? checkedExams.length === 1 ? setCheckedExams([]) : setCheckedExams([...checkedExams].filter(el => el?.external_id !== value.external_id)) : setCheckedExams([value])
        } else {
            isChecked(value) ? setCheckedExams([...checkedExams].filter(el => el?.external_id !== value.external_id)) : setCheckedExams(prev => [...prev, value])
        }
    }

    useEffect(() => {
        onCheck(checkedExams)
    }, [checkedExams])


    const callback = useCallback((event, { context }) => {
        handleToggle(context, 'longpress')
    }, []);

    const bind = useLongPress(callback, {
        onCancel: (e, { context }) => handleToggle(context, enableLongPress ? 'click' : 'longpress'),
        filterEvents: event => true, // All events can potentially trigger long press
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: false,
        detect: 'both',
    });

    const sortExams = useMemo(() => {
        if (!isEmpty(exams)) {
            return [...exams].sort((a, b) => dateComparatorDesc(
                formatDate(get(a, 'timestamp_start.unix', null))
                , formatDate(get(b, 'timestamp_start.unix', null)
                ))
            )
        } else {
            return exams
        }
    }, [exams])

    const renderColor = useCallback((item) => {
        if (checkedExams.length > 1) {
            const index = checkedExams.findIndex(el => el.external_id === item.external_id)
            if (index !== -1) {
                return COLORS[index]
            }
        } else {
            return 'transparent'
        }


    }, [checkedExams])

    if (loading) {
        return (
            <Box display={'flex'} flexDirection='column' px={2}>
                {[1, 2, 3, 4].map(e => <Skeleton key={e} width={'100%'} height={40} />)}
            </Box>
        )
    }

    return (
        <>
            {
                showSelectAll && <FormControlLabel control={<Checkbox color='secondary' onChange={e => {
                    e.target.checked ? setCheckedExams(exams) : setCheckedExams([])
                }}></Checkbox>} label={<Typography variant='caption'>{translate('selectAll')}</Typography>}></FormControlLabel>
            }

            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {sortExams.map((value) => (

                    <ListItem
                        key={value?.external_id}
                        disableGutters
                        disablePadding
                        divider

                    >
                        <ListItemButton dense  {...bind(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={isChecked(value)}
                                    color='secondary'
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText secondaryTypographyProps={{ fontSize: 10 }} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} primary={ExamExtractor.getLastExam(value)} secondary={value?.type ? translate('examType.' + value?.type) : ''} />
                        </ListItemButton>
                        {
                            allowLongClickColor && <ListItemText sx={{ justifyContent: 'center', maxWidth: 25, width: 25, minWidth: 25, display: 'flex' }}>
                                <Box maxWidth={18} width={18} minWidth={18} height={18} style={{ backgroundColor: renderColor(value), borderRadius: '4px' }}>
                                </Box>

                            </ListItemText>
                        }

                    </ListItem>
                ))}
            </List>
        </>
    )
}
ExamList.defaultProps = {
    exams: [],
    selectedExam: [],
    allowLongClickColor: true,
    showSelectAll: false,
    enableLongPress: true
}