import { Warning } from '@mui/icons-material';
import { Box, Checkbox, Chip, Divider, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Tooltip, Typography } from '@mui/material'
import { get, isEmpty, isEqual, isNull } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react'
import useLocales from 'src/hooks/useLocales';
import { formatDate } from 'src/utils/dashboard';
import { dateComparator } from 'src/utils/examDashboard';
import { useLongPress } from 'use-long-press';


export default function ConditionList({ conditions, loading, onCheck, getDuplicates, reset, selectedConditions, allowSelectColors, enableLongPress, showSelectAll, showAnesthesia, showExamDate }) {
    const [checkedConditions, setCheckedConditions] = React.useState([]);
    const { translate } = useLocales()


    useEffect(() => {
        if (!isNull(selectedConditions)) {
            let arr = String(selectedConditions).split(',')
            let _conditions = []
            arr.forEach(el => {
                let found = conditions.find(cnd => cnd.uuid === el)
                found && _conditions.push(found)
            })
            setCheckedConditions(prev => [...prev, ..._conditions])

        } else {
            setCheckedConditions([])

        }

    }, [selectedConditions, conditions])

    useEffect(() => {
        if (reset === 'duplicateCondition') {
            setCheckedConditions([])
        }
    }, [reset])

    useEffect(() => {
        onCheck(checkedConditions, 'condition')
    }, [checkedConditions])

    const sortConditions = useMemo(() => {
        if (!isEmpty(conditions)) {            
            return [...conditions].sort((a, b) => dateComparator(
                formatDate(get(a, 'timestamp_start.unix', null))
                , formatDate(get(b, 'timestamp_start.unix', null)
                ))
            )
        } else {
            return conditions
        }
    }, [conditions])

    const COLORS = ['rgba(54,199,182,1)', 'rgba(4,139,181,1)', 'rgba(193,20,193,1)', 'rgba(79,31,122,1)', 'rgba(255,0,122,1)']

    const handleClick = (value) => {
        if (checkedConditions.length <= 1) {
            let uuids = [...checkedConditions].map(e => e?.uuid)
            uuids.includes(value.uuid) ? setCheckedConditions([]) : setCheckedConditions([value])
        } else {
            setCheckedConditions(prev => [...prev].filter(el => el?.uuid !== value.uuid))
        }
    }

    const handleToggle = (value, action) => {
        if (action === 'click') {
            handleClick(value)
        } else {
            isChecked(value) ? setCheckedConditions([...checkedConditions].filter(el => el?.uuid !== value.uuid)) : setCheckedConditions(prev => [...prev, value])
        }
    }

    const isChecked = (value) => {
        return checkedConditions.map(e => e?.uuid).includes(value?.uuid)
    }
    const callback = useCallback((event, { context }) => {
        handleToggle(context, 'longpress')
    }, []);

    const bind = useLongPress(callback, {
        onCancel: (e, { context }) => handleToggle(context, enableLongPress ? 'click' : 'longpress'),
        filterEvents: event => true, // All events can potentially trigger long press
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: false,
        detect: 'both',
    });

    const formatText = (data) => {

        let gait = data?.gait ? translate(`gait.${data?.gait}`).concat('/') : ''
        let figure = data?.figure ? translate(data.figure === 'CUSTOM' ? data?.figure_custom : `figure.${data?.figure}`).concat('/') : ''
        let ground = translate(`ground.${data?.ground}`) || ''

        return gait.concat(figure, ground)
    }
    const renderColor = useCallback((item) => {
        const index = checkedConditions.findIndex(el => el.uuid === item.uuid)
        if (index !== -1) {
            return item.hasOwnProperty('color') ? item.color : COLORS[index]
        }

    }, [checkedConditions])
    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {
                showSelectAll && <FormControlLabel control={<Checkbox color='secondary' onChange={e => {
                    e.target.checked ? setCheckedConditions(conditions) : setCheckedConditions([])
                }} checked={checkedConditions.length === conditions.length}></Checkbox>} label={<Typography variant='caption'>{translate('selectAll')}</Typography>}></FormControlLabel>
            }
            <Divider></Divider>
            {sortConditions.map((value) => (
                <ListItem
                    key={value?.uuid}
                    disableGutters
                    disablePadding
                    divider
                >
                    <ListItemButton dense {...bind(value)}>
                        <ListItemIcon>
                            <Checkbox
                                key={value?.uuid}
                                checked={isChecked(value)}
                                color='secondary'
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText secondaryTypographyProps={{ fontSize: 10 }} primaryTypographyProps={{ fontWeight: 600, fontSize: 12 }} primary={formatText(value)} secondary={
                            <Box display={'flex'} flexDirection={'column'} gap={"2px"} alignItems={'self-start'}>
                                <Typography variant='caption' sx={{ display: 'inline' }} component="span" >{formatDate(value?.timestamp_start?.unix, 'HH:mm')}</Typography>
                                {(showAnesthesia && !isNull(value?.exam_type)) ? <Chip sx={{ fontSize: 10 }} variant='filled' size='small' label={translate('examType.' + value?.exam_type)}></Chip> : <></>}
                                {showExamDate &&
                                    <Tooltip title={translate('grid.createdAt')} placement='right'>
                                        <Chip sx={{ fontSize: 10 }} variant='filled' size='small' color='info' label={value.exam_date}></Chip>
                                    </Tooltip>
                                }
                            </Box>
                        } />

                    </ListItemButton>
                    <ListItemText sx={{ justifyContent: 'center', maxWidth: 25, width: 25, minWidth: 25, display: 'flex' }}>
                        {value?.hasError ? <Warning color='warning' style={{ paddingInline: 2 }} /> : allowSelectColors ? <Box maxWidth={18} width={18} minWidth={18} height={18} style={{ backgroundColor: renderColor(value), borderRadius: '4px' }}>
                        </Box> : <></>}

                    </ListItemText>
                </ListItem>
            ))}
        </List>
    )
}
ConditionList.defaultProps = {
    conditions: [],
    allowSelectColors: true,
    enableLongPress: true,
    showSelectAll: false,
    showAnesthesia: false,
    showExamDate: false,
}