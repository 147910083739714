import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { AddCircle, AddCircleOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import useLocales from 'src/hooks/useLocales';
import Iconify from 'src/components/Iconify';
import { get } from 'lodash';
import { useState } from 'react';
import { UserInvitationForm } from 'src/sections/user';
import { TeamForm } from 'src/sections/team';
import { NewOrganisationForm } from 'src/sections/organisation';
import { useEffect } from 'react';
import { getOrganisation } from 'src/redux/slices/user';

export default function MenuAddPopover() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { translate } = useLocales()
    const [openModal, setOpenModal] = useState(false)
    const [selectedComponent, setSelectedComponent] = useState(null)
    const [title, setTitle] = useState(null)
    const dispatch = useDispatch()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { user, userInOrganisation, isOpenModalOrganisation } = useSelector(
        (state) => state.user
    );
    useEffect(() => {
        dispatch(getOrganisation(!get(userInOrganisation, 'restricted', false) ? userInOrganisation?.organisation?.id : userInOrganisation?.id));
    }, [userInOrganisation]);


    const { isOpenModalNewTeam, selectedTeam } = useSelector(
        (state) => state.team
    );
    let userInOrg = get(user, 'user_in_organisations', []) || []

    const MENU_OPTIONS = [
        {
            label: "nav.member",
            linkTo: "/",
            icon: "fluent:people-add-28-regular",
            id: "newMember",
            memberOnly: true,
            showToRestrictedUser: !userInOrganisation?.restricted,
            component: <UserInvitationForm onCancel={() => {
                setSelectedComponent(null)
                setOpenModal(false)
            }} />,
            title: translate("nav.inviteMember")

        },
        {
            label: "nav.team",
            linkTo: "/",
            icon: "fluent:people-team-add-20-regular",
            id: "newTeam",
            memberOnly: true,
            showToRestrictedUser: !userInOrganisation?.restricted,
            component: <TeamForm
                team={selectedTeam || {}}
                onCancel={() => {
                    setSelectedComponent(null)
                    setOpenModal(false)
                }} />,
            title: translate("nav.newTeam")
        },
        {
            label: "nav.organisation",
            linkTo: "/",
            icon: "fluent:home-add-20-regular",
            id: "newOrganisation",
            memberOnly: false,
            showToRestrictedUser: true,
            component: <NewOrganisationForm
                isDialog={true}
                onCancel={() => {
                    setSelectedComponent(null)
                    setOpenModal(false)
                }} />,
            title: translate("modal.addOrg")


        },
    ];
    return (
        <div>
            <IconButton
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <AddCircle />
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {MENU_OPTIONS.map((option) => (
                    option.showToRestrictedUser &&
                    <MenuItem
                        disabled={
                            option.memberOnly === true &&
                            userInOrg.filter(
                                (_item) => _item?.accepted === true
                            ).length === 0
                        }
                        id={option.id}
                        key={option.label}
                        onClick={() => {
                            setTitle(option.title)
                            setSelectedComponent(option.component)
                            setOpenModal(true)
                        }}
                    >
                        <Iconify
                            icon={option.icon}
                            width={20}
                            height={20}
                            sx={{ mr: 1 }}
                        />
                        <Typography variant='subtitle2'> {translate(option.label)}</Typography>
                    </MenuItem>
                ))}
            </Menu>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style }}>
                    <Typography variant='h6'>{title}</Typography>
                    {selectedComponent}
                </Box>
            </Modal>
        </div>
    );
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1,
    maxHeight:700,
    overflowY:'scroll'
};