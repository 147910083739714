import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import '../styles/home.css'
import arioneoLogo from '../assets/icon_logo.png'
import arioneoLogoBlack from '../assets/icon_logo_black.png'
import puce from '../assets/home/puce.png'
import cheval from '../assets/home/cheval.jpg'
import mac from '../assets/home/mac.png'
import platforme from '../assets/home/platforme.png'
import gsap from 'gsap'
import Header from 'src/components/home/Header'
import useLocales from 'src/hooks/useLocales'
import Page from 'src/components/Page'


function Home() {
    const ref = useRef(null)
    const { translate } = useLocales()



    useEffect(() => {
        let divHeight = ref.current.clientHeight

        let timeline = gsap.timeline()

        //Animate logo
        timeline.fromTo('#logo', {
            y: -100,
            opacity: 0,
        }, {
            y: 0,
            opacity: 1,
            duration: 1,
            ease: 'expo.inOut'
        })

        timeline.fromTo('.init_text', {
            y: -20,
            opacity: 0,
        }, {
            opacity: 1,
            y: 0,
            stagger: 0.4,
            duration: 1,
            ease: 'power4.inOut'

        }, '-=0.6')

        timeline.to('#init_screen', {
            top: `calc(100% - 65px)`,

            ease: 'expo.inOut',
            duration: 1,
        }, '-=0.3')
        timeline.to('#wrapper', {
            height: 0,
            ease: 'expo.inOut',
            duration: 1.3
        })
        timeline.to('#init_screen', {
            opacity: 0,
            ease: 'expo.inOut'
        }, '-=1')


    }, [])

    return (
        <Page title={'Arioneo'}>
            <Box sx={{ boxSizing: 'border-box', minHeight: '100vh', width: '100%', overflow: 'hidden' }} >

                <div id='wrapper'>
                    <div id='init_screen' ref={ref}>
                        <img width={99} height={83} id='logo' src={arioneoLogoBlack} alt="" srcset="" />

                    </div>


                </div>
                <Header></Header>
                <Grid container >
                    <Grid item md={6} xs={12} width={'100%'}>
                        <img src={cheval} style={{ height: '60vh', width: '100%' }} alt="" srcset="" />
                    </Grid>
                    <Grid item md={6} xs={12} width={'100%'}>
                        <img src={puce} style={{ height: '60vh', width: '100%' }} alt="" srcset="" />
                    </Grid>
                </Grid>
                <Grid item md={12} height={'40vh'} bgcolor={'var(--home-color)'} xs={12} width={'100%'} position={'relative'}>
                    <Box px={{ md: 6, xs: 3 }} py={{ md: 1, xs: 3 }} maxWidth={{ md: '50%' }}>
                        <Typography fontSize={{ md: 70, xs: 30 }} color={'white'} display={'flex'} alignItems={'center'}>DASHBOARD VET</Typography>
                        <Typography variant='caption' fontSize={{ md: 18, xs: 14 }} color={'whitesmoke'}>
                            {translate('appDescription')}</Typography>
                    </Box>
                    <Box className='logo_home'>
                        <img width={100} height={100} src={arioneoLogo} alt="" srcset="" />
                    </Box>
                    <Box display={{ md: 'block', xs: 'none' }} sx={{ width: '600px', position: 'absolute', right: -60, bottom: -10 }}>
                        <div style={{ position: 'relative' }}>
                            <img src={mac} alt="" />
                            <img src={platforme} id='platforme_img' alt="" srcset="" />
                        </div>
                    </Box>


                </Grid>
            </Box>
        </Page>
    )
}

export default Home