import { useState, useEffect } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Boost from "highcharts/modules/boost";

// @mui
import {
  Typography,
  Stack,
  Box,
  Card,
  TextField,
  Slider,
  IconButton,
} from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Iconify from "../../components/Iconify";
import { get } from "lodash";
import LabelModal from "./LabelModal";

const scaleLbl = "s";
const chartColors = ["#5bc0de", "#9b141a", "#8fb62a", "rgb(188, 116, 33)"];

Boost(Highcharts);

export default function EcgChart({
  ecg,
  reverseEcg,
  box,
  handleChangeBox,
  sizeWindow,
  handleSizeWindow,
  handleTimelineValue,
}) {
  const { translate } = useLocales();

  const [ecgChart, setEcgChart] = useState(null);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [plotBands, setPlotBands] = useState([])
  const [open, setOpen] = useState(false)
  const [startPoint, setStartPoint] = useState(0)
  const [endPoint, setEndPoint] = useState(0)


  const afterEcgChartCreated = (chart) => {
    // Highcharts creates a separate chart instance during export
    if (!chart.options.chart.forExport) {
      setEcgChart(chart);
    }
  };

  const [ecgChartOptions, setEcgChartOptions] = useState({
    chart: {
      zoomType: "x",
      marginLeft: 40, // Keep all charts left aligned
      spacingTop: 20,
      spacingBottom: 20,
      backgroundColor: null,
      style: {
        fontFamily: "Public Sans",
      },
      events: {
        selection: function (event) {
          // log the min and max of the y axis
          /*        handleChangeBox({
                   min: event.xAxis[0].min,
                   max: event.xAxis[0].max,
                   center: (event.xAxis[0].max + event.xAxis[0].min) / 2,
                 }); */

          event.preventDefault()
          let { xAxis } = event
          let { min = 0, max = 0 } = get(xAxis, '[0]', {})
          /*           let labelText = prompt("Please enter your label name", "ex : label 1 ");
           */
          setSelectedPlot(null)
          setStartPoint(min)
          setEndPoint(max)
          setOpen(true)
        },
        load:()=>{
        }
        // click: function (event) {
        //   // console.log(this);
        //   // closestValue = Math.max.apply(null, dataIndex.xData.filter(function (v) { return v <= event.xAxis[0].value }));
        //   // $("#track-slider-colored").slider( "value", dataIndex.xData.indexOf(closestValue));
        // },
        // load: function () {
        //   var chart = this,
        //     series = this.series[0],
        //     xAxis = chart.xAxis[0],
        //     newStart = series.xData[40000],
        //     newEnd = series.xData[44000];
        //   // console.log(chart.xAxis[0]);
        //   xAxis.setExtremes(newStart, newEnd);
        // },
      },
    },
    navigation: {
      // bindingsClassName: "tools-container",
      // events: {
      //   deselectButton: function (event) {
      //     var kids = $("chart-ecg > .highcharts-annotation-shapes")
      //       .find("path")
      //       .first();
      //     var colorLabel =
      //       chartDiv.id !== "chart-hr"
      //         ? $("#other-label").val()
      //         : $("#echantillion").val();
      //     $("#chart-ecg")
      //       .find(".highcharts-annotation-label")
      //       .each(function (i, e) {
      //         $(e).addClass("ecg-annotation-text-" + i);
      //       });
      //     $("#chart-ecg")
      //       .find(".highcharts-annotation-shapes")
      //       .each(function (i, e) {
      //         var element = $(this)
      //           .find("path")
      //           .first()
      //           .addClass("label-" + i);
      //         element.css({ fillOpacity: ".3", fill: colorLabel });
      //         var labels = $("#chart-ecg").find(".highcharts-annotation-label");
      //         var text1 = labels.find("text");
      //         // console.log(labels,text1);
      //         var x = text1.attr("x");
      //         var y = text1.attr("y");
      //         var text = $("#chart-ecg")
      //           .find("#text-" + i)
      //           .detach();
      //         var link = $("#chart-ecg")
      //           .find("#link-" + i)
      //           .detach();
      //         text.attr({
      //           x: x,
      //           y: y,
      //         });
      //         link.attr({
      //           x: parseInt(x) * 12,
      //           y: y,
      //         });
      //         text.css({
      //           display: "block",
      //         });
      //         link.css({
      //           display: "block",
      //         });
      //         text1.attr({
      //           y: 200,
      //         });
      //         $(".ecg-annotation-text-" + i).append(text);
      //         $(".ecg-annotation-text-" + i).append(link);
      //         showInLabelsTable(i, text.text(), "other-label", "", x);
      //       });
      //     var counter = 0;
      //     $("#chart-ecg")
      //       .find(".highcharts-control-points")
      //       .find("path")
      //       .each(function (i, e) {
      //         if (i % 2 === 0) {
      //           $(e).attr("id", "gripLeft-" + i);
      //         } else {
      //           $(e).attr("id", "gripRight-" + (i - 1));
      //         }
      //         return true;
      //       });
      //     // kids.css({fillOpacity: '.3', fill: colorLabel});
      //     return false;
      //     kids.each(function (i, e) {
      //       //console.log(e.attr('fill'))
      //       e.css({ fill: colorLabel });
      //     });
      //   },
      // },
    },
    stockTools: {
      gui: {
        enabled: false, // disable the built-in toolbar
      },
    },
    boost: {
      enabled: true,
      // useGPUTranslations: false, // en live, on utilise le module boost de Highcharts pour ne pas faire exploser la mémoire utilisée par la page
      // useAlpha: false,
      // usePreallocated: false,
      seriesThreshold: 1,
    },
    plotOptions: {
      // area: {
      //   fillColor: {
      //     linearGradient: {
      //       x1: 0,
      //       y1: 0,
      //       x2: 0,
      //       y2: 1,
      //     },
      //     stops: [
      //       [0, Highcharts.Color(chartColors[0]).setOpacity(0.3).get("rgba")],
      //       [1, Highcharts.Color(chartColors[0]).setOpacity(0.1).get("rgba")],
      //     ],
      //   },
      //   marker: {
      //     radius: 2,
      //   },
      //   lineWidth: 1,
      //   states: {
      //     hover: {
      //       lineWidth: 1,
      //     },
      //   },
      //   //threshold: reversed ? yMax : null,
      // },
      line: {
        lineWidth: 2,
        point: {
          events: {
            // click: function (event) {
            //   if ($("#button-place-label").is(":checked")) {
            //     drawLabel(ecgChart, this.x, this.y, chartDiv);
            //   }
            // },
          },
        },
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
      series: {
        marker: {
          enabled: false,
        },
        showInNavigator: true,
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      events: {
        // click: function (event) {
        //   //console.log("hh");
        // },
      },
      crosshair: true,
      gridLineColor: "tranparent",
      labels: {
        format: "{value} ms",
      },
      plotBands
    },
    navigator: {
      enabled: true,
      height: 16,
    },
    yAxis: {
      gridLineColor: "transparent",
      min: 0,
      //max: yMax,
      // tickPixelInterval: 36,
      endOnTick: false,
      title: {
        text: "mV",
      },
      reversed: reverseEcg,
    },
    tooltip: {
      positioner: function () {
        return {
          x: this.chart.chartWidth - this.label.width, // right aligned
          y: 10, // align to title
        };
      },
      borderWidth: 0,
      backgroundColor: "none",
      headerFormat: "",
      shadow: false,
      style: {
        fontSize: "18px",
        fontColor: "white",
      },
      formatter: function () {
        /**if (dataIndex.datasets[0].id == "speed") {
                      //  return getSpeedTooltipForChart(this.y, dataIndex.datasets[0].unit);
                    }
                 else {
                       // return (this.y / 1).toFixed(dataset.valueDecimals) + ' ' + dataset.unit;
                    }**/
      },
    },
    series: [
      {
        // boostThreshold: 1,
        data: ecg,
        name: "Ecg",
        type: "line",
        color: chartColors[0],
        visible: true,
        //connectNulls: true,
        fillOpacity: 0.3,
      },
    ],
  });


  const zoomInChartEcgX = () => {
    const x = box.center;
    const length = box.max - box.min;
    handleChangeBox({
      min: x - length / 4,
      max: x + length / 4,
      center: x,
    });
  };

  const zoomOutChartEcgX = () => {
    const x = box.center;
    const length = box.max - box.min;
    handleChangeBox({
      min: x - length,
      max: x + length,
      center: x,
    });
  };

  const zoomResetChartEcgX = () => {
    const extremes = ecgChart.xAxis[0].getExtremes();
    handleChangeBox({
      min: extremes.dataMin,
      max: extremes.dataMax,
      center: (extremes.dataMax + extremes.dataMin) / 2,
    });
  };

  const zoomInChartEcgY = () => {
    const xMin = ecgChart.yAxis[0].min;
    const xMax = ecgChart.yAxis[0].max;
    const x = (xMax + xMin) / 2;
    const length = xMax - xMin;
    ecgChart.yAxis[0].setExtremes(x - length / 4, x + length / 4);
  };

  const zoomOutChartEcgY = () => {
    const xMin = ecgChart.yAxis[0].min;
    const xMax = ecgChart.yAxis[0].max;
    const x = (xMax + xMin) / 2;
    const length = xMax - xMin;
    ecgChart.yAxis[0].setExtremes(x - length, x + length);
  };

  const zoomFeetChartEcgY = () => {
    const extremes = ecgChart.yAxis[0].getExtremes();
    ecgChart.yAxis[0].setExtremes(extremes.dataMin, extremes.dataMax);
  };

  function updateExtremsFromSizeWindow(size) {
    const x = box.center;
    const length = size * 1000;
    handleChangeBox({
      min: x - length / 2,
      max: x + length / 2,
      center: x,
    });
  }
  const handlePlotEdit = (id) => {
    let found = plotBands.find(i => i?.id === id)
    if (!found) return

    setSelectedPlot(found)
    setStartPoint(found?.from)
    setEndPoint(found?.to)
    setOpen(true)
  }
  useEffect(() => {
    if (ecgChart && box) {
      ecgChart.xAxis[0].setExtremes(box.min, box.max);
      handleTimelineValue((box.center / ecgChart.xAxis[0].dataMax) * 100);
    }
  }, [box]);

  useEffect(() => {
    setEcgChartOptions(prev => ({
      ...prev,
      series: [
        {
          data: ecg,
          name: "Ecg",
          type: "line",
          color: chartColors[0],
          visible: true,
          fillOpacity: 0.3,
        },
      ],
    }));
  }, [ecg]);

  useEffect(() => {
    setEcgChartOptions(prev => ({
      ...prev,
      yAxis: {
        reversed: reverseEcg,
      },
    }));
  }, [reverseEcg]);

  useEffect(() => {
    setEcgChartOptions(prev => ({
      ...prev, xAxis: {
        ...prev.xAxis,
        plotBands
      }
    }))
  }, [plotBands])
  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="subtitle1" component="h1" paragraph>
        ECG
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <IconButton onClick={zoomInChartEcgY} size="large">
          <Iconify icon={"ant-design:zoom-in-outlined"} />
        </IconButton>

        <IconButton onClick={zoomOutChartEcgY} size="large">
          <Iconify icon={"ant-design:zoom-out-outlined"} />
        </IconButton>

        <IconButton onClick={zoomFeetChartEcgY} size="large">
          <Iconify icon={"ant-design:column-height-outlined"} />
        </IconButton>
      </Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={ecgChartOptions}
        callback={afterEcgChartCreated}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={zoomInChartEcgX} size="large">
          <Iconify icon={"ant-design:zoom-in-outlined"} />
        </IconButton>

        <IconButton onClick={zoomOutChartEcgX} size="large">
          <Iconify icon={"ant-design:zoom-out-outlined"} />
        </IconButton>

        <IconButton onClick={zoomResetChartEcgX} size="large">
          <Iconify icon={"ant-design:fullscreen-outlined"} />
        </IconButton>

        <TextField
          id="window"
          label="Fenêtre (en s)"
          value={sizeWindow}
          size="small"
          sx={{ width: 100 }}
          onChange={handleSizeWindow}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              updateExtremsFromSizeWindow(sizeWindow);
            }
          }}
        />
        <LabelModal open={open}
          plotBands={plotBands}
          handleClose={() => {
            setStartPoint(0)
            setEndPoint(0)
            setOpen(false)
          }} startPoint={startPoint} endPoint={endPoint}
          onSubmit={pb => {
            setPlotBands(prev => [...prev, pb])
            setOpen(false)
          }}
          onDelete={id => setPlotBands(prev => [...prev].filter(e => e.id !== id))}
          onEdit={handlePlotEdit}
          itemToEdit={selectedPlot}
        ></LabelModal>
      </Box>
    </Card>
  );
}
