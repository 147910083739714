import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, IconButton, MenuList, Stack, Typography } from '@mui/material';
import NoteAdd from '@mui/icons-material/NoteAdd';
import MoreVert from '@mui/icons-material/MoreVert';
import Export from '@mui/icons-material/FileUpload';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useDispatch } from 'react-redux';
import { AdminPanelSettings, Download, DownloadForOfflineOutlined, Edit, FileDownloadOff, Share, StackedLineChartOutlined, TopicRounded, Tune, Update } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import useLocales from 'src/hooks/useLocales';
import PropTypes from 'prop-types'
import Iconify from '../Iconify';


export default function DashboardMenu({ disableOptions, compareLoading, onCompare, onFilter, onExport, onShare, onManageUser, onManageExam, onFollowLongitude, onDownloadRepport, options, onDownloadData, downloadData, onEditCase }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { translate } = useLocales()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Stack direction='row' flexWrap='wrap' gap={1}>
            {options.showCompare && <LoadingButton disabled={disableOptions} loading={compareLoading} onClick={onCompare} color='darkGray' variant='outlined' startIcon={<CompareArrowsIcon fontSize='small' />}>Compare</LoadingButton>}
            {options.showFilter && <Button disabled={disableOptions} onClick={onFilter} color='darkGray' variant='outlined' startIcon={<Tune fontSize='small' />}>{translate('filters')}</Button>}
            {options.showShare && <Button disabled={disableOptions} onClick={onShare} color='darkGray' variant='outlined' startIcon={<Share fontSize='small' />}>Share</Button>}
            {options.showExport && <LoadingButton disabled={disableOptions} onClick={onExport} startIcon={<Export fontSize='small' />}>Export</LoadingButton>}
            {options.showManageUsers && <Button disabled={disableOptions} onClick={onManageUser} color='darkGray' variant='outlined' startIcon={<Iconify icon='hugeicons:share-01' />}>{translate('manageUsers')}</Button>}
            {options.showManageExams && <Button disabled={disableOptions} onClick={onManageExam} color='darkGray' variant='outlined' startIcon={<Iconify icon='proicons:file-add' />}>{translate('addExams')}</Button>}
            {options.showLongitude && <Button disabled={disableOptions} onClick={onFollowLongitude} color='darkGray' variant='outlined' startIcon={<Iconify icon='hugeicons:chart-line-data-01' />}>{translate('suiviLongitude')}</Button>}
            {options.showDownloadRepport && <Button disabled={disableOptions} onClick={onDownloadRepport} color='darkGray' variant='outlined' startIcon={<Iconify icon='solar:download-outline' />}>{translate('gridOptions.downloadRepport')}</Button>}
            {options.showDownloadData &&
                <>
                    <LoadingButton disabled={disableOptions} loading={downloadData} onClick={onDownloadData} color='darkGray' variant='outlined' startIcon={<DownloadForOfflineOutlined fontSize='small' />}>{translate('gridOptions.downloadDataExam')}</LoadingButton>

                </>
            }
            {options.showUpdateCase && <Button disabled={disableOptions} onClick={onEditCase} color='info' variant='contained' startIcon={<Iconify icon='mage:edit' />}>{translate('gridOptions.update')}</Button>}

            {options.showMultiOptions && <div>
                <IconButton
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuList dense>
                        <MenuItem onClick={handleClose}>
                            <NoteAdd sx={{ marginRight: 1 }} fontSize='small' />
                            <Typography variant='body2'>Add note</Typography>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </div>}

        </Stack>
    );
}


DashboardMenu.defaultProps = {
    options: {
        showFilter: true,
        showShare: true,
        showManageUsers: true,
        showCompare: true,
        showExport: true,
        showMultiOptions: true,
        showManageExams: false,
        showLongitude: false,
        showDownloadRepport: false,
        showDownloadData: false,
    },
    downloadData: false
}

DashboardMenu.propTypes = {
    options: PropTypes.shape({
        showFilter: PropTypes.bool,
        showShare: PropTypes.bool,
        showManageUsers: PropTypes.bool,
        showCompare: PropTypes.bool,
        showExport: PropTypes.bool,
        showMultiOptions: PropTypes.bool,
        showManageExams: PropTypes.bool,
    })
}
