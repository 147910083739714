import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
// hooks
import useLocales from "../../../hooks/useLocales";
//
import { NavRootExamen} from "./NavList";

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavSectionPlusVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
  handleClick: PropTypes.func,
  handleLongPress: PropTypes.func,
};

export default function NavSectionPlusVertical({
  navConfig,
  isCollapse = false,
  handleClick,
  handleLongPress,
  ...other
}) {
  const { translate } = useLocales();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {/* <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>

          {group.examens.map((examen) => (
            <NavRootExamen
              key={examen.external_id}
              examen={examen}
              subExamens={examen.conditions}
              isCollapse={isCollapse}
              handleClick={handleClick}
              handleLongPress={handleLongPress}
            />
          ))} */}
        </List>
      ))}
    </Box>
  );
}
