import { PATH_AUTH } from "src/routes/paths";

export const providers = [

    {
        title: 'auth.registerWithEquimetre',
        isDisabled: false,
        link: PATH_AUTH.registerEquimetre
    },
    {
        title: 'auth.registerWithEquisym',
        isDisabled: false,
        link: PATH_AUTH.registerEquisym
    },
    // {
    //     title: 'auth.createAccount',
    //     isDisabled: false,
    //     link: PATH_AUTH.register
    // },

]