import { Avatar, Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import React from 'react'
import arioneoLogo from '../assets/icon_logo.png'
import { green } from '@mui/material/colors'
import { Business, JoinFullOutlined, Login } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import useLocales from 'src/hooks/useLocales'
import { useMutation } from 'react-query'
import axios from 'axios'
import { HOST_API } from 'src/config'
import { setSession } from 'src/utils/jwt'
import { addUserToOrg } from 'src/redux/slices/user'
import { useSnackbar } from 'notistack'

function PageJoinDashboard() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { translate } = useLocales()
    let token = searchParams.get('token')
    let orgId = searchParams.get('orgId')
    let orgName = searchParams.get('orgName') ?? translate('nav.organisation')
    let email = searchParams.get('email') ?? translate('auth.email')
    let userId = searchParams.get('userId')
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    //Mutations
    const joinOrgMutation = useMutation((data) => axios.post(HOST_API + 'user_in_organisations', data, {
        headers: {
            Authorization: `Bearer ${token}`
        }

    }))
    //Fncs
    const handleJoinOrg = async () => {
        if (token) {

            try {
                let data = {
                    "user": userId,
                    "organisation": orgId,
                    "type": "MEMBER"
                }
                await joinOrgMutation.mutateAsync(data)
                setSession(token)
                navigate('/dashboard')

            } catch (err) {

                let code = err?.response?.data?.code

                if (code == 401) {
                    enqueueSnackbar(translate('snackbar.invitationExpired'), {
                        variant: 'warning'
                    })
                } else {
                    enqueueSnackbar(translate('general.error'), {
                        variant: 'error'
                    })
                }



            }
        } else {
            enqueueSnackbar(translate('general.error'), {
                variant: 'error'
            })
        }
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={10}>
            <img
                src={`${arioneoLogo}?w=100&fit=crop&auto=format`}
                srcSet={`${arioneoLogo}?w=161&fit=crop&auto=format&dpr=2 2x`}
                alt="arioneoLogo"
                loading="lazy"
                width={100}
            />
            <Card sx={{ px: 2 }}>
                <CardContent>
                    <Box display={'flex'} gap={2} alignItems={'center'} mb={2}>
                        <Avatar sx={{ bgcolor: green[600] }} variant="rounded">
                            <Business style={{ color: 'white' }} />
                        </Avatar>
                        <Typography variant='h4' color="text.secondary" gutterBottom>
                            {translate('joinOrg.header', { orgName })}
                        </Typography>
                    </Box>

                    <Typography variant="h5" component="div">
                    </Typography>
                    <Typography  sx={{ mb: 1.5 }} color="text.secondary">
                       <div dangerouslySetInnerHTML={{__html:translate('joinOrg.subheader', { email, orgName })}}></div> 
                    </Typography>

                </CardContent>
                <CardActions>
                    <LoadingButton loading={joinOrgMutation.isLoading} endIcon={<Login></Login>} onClick={handleJoinOrg} variant='contained' color='success' sx={{ color: 'white', mb: 3 }} fullWidth >{translate('team.join')}</LoadingButton>
                </CardActions>
            </Card>

        </Box>
    )
}

export default PageJoinDashboard