import { Avatar, Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Typography } from '@mui/material';
import React from 'react'
import Iconify from '../Iconify';
import useLocales from 'src/hooks/useLocales';
import { Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

function VetCasesModal({ open, handleClose, cases,horse }) {
    const { examCases } = useSelector((state) => state.examen)
    const { translate } = useLocales()
    const navigate = useNavigate()

    const viewFolder=(folder)=>{
        navigate(PATH_DASHBOARD.horses.folderView(horse.external_id,folder.id))
        handleClose()
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                {/* Header */}
                <Box display='flex' position={'sticky'} top={0} bgcolor={'white'} p={2} zIndex={5} justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <Iconify icon='gravity-ui:folders' sx={{ color: '#3E85A7' }} />
                        <Typography color='primary' variant='h6'>{translate("nav.folders")}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>
                <List dense>
                    {cases.map(c => (
                        <ListItem key={c.id}>
                            <ListItemButton onClick={()=>viewFolder(c)}>
                                <ListItemIcon>
                                    <Avatar>
                                        <Iconify icon='gravity-ui:folders'></Iconify>
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                    primary={c?.case_number ?? translate('general.unamed_folder')}
                                    secondary={dayjs(c?.creation_date).format('DD/MM/YYYY HH:mm')}
                                >

                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Modal>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxHeight: 500,
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1
};
export default VetCasesModal