import axios from "../utils/axios";
import { get, isEmpty } from "lodash"


export class Examen {

    constructor(examens = []) {
        this.examens = examens
    }


    static async getExams(organisation_id, page = 1, firstDate = null, lastDate = null, searchQuery, direction) {
        if (!organisation_id) return []

        return axios.get(`organisations/${organisation_id}/exams?page=${page}&first_date_shown=${firstDate}&last_date_shown=${lastDate}`
            .concat(direction ? `&direction=${direction}` : "")
            .concat(searchQuery ? `&q=${searchQuery}` : ''));


    }

    static getLastExamFromVetCase(examen) {
        return get(examen, 'last_exam', null)
    }

    static getHorseVetCases({ id, userInOrganisation, params = [], excludeVetCasesContainingExam = null }) {
        console.log({params});
        
        return axios.get(`/horses/${id}/vet_cases?`
            .concat(params.join('&'))
            .concat(userInOrganisation ? `user_in_organisation=${userInOrganisation}` : '')
            .concat(excludeVetCasesContainingExam ? `&exclude_vet_cases_containing_exam=${excludeVetCasesContainingExam}` : '')
        )

    }

    static updateCase(data, id) {
        return axios.put(`/vet_cases/${id}`, data)
    }

    static patchCase(data, id) {
        return axios.patch(`/vet_cases/${id}`, data, {
            headers: { "Content-Type": "application/merge-patch+json" },
        })

    }

    static getConditionData({ exam_id, uuid }) {
        return axios.get(`/exams/${exam_id}/results?condition=${uuid}`)
    }

    static getConditionVideo(uuid, config) {
        return axios.get(`/exams/videos/${uuid}`, config)
    }

    static getPinnedCondition(id) {
        return axios.get(`/exam_equisyms/${id}/equisym_pinned_views`)
    }

    static getRapport(id) {
        return axios.get(`/reports/${id}`)
    }

    static getPdf(report_id, pinned_id, template_id) {
        return axios.get(`reports/${report_id}/report_pages/preview?equisym-pinned-view=${pinned_id}&template=${template_id}&base64=true&format=jpg`)
    }

    static updateRepport(id, data) {
        return axios.patch(`/reports/${id}`, data, {
            headers: { "Content-Type": "application/merge-patch+json" },
        })
    }

    static createRepportPage(data) {
        return axios.post('/report_pages', data)
    }

    static deleteRepportPage(id) {
        return axios.delete(`/report_pages/${id}`)
    }
    static updateRepportPage(id, data) {
        return axios.patch(`/equisym_pinned_views/${id}`, data, {
            headers: { "Content-Type": "application/merge-patch+json" },
        })
    }

    static updateReportPage(id, data) {
        return axios.patch(`/report_pages/${id}`, data, {
            headers: { "Content-Type": "application/merge-patch+json" },
        })
    }

    static deletePinnedView(id) {
        return axios.delete(`/equisym_pinned_views/${id}`)
    }

    static addExamToVetCase(data) {
        return axios.post('/exam_in_vet_cases', data)
    }

    static downloadExams(vetId) {
        return axios.get(`/vet_cases/${vetId}/equisym_extract`)
    }

    static getRepports(id) {
        return axios.get(`/vet_cases/${id}/reports`)
    }

    static deleteReport(id) {
        return axios.delete(`/reports/${id}`)
    }

    static getLastExams(id, limit = 30, page = 1) {
        return axios.get(`/users/${id}/exams?itemsPerPage=${limit}&page=${page}`)
    }

    static getLastHorses(id, limit = 30, page = 1, order = 'DESC') {
        return axios.get(`/users/${id}/horses?itemsPerPage=${limit}&page=${page}&order[lastExam.creationDate]=${order}`)
    }

    static getLastCases(id, limit = 30, page = 1, order = 'DESC') {
        return axios.get(`/users/${id}/vet_cases?itemsPerPage=${limit}&page=${page}&order[lastExam.creationDate]=${order}`)
    }
}