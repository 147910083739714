import isString from "lodash/isString";
import PropTypes from "prop-types";
// @mui
import { Box, Typography, Link, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
//components
import Label from "./Label";
//
import Breadcrumbs from "./Breadcrumbs";

// ----------------------------------------------------------------------

TableHeader.propTypes = {
  tags: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export default function TableHeader({
  tags,
  action,
  heading,
  moreLink = "" || [],
  sx,
  ...other
}) {
  const theme = useTheme();

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
            <>
              {tags &&
                tags.map((tag) => (
                  <Label
                    variant={
                      theme.palette.mode === "light" ? "ghost" : "filled"
                    }
                    color={tag.color}
                  >
                    {tag.value}
                  </Label>
                ))}
            </>
          </Stack>
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
