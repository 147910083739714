import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, IconButton, Modal, TextField, Typography } from '@mui/material';
import React from 'react';
import useLocales from 'src/hooks/useLocales';

function EcgNameModal({ open, handleClose, name, handleNameChange, handleSubmit, id, handleDelete, handleCommentChange, comment, loading }) {

    const { translate } = useLocales()
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {/* Header */}
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box display='flex' gap={1} alignItems='center'>
                        </Box>
                        <IconButton onClick={handleClose}>
                            <Close fontSize='small' />
                        </IconButton>
                    </Box>

                    <TextField value={name} onChange={(e) => handleNameChange(e.target.value)} fullWidth placeholder='Entrer le nom' label='Nom'></TextField>
                    <Box mt={2} gap={1} display={'flex'} justifyContent={'end'} alignItems={'center'}>
                        {id && <LoadingButton loading={loading} onClick={() => handleDelete(id)} sx={{ marginTop: '0 !important' }} variant='outlined' color='error'>{translate('nav.delete')}</LoadingButton>}

                        <LoadingButton loading={loading} onClick={handleSubmit} sx={{ marginTop: '0 !important' }} variant='contained'>{id ? translate('team.edit') : translate('nav.save')}</LoadingButton>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default EcgNameModal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};