import { useState, useEffect } from "react";

// @mui
import {
  Typography,
  Stack,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Divider,
  Tooltip,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
import { get, isEmpty, isNull, orderBy, toNumber } from "lodash";
import { useMemo } from "react";
import { CopyAll, StraightenTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";


const MAX_RR_EXCEDENCE_VALUE = 500

export default function EcgToolbar({
  reverseEcg,
  handleReverseEcgChange,
  sensibilite,
  onSensChanged,
  picsOptions,
  onLabelModeChange,
  labelMode,
  ecgLabels,
  onCurrentLabelChanged,
  currentEcgLabel,
  isActionsAllowed,
  takeScreenshot,
  onEditLabelModeChange,
  editLabelMode,
  onPicsChange,
  picOption,
  picsDisabled,
  interPicsChanged,
  timeInterPics,
  isQuadrillageEnabled,
  onQuadrillageChange,
  quadrillageValue,
  onChangeQuadrillageValue,
  arythmiePr,
  onChangeArythmiePr,
  onDetectArythmie,
  arythmies,
  onArythmiesNext,
  onArythmiesPrev,
  isDetectionPicsLaunched,
  r_peak_uri,
  isRulerEnabled,
  onRulerChange,
  arythmiesIdx,
  clipboardExportLoading,
  pngExportLoading
}) {
  const { translate } = useLocales()
  const [currentLabel, setCurrentLabel] = useState(null)


  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  const reOrderedLabels = useMemo(() => {
    return orderBy(ecgLabels, ['index_start'], ['asc'])
  }, [ecgLabels])

  const isInputDisabled = picsDisabled || !isDetectionPicsLaunched || !r_peak_uri

  const disabledMessage = () => {
    if (!r_peak_uri) return translate('snackbar.picFileNotFound')
    if (!isDetectionPicsLaunched) return translate('snackbar.pleaseLanchDetection')
    if (picsDisabled) return translate('snackbar.enlargeGraph')
    return ""
  }
  const disabledMessageNoZoom = () => {
    if (!r_peak_uri) return translate('snackbar.picFileNotFound')
    if (!isDetectionPicsLaunched) return translate('snackbar.pleaseLanchDetection')
    return ""
  }

  const handleNextLabel = () => {
    if (!isEmpty(reOrderedLabels)) {
      if (!currentEcgLabel) {
        setCurrentLabel(reOrderedLabels[0])
      } else {
        let curr_index = reOrderedLabels.findIndex(label => label.id === currentEcgLabel.id)
        if (curr_index === ecgLabels.length - 1) {
          setCurrentLabel(reOrderedLabels[0])
        } else {
          setCurrentLabel(reOrderedLabels[curr_index + 1])
        }

      }
    }
  }
  const handlePreviousLabel = () => {
    if (!isEmpty(reOrderedLabels)) {
      if (!currentEcgLabel) {
        setCurrentLabel(reOrderedLabels[0])
      } else {
        let curr_index = reOrderedLabels.findIndex(label => label.id === currentEcgLabel.id)

        if (curr_index === 0) {
          setCurrentLabel(reOrderedLabels[reOrderedLabels.length - 1])
        } else {
          setCurrentLabel(reOrderedLabels[curr_index - 1])
        }

      }
    }
  }
  useEffect(() => {
    currentLabel && onCurrentLabelChanged(currentLabel)
  }, [currentLabel])

  return (
    <Stack spacing={2}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            gridAutoColumns: "1fr",
            gap: 2,
          }}
        >
          <Card sx={{ p: 2, gridRow: "1", gridColumn: "span 3" }}>
            <Typography variant="subtitle1" component="h1" paragraph>
              {translate('ecg.display.title')}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridAutoFlow: "column",
                gap: 3,
              }}
            >
              <Stack direction="column" spacing={1} justifyContent={'space-between'}>
                <FormControlLabel
                  value="inverseECG"
                  control={
                    <Checkbox
                      checked={reverseEcg}
                      onChange={handleReverseEcgChange}
                    />
                  }
                  label={translate('ecg.display.invert')}
                />
                <div>
                  <FormControlLabel
                    value="quadrillage"
                    control={<Checkbox checked={isQuadrillageEnabled} onChange={e => onQuadrillageChange(e.target.checked)} />}
                    label={translate('ecg.display.quadrillage')}
                  />
                </div>
                <TextField
                  id="paperSpeed"
                  label={translate('ecg.display.paper_speed')}
                  fullWidth
                  value={quadrillageValue}
                  type="number"
                  onChange={e => onChangeQuadrillageValue(e.target.value)}
                  size="medium"
                />
              </Stack>

              <Stack direction="column" justifyContent={'flex-start'} alignItems={'flex-start'}>
                <Box>
                  <RadioGroup >
                    {picsOptions.map((option, index) => (
                      <div key={option.name}>
                        <Tooltip arrow title={disabledMessage()} placement="bottom-start">
                          <FormControlLabel
                            key={option.name}
                            value={option.value}
                            control={<Checkbox checked={picOption[option.value]} onChange={e => {
                              onPicsChange({ [option.value]: e.target.checked })
                            }} />}
                            label={option.name}

                          />
                        </Tooltip>
                      </div>
                    ))}
                  </RadioGroup>
                </Box>
                <Box>
                  <Tooltip arrow title={disabledMessage()} placement="bottom-start">
                    <FormControlLabel
                      value="interPicsTime"
                      control={<Checkbox checked={timeInterPics} onChange={e => interPicsChanged(e.target.checked)} />}
                      label={translate('ecg.display.tip')}
                    />
                  </Tooltip>
                </Box>
                <Box>
                  <FormControl variant="outlined" sx={{ mt: 1 }}>
                    <InputLabel htmlFor="outlined-adornment-password">{translate('ecg.display.sens')}</InputLabel>
                    <OutlinedInput
                      endAdornment={<InputAdornment position="end">ms</InputAdornment>}
                      label={translate('ecg.display.sens')}
                      defaultValue={sensibilite} onChange={(e) => onSensChanged(toNumber(e.target.value))}></OutlinedInput>
                  </FormControl>
                </Box>
              </Stack>
            </Box>
          </Card>
          <Card sx={{ p: 2, gridRow: "1", gridColumn: "span 2" }}>
            <Typography variant="subtitle1" component="h1" paragraph>
              {translate('ecg.display.arythmie')}
            </Typography>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack direction="column" spacing={1} >

                <Tooltip title={disabledMessageNoZoom()} placement="bottom-start">
                  <TextField
                    disabled={!r_peak_uri || !isDetectionPicsLaunched}
                    id="rrPercentage"
                    label={translate('ecg.display.exceed')}
                    fullWidth
                    size="medium"
                    type="number"
                    value={arythmiePr}
                    onChange={event => {
                      let val = parseInt(event.target.value)
                      return onChangeArythmiePr(val >= MAX_RR_EXCEDENCE_VALUE ? MAX_RR_EXCEDENCE_VALUE : val)
                    }}
                  />
                </Tooltip>

                <Tooltip title={disabledMessageNoZoom()} placement="bottom-start">
                  <span>
                    <Button disableElevation
                      fullWidth
                      disabled={!r_peak_uri || !isDetectionPicsLaunched}
                      onClick={onDetectArythmie} size="medium" variant="contained">{translate('ecg.display.launchAr')}</Button>
                  </span>
                </Tooltip>
                <Stack direction={'row'} spacing={2}>
                  <Button disableElevation onClick={onArythmiesPrev} disabled={arythmies.length === 0 || arythmiesIdx === 0} size="medium" variant="outlined">{translate('ecg.display.prevAr')}</Button>
                  <Button disableElevation onClick={onArythmiesNext} disabled={arythmies.length === 0 || arythmiesIdx === arythmies.length - 1} size="medium" variant="outlined">{translate('ecg.display.nextAr')}</Button>
                </Stack>

              </Stack>
            </Stack>
          </Card>
          <Card sx={{ p: 2, gridRow: "1", gridColumn: "span 2" }}>
            <Typography variant="subtitle1" component="h1" paragraph>
              {translate('ecg.display.export')}
            </Typography>
            <Stack spacing={2} direction="column">
              <LoadingButton loading={pngExportLoading} size="small" onClick={() => takeScreenshot()} variant="contained">{translate('ecg.display.toPng')}</LoadingButton>
              {!isSafari() && <LoadingButton loading={clipboardExportLoading} size="small" onClick={() => takeScreenshot(true)} startIcon={<CopyAll></CopyAll>} variant="contained">{translate('button.copy')}</LoadingButton>}
            </Stack>
          </Card>
        </Box>
      </Box>

      <Stack spacing={2} direction="row">
        <Button fullWidth disableElevation size="medium" sx={{ color: 'white' }} color={labelMode ? 'success' : 'primary'} onClick={() => onLabelModeChange(!labelMode)} variant="contained">{translate('ecg.display.placeLabel')} {labelMode && `(${translate('active')})`} </Button>
        <Button fullWidth disableElevation size="medium" sx={{ color: 'white' }} color={editLabelMode ? 'success' : 'primary'} onClick={() => onEditLabelModeChange(!editLabelMode)} variant="contained">{translate('ecg.display.editLabel')} {editLabelMode && `(${translate('active')})`}</Button>
        <Button fullWidth disableElevation size="medium" disabled={!isActionsAllowed || ecgLabels.length === 0} onClick={handlePreviousLabel} variant="outlined">{translate('ecg.display.prevLabel')}</Button>
        <Button fullWidth disableElevation size="medium" disabled={!isActionsAllowed || ecgLabels.length === 0} onClick={handleNextLabel} variant="outlined">{translate('ecg.display.nextLabel')}</Button>
        <Divider orientation="vertical" flexItem />

        <Button fullWidth disableElevation color={isRulerEnabled ? 'success' : 'primary'} sx={{ color: 'white' }} onClick={() => onRulerChange(!isRulerEnabled)} variant="contained" startIcon={<Icon icon={'tabler:ruler-measure-2'}></Icon>}>{translate('ecg.display.ruler')} {isRulerEnabled && `(${translate('active')})`}</Button>
      </Stack>
    </Stack>
  );
}
