import PropTypes from "prop-types";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Box,
  Stack,
  Button,
  DialogActions,
  TextField,

  FormControl,
  FormHelperText,
  Divider,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// hooks
import useLocales from "../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { inviteUsersInOrganisation } from "../../redux/slices/user";
import { createUserInTeamsFromEmails, getTeamSuccess } from "../../redux/slices/team";
// components

import { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useMutation } from "react-query";
import UserNotInTeam from "./UserNotInTeam";
import axiosInstance from "src/utils/axios";
import MultiEmail from "src/components/global/MultiEmail";
import { useState } from "react";
import { isEmpty } from "lodash";
// ----------------------------------------------------------------------

UserInvitationForm.propTypes = {
  onCancel: PropTypes.func,
};

export default function UserInvitationForm({ onCancel, team }) {
  console.log({ team });
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { translate } = useLocales();
  const [emails, setEmails] = useState([])
  const [errs, setErros] = useState([])
  const [draftValueErr, setDraftValueErr] = useState(null)
  const [draftValue, setDraftValue] = useState('')

  const { userInOrganisation, organisation } = useSelector(
    (state) => state.user
  );

  const { userNotFound } = useSelector(
    (state) => state.team, shallowEqual
  );



  useEffect(() => {
    userNotFound && enqueueSnackbar('Aucun utilisateur trouvé', {
      variant: 'error'
    })
  }, [userNotFound])


  const defaultValues = {
    emails: [],
    teams: [],
  };

  const saveUserMutation = useMutation((emails) => dispatch(createUserInTeamsFromEmails(emails, team)))
  const saveUserToOrgMutation = useMutation((emails) => dispatch(
    inviteUsersInOrganisation(
      emails,
      userInOrganisation.organisation,
      []
    )
  ))



  const UserInvitationSchema = Yup.object().shape({
    emails: Yup.string()
      .required(translate('yup.email.required')),
  });


  const methods = useForm({
    resolver: yupResolver(UserInvitationSchema),
    defaultValues,
  });



  const onSubmit = async ({ mail }) => {

    let _emails = [...emails]

    if (isEmpty(_emails) && draftValue) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(draftValue)) {
        setDraftValueErr(translate('yup.email.adressInvalid', { adress: draftValue }))
        return
      } else {
        _emails.push(draftValue)
      }
    }


    let promise = team ? saveUserMutation.mutateAsync(_emails) : (!isEmpty(errs) || isEmpty(_emails)) ? null : saveUserToOrgMutation.mutateAsync(_emails)
    promise
      .then(async (result) => {
        enqueueSnackbar(translate('auth.resetSuccess'));
        onCancel()
        if (team) {
          const response = await axiosInstance.get(`/teams/${team.id}`);
          dispatch(getTeamSuccess(response.data));
        }

      }).catch(error => {
        enqueueSnackbar(translate(error), { variant: 'error' })

      })

  };

  const loading = saveUserMutation.isLoading || saveUserToOrgMutation.isLoading
  return (
    <>

      <Stack flexDirection='column' paddingLeft={2} marginTop={3} paddingRight={4}>

        <MultiEmail onChange={(emls, err) => {
          setEmails(emls)
          setErros(err)
        }}
          onInputChange={value => {
            setDraftValueErr(null)
            setDraftValue(value)
          }}
        ></MultiEmail>
        <Typography variant='caption' color='red'>{draftValueErr}</Typography>

        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />

          <Button variant="outlined" color="inherit" onClick={() => onCancel()}>
            {translate("nav.cancel")}
          </Button>

          <LoadingButton onClick={onSubmit} variant="contained" loading={loading}>
            {translate('team.invite')}
          </LoadingButton>
        </DialogActions>
        <Divider sx={{ my: 1 }}></Divider>
        <UserNotInTeam isLoading={saveUserMutation.isLoading} addUser={(email) => onSubmit({ mail: email })} team={team} organisation={organisation}></UserNotInTeam>
      </Stack>


    </>
  );
}
