import PropTypes from "prop-types";
import * as Yup from "yup";
import merge from "lodash/merge";
import { useSnackbar } from "notistack";
// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Box,
  Stack,
  Button,
  Tooltip,
  IconButton,
  DialogActions,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
// hooks
import useLocales from "../../hooks/useLocales";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { createTeam, updateTeam, deleteTeam } from "../../redux/slices/team";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFRadioGroup,
} from "../../components/hook-form";
import { getAuth, saveUser, updateStoreFromUser } from "src/redux/slices/user";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

const getInitialValues = (team) => {
  const _team = {
    name: "",
    description: "",
    confidentiality_policy: "PUBLIC_IN_ORGANISATION",
    membership_type_to_accept_requests: "MEMBER",
    organisation: "",
    auto_create_vet_cases_for_new_exams: false
  };

  if (team) {
    return merge({}, _team, team);
  }

  return _team;
};

// ----------------------------------------------------------------------

TeamForm.propTypes = {
  team: PropTypes.object,
  onCancel: PropTypes.func,
};

export default function TeamForm({ team, onCancel, refresh }) {
  const { enqueueSnackbar } = useSnackbar();
  const {translate} = useLocales()
  const dispatch = useDispatch();


  const { userInOrganisation } = useSelector((state) => state.user);
  const { isLoading } = useSelector((state) => state.team)


  const AUTHORIZATION_OPTION = [
    {
      name: translate("teamAuthorization.auth1.name"),
      subtitle: translate("teamAuthorization.auth1.subtitle"),
      value: "PUBLIC_IN_ORGANISATION",
    },
    {
      name: translate("teamAuthorization.auth2.name"),
      subtitle: translate("teamAuthorization.auth2.subtitle"),
      value: "ORGANISATION_MEMBER_CAN_REQUEST_ACCESS",
    },
    {
      name: translate("teamAuthorization.auth3.name"),
      subtitle: translate("teamAuthorization.auth3.subtitle"),
      value: "PRIVATE",
    },
  ];

  const ACCEPTATION_OPTION = [
    {
      name: translate("teamRequestAcceptance.req1.name"),
      subtitle: translate("teamRequestAcceptance.req1.subtitle"),
      value: "ADMIN",
    },
    {
      name: translate("teamRequestAcceptance.req2.name"),
      subtitle: translate("teamRequestAcceptance.req2.subtitle"),
      value: "MEMBER",
    },
  ];

  const isCreating = Object.keys(team).length === 0;

  const TeamSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(TeamSchema),
    defaultValues: getInitialValues(team),
  });

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
    watch
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newTeam = {
        name: data.name,
        description: data.description,
        auto_create_vet_cases_for_new_exams: data.auto_create_vet_cases_for_new_exams,
        confidentiality_policy: data.confidentiality_policy,
        membership_type_to_accept_requests:
          data.membership_type_to_accept_requests,
        organisation: userInOrganisation.organisation["@id"],
      };
      if (team.id) {
        await dispatch(updateTeam(team.id, newTeam));
      } else {
        await dispatch(createTeam(newTeam));
        enqueueSnackbar(translate('snackbar.createSuccess'));
      }
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (!team.id) return;
    try {
      onCancel();
      dispatch(deleteTeam(team.id));
      enqueueSnackbar("Delete success!");
    } catch (error) {
      console.error(error);
    }
  };

  const watchType = watch("confidentiality_policy");


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="name" label={translate('grid.name')} />

        <RHFTextField
          name="description"
          label={translate('grid.description')}
          multiline
          rows={4}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Controller
                name={'auto_create_vet_cases_for_new_exams'}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={translate('team.autoCreateVetCase')}
          />
        </FormGroup>

        <Box
          sx={{
            display: "grid",
            rowGap: 3,
            columnGap: 2,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            },
            mt: 3,
          }}
        >
          <LabelStyle>{translate("teamAuthorization.title")}</LabelStyle>
          <RHFRadioGroup
            name="confidentiality_policy"
            options={AUTHORIZATION_OPTION}
            sx={{
              "& .MuiFormControlLabel-root": { mr: 6 },
            }}
          />
        </Box>

        {watchType !== 'PUBLIC_IN_ORGANISATION' && <Box
          sx={{
            display: "grid",
            rowGap: 3,
            columnGap: 2,
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            },
            mt: 3,
          }}
        >
          <LabelStyle>{translate("teamRequestAcceptance.title")}</LabelStyle>
          <RHFRadioGroup
            name="membership_type_to_accept_requests"
            options={ACCEPTATION_OPTION}
            sx={{
              "& .MuiFormControlLabel-root": { mr: 6 },
            }}
          />
        </Box>}

      </Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          {translate("nav.cancel")}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          {translate("nav.save")}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
