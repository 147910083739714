import { useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle } from "react";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Boost from "highcharts/modules/boost";

// @mui
import {
  Typography,
  Card,
  Button,
  Box,
} from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
import { get, isEmpty, set, toString, uniqueId } from "lodash";
import EcgNameModal from "./EcgNameModal";
import { v4 as uuidv4 } from 'uuid';
import { ZoomOutMapOutlined } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import { Ecg } from "src/classes/Ecg";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import EcgChartNavigator from "./EcgChartNavigator";

const scaleLbl = "s";
const chartColors = ["#5bc0de", "#9b141a", "#8fb62a", "rgb(188, 116, 33)"];

Boost(Highcharts);

function EcgTrainingChart({
  dataSets,
  box,
  showSpeed,
  hrOrRrValue,
  handleChangeTrainingClick,
  sampleExtremes,
  isZoomTriggered,
  isLabelModeActive,
  handleTimelineValue,
  zoomValues,
  examInVetCase,
  ecgSamples,
  samplesFormatted,
  disablePlaceLabelMode,
  isEditLabelModeActive,
  trackPlot,
  sync,
  EcgExtremes
}, ref) {
  const { translate } = useLocales();
  const chartRef = useRef()
  const [open, setOpen] = useState(false)
  const [startPoint, setStartPoint] = useState(0)
  const [endPoint, setEndPoint] = useState(0)
  const [id, setId] = useState(null)
  const [labelName, setLabelName] = useState('')
  const [comment, setComment] = useState('')
  const { enqueueSnackbar } = useSnackbar();
  const query = useQueryClient()
  const [isZoomActive, setIsZoomActive] = useState(false)
  const navigatorRef = useRef(null)


  //Mutations
  const saveSampleMutation = useMutation((data) => Ecg.saveEcgSample(data))
  const updateSampleMutation = useMutation((data) => Ecg.patchEcgSample(id, data))
  const deleteSampleMutation = useMutation((data) => Ecg.deleteEcgSample(id))
  //End of mutations

  useImperativeHandle(ref, () => ({

    handleZoom(min, max) {
      let chart = chartRef?.current?.chart

      try {
        chart?.showLoading()
        chart?.xAxis[0]?.setExtremes(isNaN(min) ? 0 : min, isNaN(max) ? 0 : max)
      } catch (err) {

      } finally {
        chart?.hideLoading()

      }

    }

  }), []);

  useEffect(() => {
    let el = Highcharts.chart('sampleChart', {
      chart: {
        marginLeft: 40, // Keep all charts left aligned
        spacingTop: 20,
        spacingBottom: 20,
        backgroundColor: null,
        animation: false,
        zoomType: 'x',
        height: '400px',
        style: {
          fontFamily: "Public Sans",
        },
        zooming: {
          mouseWheel: {
            enabled: false
          }
        },

        resetZoomButton: {
          theme: {
            style: {
              display: 'none'

            }
          }
        },
        events: {
          click: function (e) {
            //to track ctrl on winodws or command on mac
            var mac = /(Mac)/i.test(navigator.platform);
            let pressed = mac ? e.metaKey : e.ctrlKey
            if (pressed) {
              const x = e.xAxis[0].value;
              handleChangeTrainingClick(x);
            }

          },
          load: function (event) {

          },
          selection: function (event) {
            handleSelectionEvent(event, this)
          },
        },

      },
      exporting: {
        enabled: false
      },
      stockTools: {
        gui: {
          enabled: false, // disable the built-in toolbar
        },
      },
      scrollbar: {
        enabled: true
      },
      boost: {
        allowForce: true,
        enabled: true,
        useGPUTranslations: true, // en live, on utilise le module boost de Highcharts pour ne pas faire exploser la mémoire utilisée par la page
        useAlpha: true,
        usePreallocated: true,
        seriesThreshold: 50,
      },
      plotOptions: {

        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, new Highcharts.Color(chartColors[0]).setOpacity(0.3).get("rgba")],
              [1, new Highcharts.Color(chartColors[0]).setOpacity(0.1).get("rgba")],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          //threshold: reversed ? yMax : null,
        },
        series: {
          animation: false,
          dataGrouping: {
            enabled: false
          },
          states: {
            hover: false,
            inactive: {
              opacity: 1,
              enabled: false
            },
          },
          events: {
            click: function (event) {
              handleChangeTrainingClick(event.point.x);
            },
          },
          //   point: {
          //     events: {
          //       click: function (event) {
          //         if ($("#button-place-sample").is(":checked")) {
          //           drawLabel(tmpChart, this.x, this.y, chartDiv);
          //         }
          //       },
          //     },
          //   },
        },
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        crosshair: false,
        tickLength: 0,
        lineWidth: 1,
        labels: {
          formatter: function () {
            return new Date(this.value * 1000).toISOString().slice(11, 19)
          }
        },
        plotBands: plotsFromSamples,
        events: {

          setExtremes: function (e) {
            let chart = chartRef?.current?.chart
            let xData = chart.series[0].xData
            if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
              sync && isZoomTriggered(EcgExtremes?.min, EcgExtremes?.max)
              setIsZoomActive(false)
            } else {
              setIsZoomActive(true)
            }
          }
        },
      },
      yAxis: [
        {
          // SPEED
          crosshair: false,
          gridLineColor: "transparent",
          min: 0,
          max: 75,
          endOnTick: true,
          labels: {
            format: "{value} km/h",
            style: {
              color: chartColors[0],
            },
          },
          opposite: false,
          visible: showSpeed,
        },
        {
          // HR
          crosshair: false,
          min: 0,
          max: 250,
          // tickPixelInterval: 36,
          endOnTick: true,
          labels: {
            format: "{value} bpm",
            style: {
              color: chartColors[1],
            },
          },
          title: {
            text: null,
          },
          opposite: true,
          visible: hrOrRrValue === "hr",
        },
        {
          // RR
          crosshair: false,
          min: 0,
          max: 2500,
          // tickPixelInterval: 36,
          endOnTick: true,
          labels: {
            format: "{value} ms",
            style: {
              color: chartColors[2],
            },
          },
          title: {
            text: null,
          },
          opposite: true,
          visible: hrOrRrValue === "rr",
        },
      ],
      tooltip: {
        shared: false,
        positioner: function () {
          return { x: 120, y: 0 };
        },
        borderWidth: 0,
        shadow: false,
        backgroundColor: "none",
        useHTML: true,
        snap: false,
        crosshairs: true,
        headerFormat: "",
        style: {
          fontSize: "18px",
          fontColor: "black",
        },
        animation: false,
        formatter: function () {
          return [this.point].reduce(function (s, point) {
            if (point.series.name === "hr") {
              return s + "<br/>" + point.series.name + ": " + Math.round(point.y) + " bpm";
            } else if (point.series.name === "speed") {
              return s + "<br/>" + point.series.name + ": " + point.y + " km/h";
            } else if (point.series.name === "rr") {
              return s + "<br/>" + point.series.name + ": " + point.y + " ms";
            }
          }, "<b>" + this.x + " " + scaleLbl + "</b>");
        },
      },
      series: [
        {
          data: get(dataSets, '[0].data', []),
          name: get(dataSets, '[0].name', ''),
          type: get(dataSets, '[0].type', ''),
          color: chartColors[0],
          zIndex: 100,
          visible: showSpeed,
          //connectNulls: true,
          fillOpacity: 0.3,
        },
        {
          type: 'line',
          data: get(dataSets, '[1].data', []),
          name: get(dataSets, '[1].name', ''),
          color: chartColors[1],
          zIndex: 1000,
          visible: hrOrRrValue === "hr",
          fillOpacity: 0.3,
          yAxis: 1,

        },
        {
          data: get(dataSets, '[2].data', []),
          name: get(dataSets, '[2].name', ''),
          type: get(dataSets, '[2].type', ''),
          color: chartColors[2],
          zIndex: 1000,
          visible: hrOrRrValue === "rr",
          //connectNulls: true,
          fillOpacity: 0.3,
          yAxis: 2,
        },
      ],
    })
    chartRef.current = {
      chart: el
    }
  }, [])

  useEffect(() => {
    let data = []
    if (isEmpty(ecgSamples)) {
      samplesFormatted([])
      return
    }

    data = ecgSamples.map(sample => ({
      ...sample,
      vm: getSpeed(sample.index_start, sample.index_end),
      bpm: getBpm(sample.index_start, sample.index_end),
    }))

    samplesFormatted(data)
  }, [ecgSamples])


  useEffect(() => {
    let chart = chartRef?.current?.chart
    let { min: minLabel, max: maxLabel } = sampleExtremes

    if (minLabel === 0 && maxLabel === 0) return 0
    //check if zoom values > labels extremes
    let seconds = navigatorRef.current.getSeconds()

    let minValue = null
    let maxValue = null

    let diff = maxLabel - minLabel

    if (seconds < diff) {
      minValue = minLabel - ((maxLabel - minLabel) * 10 / 100)
      maxValue = maxLabel + ((maxLabel - minLabel) * 10 / 100)
    } else {
      let rest = seconds - diff
      let addedExtremes = rest / 2

      minValue = minLabel - addedExtremes
      maxValue = maxLabel + addedExtremes
    }




    chart.xAxis[0].setExtremes(minValue, maxValue)

    if (!chart.resetZoomButton) {
      chart.showResetZoom();
    }

  }, [sampleExtremes])

  const handlePlotClick = (id, options) => {
    console.log({ id, options });

    setId(id)
    setLabelName(options?.label?.text)
    setStartPoint(options?.from)
    setEndPoint(options?.to)
    setOpen(true)
  }

  const plotsFromSamples = useMemo(() => {

    let arr = []

    if (!isEmpty(ecgSamples)) {
      arr = ecgSamples.map(sample => {
        return {
          color: 'rgba(102, 51, 153,0.3)', // Color value
          from: sample?.index_start, // Start of the plot band
          to: sample?.index_end,
          borderWidth: 2,
          borderColor: 'rgba(102, 51, 153,0.8)',
          //replace later by db id
          id: toString(sample.id),
          //Handle click events
          zIndex: 1000,
          events: {
            click: function (event) {
              return !isEditLabelModeActive ? event.preventDefault() :
                handlePlotClick(this.id, this.options)
            }
          },
          label: {
            text: sample?.name, // Content of the label. 
            align: 'center', // Positioning of the label. Default to center.
            style: {
              fontWeight: '700',
              color: 'black'
            }
          }
        }
      })
    }



    return trackPlot ? [...arr, trackPlot] : arr

  }, [ecgSamples, isEditLabelModeActive, trackPlot])

  useEffect(() => {
    let chart = chartRef?.current?.chart

    if (zoomValues?.min && zoomValues?.max) {
      chart?.showLoading()
      chart?.xAxis[0]?.setExtremes(zoomValues?.min, zoomValues?.max)
      chart?.hideLoading()
    }
  }, [zoomValues])

  const getSpeed = (point1, point2) => {
    let speedDataX = get(chartRef, 'current.chart.series[0].processedXData', [])
    let speedDataY = get(chartRef, 'current.chart.series[0].processedYData', [])
    let closePoint1 = getClosest(point1, speedDataX)
    let closePoint2 = getClosest(point2, speedDataX)

    let index1 = speedDataX.indexOf(closePoint1)
    let index2 = speedDataX.indexOf(closePoint2)

    let arrRange = speedDataY.slice(index1, index2)

    let total = arrRange.reduce((curr, prev) => {
      return curr + prev
    }, 0)
    return total / arrRange.length;
  }

  const getBpm = (point1, point2) => {
    let bpmDataX = get(chartRef, 'current.chart.series[1].processedXData', [])
    let bpmDataY = get(chartRef, 'current.chart.series[1].processedYData', [])
    let closePoint1 = getClosest(point1, bpmDataX)
    let closePoint2 = getClosest(point2, bpmDataX)

    let index1 = bpmDataX.indexOf(closePoint1)
    let index2 = bpmDataX.indexOf(closePoint2)

    let arrRange = bpmDataY.slice(index1, index2)

    let total = arrRange.reduce((curr, prev) => {
      return curr + prev
    }, 0)

    return total / arrRange.length;
  }

  const getClosest = (point = 0, arr = []) => {
    return arr.reduce(function (prev, curr) {
      return (Math.abs(curr - point) < Math.abs(prev - point) ? curr : prev);
    }, 0);
  }
  const handleSelectionEvent = (event, current) => {
    let chart = chartRef.current.chart
    let { xAxis } = event

    setId(null)
    setLabelName(null)
    let { min = 0, max = 0 } = get(xAxis, '[0]', {})
    chart.xAxis[0].setExtremes(null, null)

    if (isLabelModeActive) {
      event.preventDefault()




      setStartPoint(min < 0 ? 0 : min)
      setEndPoint(max)
      if (min !== 0 && max !== 0) {
        setOpen(true)
      } else {
        setOpen(false)

      }

    } else {
      sync && isZoomTriggered(min * 1000, max * 1000)
    }
  }



  useEffect(() => {
    let chart = chartRef.current.chart

    chart.update({
      chart: {
        events: {
          selection: function (event) {
            handleSelectionEvent(event, this)
          }
        }
      }
    })



  }, [isLabelModeActive, sync, EcgExtremes])


  useEffect(() => {
    let chart = chartRef.current?.chart
    if (!chart) return
    chart.update({
      xAxis: {
        plotBands: plotsFromSamples
      }
    })

  }, [plotsFromSamples, isEditLabelModeActive])

  useEffect(() => {
    let chart = chartRef.current.chart


    chart.update({
      xAxis: {
        crosshair: false,
        tickLength: 0,
        lineWidth: 1,
        labels: {
          formatter: function () {
            return new Date(this.value * 1000).toISOString().slice(11, 19)
          }
        },
        plotLines: [
        ],

        events: {
          setExtremes: function (e) {
            let chart = chartRef?.current?.chart
            let xData = chart.series[0].xData
            if (typeof e.min == 'undefined' && typeof e.max == 'undefined') {
              sync && isZoomTriggered(EcgExtremes?.min, EcgExtremes?.max)
              setIsZoomActive(false)
            } else {
              setIsZoomActive(true)
            }
          }
        },
      }
    })


  }, [dataSets, EcgExtremes, sync])


  useEffect(() => {
    let chart = chartRef.current.chart



    chart.update({
      series: [
        {
          data: get(dataSets, '[0].data', []),
          name: get(dataSets, '[0].name', ''),
          type: get(dataSets, '[0].type', ''),
          color: chartColors[0],
          visible: showSpeed,
          //connectNulls: true,
          fillOpacity: 0.3,
        },
        {
          data: get(dataSets, '[1].data', []),
          name: get(dataSets, '[1].name', ''),
          type: get(dataSets, '[1].type', ''),
          color: chartColors[1],
          visible: hrOrRrValue === "hr",
          type: 'line',
          dataGrouping: {
            enabled: false
          },
          //connectNulls: true,
          fillOpacity: 0.3,
          yAxis: 1,
        },
        {
          data: get(dataSets, '[2].data', []),
          name: get(dataSets, '[2].name', ''),
          type: get(dataSets, '[2].type', ''),
          color: chartColors[2],
          visible: hrOrRrValue === "rr",
          //connectNulls: true,
          fillOpacity: 0.3,
        },
      ],
      yAxis: [
        {
          // SPEED
          crosshair: false,
          gridLineColor: "transparent",
          min: 0,
          max: 75,
          endOnTick: true,
          labels: {
            format: "{value} km/h",
            style: {
              color: chartColors[0],
            },
          },
          opposite: false,
          visible: showSpeed,
        },
        {
          // HR
          crosshair: false,
          min: 0,
          max: 250,
          // tickPixelInterval: 36,
          endOnTick: true,
          labels: {
            format: "{value} bpm",
            style: {
              color: chartColors[1],
            },
          },
          title: {
            text: null,
          },
          opposite: true,
          visible: hrOrRrValue === "hr",
        },
        {
          // RR
          crosshair: false,
          min: 0,
          max: 2500,
          // tickPixelInterval: 36,
          endOnTick: true,
          labels: {
            format: "{value} ms",
            style: {
              color: chartColors[2],
            },
          },
          title: {
            text: null,
          },
          opposite: true,
          visible: hrOrRrValue === "rr",
        },
      ],
    });



  }, [hrOrRrValue, dataSets]);

  useEffect(() => {
    let chart = chartRef.current.chart



    chart.update({
      series: [
        {
          data: get(dataSets, '[0].data', []),
          name: get(dataSets, '[0].name', ''),
          type: get(dataSets, '[0].type', ''),
          color: chartColors[0],
          visible: showSpeed,
          //connectNulls: true,
          fillOpacity: 0.3,
        },
        {
          data: get(dataSets, '[1].data', []),
          name: get(dataSets, '[1].name', ''),
          type: get(dataSets, '[1].type', ''),
          color: chartColors[1],
          visible: hrOrRrValue === "hr",
          //connectNulls: true,
          type: 'line',
          dataGrouping: {
            enabled: false
          },
          fillOpacity: 0.3,
          yAxis: 1,
        },
        {
          data: get(dataSets, '[2].data', []),
          name: get(dataSets, '[2].name', ''),
          type: get(dataSets, '[2].type', ''),
          color: chartColors[2],
          visible: hrOrRrValue === "rr",
          //connectNulls: true,
          fillOpacity: 0.3,
        },
      ],
      yAxis: [
        {
          // SPEED
          crosshair: false,
          gridLineColor: "transparent",
          min: 0,
          max: 75,
          endOnTick: true,
          labels: {
            format: "{value} km/h",
            style: {
              color: chartColors[0],
            },
          },
          opposite: false,
          visible: showSpeed,
        },
        {
          // HR
          crosshair: false,
          min: 0,
          max: 250,
          // tickPixelInterval: 36,
          endOnTick: true,
          labels: {
            format: "{value} bpm",
            style: {
              color: chartColors[1],
            },
          },
          title: {
            text: null,
          },
          opposite: true,
          visible: hrOrRrValue === "hr",
        },
        {
          // RR
          crosshair: false,
          min: 0,
          max: 2500,
          // tickPixelInterval: 36,
          endOnTick: true,
          labels: {
            format: "{value} ms",
            style: {
              color: chartColors[2],
            },
          },
          title: {
            text: null,
          },
          opposite: true,
          visible: hrOrRrValue === "rr",
        },
      ],
    });



  }, [showSpeed]);

  const resetValues = () => {
    setLabelName('')
    setComment('')
    setId('')
    setStartPoint(0)
    setEndPoint(0)
  }

  const getIdFromUuid = (uuid) => {
    return ecgSamples.find(el => el.uuid === uuid).id
  }

  //CRUD SAMPLES
  const saveSample = () => {

    let data = {
      "index_start": String(startPoint),
      "index_end": String(endPoint),
      "name": String(labelName),
      // "comment": comment,
      "exam_in_vet_case": examInVetCase?.rawId
    }
    if (id) {

      updateSampleMutation.mutateAsync(data)
        .then(({ data }) => {
          enqueueSnackbar(translate('snackbar.updateSuccess'), {
            variant: 'success'
          })
          query.refetchQueries(['labelsQuery', examInVetCase?.id])
          resetValues()
          setOpen(false)
        }).catch(err => {
          enqueueSnackbar(translate('general.error'), {
            variant: 'error'
          })
        })
    } else {
      saveSampleMutation.mutateAsync(data)
        .then(({ data }) => {
          enqueueSnackbar(translate('snackbar.createSuccess'), {
            variant: 'success'
          })
          query.refetchQueries(['labelsQuery', examInVetCase?.id])
          resetValues()
          disablePlaceLabelMode()

          setOpen(false)
        }).catch(err => {
          enqueueSnackbar(translate('general.error'), {
            variant: 'error'
          })
        })
    }
  }

  const deleteSample = () => {
    deleteSampleMutation.mutateAsync()
      .then(result => {
        query.refetchQueries(['labelsQuery', examInVetCase?.id])
        setOpen(false)
        resetValues()
      }).catch(err => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      })
  }
  //End CRUD SAMPLES



  const onBack = (pourcentage) => {
    let chart = chartRef.current.chart
    try {

      let data = getBiggerSerie()
      let firstEl = data[0]
      let lasEl = data[data.length - 1]
      let diff = lasEl - firstEl



      chart.xAxis[0].setExtremes(firstEl - diff, firstEl)
      if (!firstEl && !lasEl) return

      sync && isZoomTriggered((firstEl - diff) * 1000, firstEl * 1000)
    } catch (err) {
      console.log({ err });

    } finally {


    }


  }

  const onNext = (pourcentage) => {
    let chart = chartRef.current.chart
    try {

      let data = getBiggerSerie()
      let firstEl = data[0]
      let lasEl = data[data.length - 1]
      let diff = lasEl - firstEl


      if (!firstEl && !lasEl) return

      chart.xAxis[0].setExtremes(lasEl, lasEl + diff)
      sync && isZoomTriggered(lasEl * 1000, (lasEl + diff) * 1000)
    } catch (err) {
      console.log({ err });
    } finally {

    }




  }

  const onZoomIn = () => {

    let chart = chartRef.current.chart

    let data = getBiggerSerie()


    let dataStartPoint = data[0]
    let dataEndPoint = data[data.length - 1]
    let prValue = percentage(dataEndPoint - dataStartPoint, 15)

    chart.xAxis[0].setExtremes(dataStartPoint + prValue, dataEndPoint - prValue)

    sync && isZoomTriggered((dataStartPoint + prValue) * 1000, (dataEndPoint - prValue) * 1000)



  }
  const onZoomOut = () => {

    let chart = chartRef.current.chart

    let data = getBiggerSerie()

    let dataStartPoint = data[0]
    let dataEndPoint = data[data.length - 1]
    let prValue = percentage(dataEndPoint - dataStartPoint, 15)


    chart.xAxis[0].setExtremes(dataStartPoint - prValue, dataEndPoint + prValue)
    sync && isZoomTriggered((dataStartPoint - prValue) * 1000, (dataEndPoint + prValue) * 1000)




  }


  const onCustomZoom = (value) => {
    let chart = chartRef?.current?.chart
    if (!chart) return

    let data = getBiggerSerie()

    let dataStartPoint = data[0]
    let endPoint = data[data.length - 1]

    let middle = (endPoint + dataStartPoint) / 2


    let seconds = value

    let addedExtremes = seconds / 2

    chart.xAxis[0].setExtremes(middle - addedExtremes, middle + addedExtremes)

    sync && isZoomTriggered((middle - addedExtremes) * 1000, (middle + addedExtremes) * 1000)

  }

  const getBiggerSerie = () => {
    let chart = chartRef?.current?.chart
    if (!chart) return

    let serie = chart.series.reduce((prev, curr) => {
      return prev?.processedXData?.length > curr?.processedXData?.length ? prev : curr
    })
    return serie?.processedXData
  }

  function percentage(num, per) {
    return (num / 100) * per;
  }
  const resetZoom = () => {
    let chart = chartRef?.current?.chart
    if (!chart) return
    chart.xAxis[0].setExtremes(null, null);
    sync && isZoomTriggered(null, null)
    setIsZoomActive(false)
  }
  return (
    <Card sx={{ p: 2 }} id="samplesGraph">
      <Box display={'flex'} justifyContent={'end'} alignItems={'center'}>

      </Box>
      <Typography variant="subtitle1" component="h1" paragraph>
        {translate('grid.graph')}
      </Typography>
      <div id="sampleChart" />
      <EcgChartNavigator ref={navigatorRef} resetZoom={resetZoom} enableTriggerZoom={false} zoom={isZoomActive} pourcentage={0} onBack={onBack} onNext={onNext} onZoomIn={onZoomIn} onZoomOut={onZoomOut} onCustomZoom={onCustomZoom}></EcgChartNavigator>

      <EcgNameModal loading={saveSampleMutation.isLoading || updateSampleMutation.isLoading || deleteSampleMutation.isLoading} comment={comment} handleCommentChange={(val) => setComment(val)} handleDelete={deleteSample} id={id} name={labelName} handleNameChange={val => setLabelName(val)} handleSubmit={saveSample} open={open} handleClose={() => setOpen(false)}></EcgNameModal>
    </Card>
  );
}


export default forwardRef(EcgTrainingChart)