import { Alert, Avatar, Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Stack, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react'
import Iconify from '../Iconify';
import useLocales from 'src/hooks/useLocales';
import { isEmpty, uniqBy } from 'lodash';
import dayjs from 'dayjs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ExamExtractor } from 'src/utils/ExamExtractor';
import { useNavigate } from 'react-router';

function SelectCaseForReport({ open, handleClose, exam }) {
    const { translate } = useLocales()
    const [selectedCase, setSelectedCase] = useState(null)
    const navigate = useNavigate()
    const cases = useMemo(() => {
        if (isEmpty(exam?.exam_in_vet_cases)) return []

        return uniqBy(exam.exam_in_vet_cases.map(e => e?.vet_case), '@id')
    }, [exam])

    const createRepport = () => {
        handleClose()
        navigate(PATH_DASHBOARD.horses.createNewReport(ExamExtractor.getHorseId(exam), exam?.external_id, selectedCase?.['@id']))
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            keepMounted={false}
        >
            <Box sx={style}>
                <Typography alignItems='center' display='flex' gap={1} id="modal-modal-title" variant="h6" fontWeight={600}>
                    <Iconify width={21} height={21} icon='hugeicons:folder-add'></Iconify>
                    {translate('nav:folders')}
                </Typography>

                <Stack spacing={2} mt={2}>
                    <Alert variant='standard' severity='info'>
                        {translate('createRepportHint')}
                    </Alert>
                    <List dense disablePadding sx={{ maxHeight: 220, overflowY: 'auto' }}>
                        {cases.map(c => (
                            <ListItem key={c.id} disableGutters>
                                <ListItemButton selected={selectedCase?.id === c.id} onClick={() => setSelectedCase(c)}>
                                    <ListItemIcon>
                                        <Avatar>
                                            <Iconify icon='gravity-ui:folders'></Iconify>
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={c?.case_number ?? translate('general.unamed_folder')}
                                        secondary={dayjs(c?.creation_date).format('DD/MM/YYYY HH:mm')}
                                    >

                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Box display={'flex'} justifyContent={'end'} gap={1} mt={2}>
                        <Button onClick={() => {
                            setSelectedCase(null)
                            handleClose()
                        }} color='error'>{translate('nav.cancel')}</Button>
                        <Button onClick={createRepport} variant='contained' disabled={!selectedCase}>{translate('pinnedConditions.create')}</Button>
                    </Box>
                </Stack>

            </Box>
        </Modal>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};
export default SelectCaseForReport