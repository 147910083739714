// components
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;

const ICONS = {
  user: getIcon("carbon:user-avatar"),
  dashboard: getIcon("mdi:monitor-dashboard"),
  horses: getIcon("la:horse-head"),
  tools: getIcon("clarity:tools-line"),
  exams: getIcon("healthicons:i-exam-multiple-choice-outline"),
  folders: getIcon("gravity-ui:folders"),
};

const navConfig = [
  {
    subheader: "title",
    items: [
      { title: "nav.dashboard", path: "/dashboard", icon: ICONS.dashboard },
      { title: "nav.horses", path: "/horses", icon: ICONS.horses },
      { title: "nav.folders", path: "/folders", icon: ICONS.folders },
      { title: "general.exams", path: "/exams", icon: ICONS.exams },
    ],
  },
];

export default navConfig;
