// @mui
import { Container, Tab, Box, Tabs } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
import useTabs from "../../hooks/useTabs";
// components
import Page from "../../components/Page";
// sections
import {
  OrganisationGeneral,
  OrganisationMembers,
  OrganisationTeams,
  OrganisationAdvanced,
} from ".";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function OrganisationTabs() {
  const queryParams = new URLSearchParams(window.location.search);
  const tab = queryParams.get("tab");
  const { organisation,userInOrganisation } = useSelector(state => state.user)
  const { currentTab, onChangeTab } = useTabs(tab || "general");
  const { translate } = useLocales();

  const CLINIC_TABS = [
    {
      name: "settings.tabGeneral",
      value: "general",
      component: <OrganisationGeneral />,
      isVisible: true
    },
    {
      name: "settings.tabMembers",
      value: "members",
      component: <OrganisationMembers />,
      isVisible: !userInOrganisation?.restricted
    },
    {
      name: "settings.tabTeams",
      value: "teams",
      component: <OrganisationTeams />,
      isVisible: !userInOrganisation?.restricted
    },
    {
      name: "settings.tabAdvanced",
      value: "advanced",
      component: <OrganisationAdvanced />,
      isVisible: !organisation?.equisym_clinic_id
    },
  ];

  return (
    <Page title={translate("settings.organisationManagement")}>
      <Container maxWidth={false}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {CLINIC_TABS.map((tab) => (
           tab.isVisible && (
             <Tab
            disableRipple
            key={tab.value}
            label={translate(tab.name)}
            icon={tab.icon}
            value={tab.value}
            sx={{ typography: "body2" }}
          />
           )
           
         
            
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {CLINIC_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
