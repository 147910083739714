import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Iconify from '../Iconify'
import useLocales from 'src/hooks/useLocales'
import gsap from 'gsap'
import { ExpansionPanelSummary } from '@material-ui/core'
import { Edit } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

function WelcomeCard() {
    const { translate } = useLocales()
    const navigate = useNavigate()

    const { user } = useSelector(state => state.user)


    return (
        <Card sx={{ width: '100%', minHeight: '100%', backgroundImage: 'url(https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display: 'flex', width: '100%' }} >
            <Box position={'absolute'} top={0} left={0} bgcolor={'black'} sx={{ opacity: 0.6, width: '100%', height: '100%' }}></Box>
            <CardContent sx={{ width: '100%', minHeight: '100%', zIndex: 3 }}>
                <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
                    <Box>
                        <Typography className='text' fontWeight={600} fontSize={'1.5rem'} color={'white'} >{translate('general.welcomeBack')} ,</Typography>
                        <Typography className='text' fontWeight={700} fontSize={'1.5rem'} color={'white'} >{user?.firstname}</Typography>
                    </Box>
                    <Box display={'flex'} gap={2} mt={3} zIndex={2}>
                        <Button size='small' onClick={() => navigate('/horses')} variant='outlined' sx={{ color: 'white', borderColor: 'white' }}>
                            <Iconify icon={'la:horse-head'} width={20} height={20} sx={{ mr: 1 }}></Iconify>
                            {translate('nav.horses')}</Button>
                        <Button size='small' onClick={() => navigate('/folders')} variant='outlined' sx={{ color: 'white', borderColor: 'white' }}>
                            <Iconify icon={'gravity-ui:folders'} width={20} height={20} sx={{ mr: 1 }}></Iconify>
                            {translate('nav.folders')}</Button>
                        <Button size='small' onClick={() => navigate('/exams')} variant='outlined' sx={{ color: 'white', borderColor: 'white' }}>
                            <Iconify icon={'healthicons:i-exam-multiple-choice-outline'} width={20} height={20} sx={{ mr: 1 }}></Iconify>
                            {translate('general.exams')}</Button>

                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

export default WelcomeCard