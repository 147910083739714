import { Box, Chip, CircularProgress, Container, Divider, Skeleton, Stack, Typography } from '@mui/material'
import { DataGrid, frFR } from '@mui/x-data-grid'
import { get, isNull } from 'lodash'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Examen } from 'src/classes/Examen'
import DashboardMenu from 'src/components/dashboard/DashboardMenu'
import FilterModal from 'src/components/dashboard/FilterModal'
import Page from 'src/components/Page'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { columns, dashboardWidget, injectI18N } from 'src/utils/dashboard'
import { uncategorizedExamsColumns, uncategorizedExamsFilters } from 'src/utils/examDashboard'
import useLocales from 'src/hooks/useLocales';
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { getUserVetCases } from 'src/redux/slices/user'
import { gridKeys } from 'src/enums/dashboard.enums'

const KEY = 'USER_VET_CASES'

function PageExternalCases() {
  const [parametre, setParametre] = useState(dashboardWidget.find(el => el.key === KEY))
  const { userInOrganisation, user } = useSelector((state) => state.user);
  const [filterModal, setFilterModal] = useState(false)
  const [casesData, setCasesData] = useState([])
  const { translate, dataGridLocales } = useLocales()
  let org_id = get(userInOrganisation, 'organisation.id', null)
  const navigate = useNavigate()
  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar();



  const { data, isLoading } = useQuery(
    ['user_in_vet_cases', user?.id],
    () => getUserVetCases(user?.id),
    {
      enabled: !isNull(user?.id),
      staleTime: 10 * 60 * 1000,
      onSuccess: (data) => {
        setCasesData(get(data, 'data.hydra:member', [])).map(e => e?.vet_case)
      }
    }
  )

  const _cases = get(data, 'data.hydra:member', []).map(e => e?.vet_case)

  //Get Count with exams
  const getCount = (key) => {
    switch (key) {
      case KEY:
        return _cases.length
      default:
        return 0
    }
  }


  //HandleCellClick
  const handleCellClick = (param) => {
    let lastExam = Examen.getLastExamFromVetCase(param.row)
    let vetCasaeID = get(param.row, 'id')
    if (param.field !== 'actions') {
      if (['createdAt', 'toolName'].includes(param.field)) {

        if (!lastExam) {
          return enqueueSnackbar(translate('snackbar.emptyVetCase'), {
            variant: 'error'
          })
        }
        navigate(PATH_DASHBOARD.horses.examenView(
          param.row.horse.external_id,
          lastExam?.external_id,
          vetCasaeID
        ))
      }
      else if (['reason', 'folderNumber'].includes(param.field)) {
        navigate(PATH_DASHBOARD.horses.folderView(
          param.row.horse.external_id,
          vetCasaeID
        ))
      }
      else {
        navigate(PATH_DASHBOARD.horses.view(
          param.row.horse.external_id,
        ))
      }

    }
  }


  return (
    <Page title={translate('cards.externalLink')}>
      <Container maxWidth={false}>

        {/* Dashboard Header */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent='space-between'
        >
          <Typography display='flex' alignItems={'center'} variant='h3'>{translate(parametre?.title)}
            {isLoading ? <Skeleton variant="text" height={50} width={70} sx={{ fontSize: '1rem', marginLeft: 2, borderRadius: 2 }} /> : <Chip sx={{ marginLeft: 2, color: 'white' }} label={getCount(parametre.key)} color={parametre.color} />}
          </Typography>
          <DashboardMenu compareLoading={false} onFilter={() => setFilterModal(true)} options={{
            showCompare: false,
            showExport: false,
            showFilter: true,
            showShare: false,
            showManageUsers: false,
            showMultiOptions: false,
          }}></DashboardMenu>
        </Stack>

        {/* Modals Section */}
        <FilterModal examens={_cases} isLoading={isLoading} open={filterModal} handleClose={() => setFilterModal(false)} onSubmit={data => setCasesData(data)} />


        <Divider sx={{ marginBlock: 5 }} />

        {/* Start Data Table */}
        {isLoading ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress />
          </Box>

          :
          <DataGrid
            rows={casesData}
            columns={injectI18N([
              { field: gridKeys.org, headerName: gridKeys.org, valueGetter: (params) => get(params, 'row.team.organisation.name', ''), flex: 1 },
              ...columns], 'headerName', translate)}
            getRowId={(row) => row?.id}

            autoHeight
            onCellClick={(params) => handleCellClick(params)}
            loading={isLoading}
            localeText={dataGridLocales()}
            rowsPerPageOptions={[5, 25, 50]}
            sortModel={[]}
          />}

        {/* End of table */}

      </Container>
    </Page>
  )
}

export default PageExternalCases