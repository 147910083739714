import { Box } from '@mui/material'
import { get, inRange, isArray, isEmpty, max, toNumber } from 'lodash'
import React, { useEffect, useState } from 'react'
import useLocales from 'src/hooks/useLocales'
import { LinesMode } from 'src/sections/eqs/EqsWindow'
// const threshold = require('./thresholds.json')
import threshold from './thresholds.json'
function DataTable({ conditions, linesMode, option, seuils, canBreakPage }) {
  const [cnd, setCnd] = useState([])
  //hooks
  const { translate } = useLocales()


  useEffect(() => {
    if (!isEmpty(conditions)) {
      let temp = []
      conditions.forEach(condition => {
        temp.push(renderSeuils(condition))
      })
      setCnd(temp)
    }
  }, [conditions])


  const renderSeuils = (condition) => {
    let thresholdJson = threshold

    //Get Key
    let key = condition?.key
    let data = condition?.data ?? []
    //Filtrer par type de seuil
    if (key) {
      if (key.includes('tete') || key.includes('garrot')) {
        thresholdJson = thresholdJson.filter(e => e?.Type_seuil === 'ANT')
      } else {
        thresholdJson = thresholdJson.filter(e => e?.Type_seuil === 'POST')
      }
    }
    //Filtrer par option
    thresholdJson = thresholdJson.filter(e => e?.Indice.includes(getIndice(key).concat('.' + option)))
    console.log({thresholdJson,indice:getIndice(key).concat('.' + option)});
    
    //Check conditions
    if (isEmpty(data)) return

    if (data.every(item => item.figure?.includes('STRAIGHT_LINE') || item.figure?.includes('FLEX'))) {
      return Object.assign(condition, { seuils: thresholdJson.find(e => e?.Condition === 'LDD') })
    } else {
      let circle = get(data, '[0].figure', '')

      let circle_key = circle && circle === 'RIGHT_CIRCLE' ? 'MD' : 'MG'

      thresholdJson = thresholdJson.filter(e => e?.Condition.includes(circle_key))



      let tempData = Object.groupBy(data, item => item?.ground)

      let result = []

      for (const [key, value] of Object.entries(tempData)) {
        if (key === 'SOFT') {
          result.push({ ...condition, data: value, seuils: thresholdJson.find(e => e.Condition === circle_key.concat('S')), keyHeader: key })
        } else {
          result.push({ ...condition, data: value, seuils: thresholdJson.find(e => e.Condition === circle_key.concat('D')), keyHeader: key })
        }
      }

      return result




    }

  }


  const getIndice = (key) => {
    switch (key) {
      case "examEnums.croupe":
        return "croupSI"
      case "examEnums.tete":
        return "headSI"
      case "examEnums.garrot":
        return "withersSI"
      default:
        break;
    }
  }

  const formatText = (data) => {
    let figure = data?.figure ? translate(data.figure === 'CUSTOM' ? data?.figure_custom : `figure.${data?.figure}`).concat(' ') : ''
    let ground = translate(`ground.${data?.ground}`) || ''

    return figure.concat(ground)
  }

  const getCenter = (data) => {
    return linesMode === LinesMode.Single
      ? parseInt(data?.si?.[option]?.strides[0] ?? 0)
      : parseInt(data?.si?.[option]?.mean);
  }

  const renderHeader = (condition) => {
    let data = get(condition, '[0]data', [])
    if (isEmpty(data)) return

    return (
      <tr>
        <th></th>
        <th id='seuils' colSpan={2} >{translate('repport.table.supplementary.seuil')}</th>

        {
          data.map(item => (
            <th>{formatText(item)}</th>
          ))
        }
      </tr>
    )
  }

  const checkColor = (value = 0, min = 0, max = 0) => {
    return inRange(toNumber(value), toNumber(min), toNumber(max)) ? 'black' : 'red'
  }

  //render table
  const renderConditions = (cnd, emptySpace = 0, resizeFont = false) => {
    if (isEmpty(cnd) || cnd.every(e => isEmpty(e?.data))) return <></>

    return (
      <>
        {cnd.map(condition => (
          <tr className={resizeFont ? 'multiple_data_row' : ''}>
            <td>{translate(condition.key)}</td>
            <td id='seuils'>{get(condition, 'seuils.seuilG_inf', 0)}</td>
            <td id='seuils'>{get(condition, 'seuils.seuilD_sup', 0)}</td>
            {condition.data.map(data => (
              <td style={{ color: checkColor(getCenter(data), get(condition, 'seuils.seuilG_inf', 0), get(condition, 'seuils.seuilD_sup', 0)) }}>{getCenter(data)}</td>
            ))}
            {/* To align Tables */}
            {
              [...Array(emptySpace)].map(e => (
                <td></td>
              ))
            }


          </tr>
        ))}
      </>
    )
  }

  const renderCustomConditions = (cnd) => {
    if (isEmpty(cnd)) return <></>
    const softData = []
    const hardData = []
    cnd.forEach(sub => {

      let soft = sub?.find(e => e?.keyHeader === 'SOFT')
      let hard = sub?.find(e => e?.keyHeader === 'HARD')
      if (soft) softData.push(soft)
      if (hard) hardData.push(hard)

    })

    console.log({softData,hardData});
    

    let maxLength = max([get(softData, '[0].data', []).length, get(hardData, '[0].data', []).length])

    return (
      <>
        {
          (!isEmpty(softData) && softData.every(i => !isEmpty(i.data))) && <>
            <tr className='multiple_data_row'>
              <th></th>
              <th id='seuils' colSpan={2}>{translate('repport.table.supplementary.seuil')} {translate('examEnums.ground')} {translate('ground.SOFT')}</th>
              <th colSpan={maxLength}></th>
            </tr>
            {renderConditions(softData, maxLength - get(softData, '[0].data', []).length, true)}

          </>
        }
        <>
          {
            (!isEmpty(hardData) && hardData.every(i => !isEmpty(i.data))) && <>
              <tr className='multiple_data_row'>
                <th> </th>
                <th id='seuils' colSpan={2}>{translate('repport.table.supplementary.seuil')} {translate('examEnums.ground')} {translate('ground.HARD')}</th>
                <th colSpan={maxLength} ></th>
              </tr>
              {renderConditions(hardData, maxLength - get(hardData, '[0].data', []).length, true)}

            </>
          }
        </>
      </>
    )
  }


  const isArrayEl = () => {
    return isArray(get(cnd, '[0]'))
  }

  return (
    <Box mt={1} className='wrapper_padding' sx={{ pageBreakBefore: canBreakPage ? 'always' : 'avoid' }}>
      <h4>{translate('repport.table.supplementary.elevation')} (%) : </h4>


      <table className='dataTable'>
        {/* Header render */}
        {
          !isArrayEl() ? renderHeader(cnd) : <></>
        }

        {/* Body render */}
        {
          !isArrayEl() ? renderConditions(cnd) : renderCustomConditions(cnd)
        }


      </table>
    </Box>
  )
}

export default DataTable