import { Box, Button, Divider, Grid, Skeleton, Typography } from '@mui/material'
import axios from 'axios'
import React, { memo, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import CardBlog from './CardBlog'
import useLocales from 'src/hooks/useLocales'
import Iconify from '../Iconify'
import { useDispatch, useSelector } from 'react-redux'
import { setBlogs } from 'src/redux/slices/blog'

function BlogCard({ lang }) {
    const [data, setData] = useState([])
    const {blogs} = useSelector(state=>state.blog)
    const dispatch = useDispatch()
    const { translate } = useLocales()


    const blogQuery = useQuery(['blogs'], () => {
        return axios.get('https://vet.arioneo.com/wp-json/wp/v2/posts')
    }, {
        staleTime: 20 * 60 * 1000 ,
        cacheTime: 20 * 60 * 1000 ,
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
            dispatch(setBlogs(res.data))
        }
    })

    useEffect(() => {
        setData(blogs.filter(e => e.correct_language === lang ?? 'fr').slice(0, 3))
    }, [blogs, lang])




    if (blogQuery.isLoading || blogQuery.isFetching) {
        return <Box display={'flex'} gap={2} >
            {Array.from(new Array(3)).map(e => (
                <Box flexDirection={'column'} position={'relative'} width={'100%'}>
                    <Skeleton variant="rectangular" sx={{ width: '100%' }} height={250} />
                    <Box sx={{ pt: 0.5 }}>
                        <Skeleton />
                        <Skeleton width="60%" />
                    </Box>
                </Box>
            ))}


        </Box>
    }

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                <Typography variant='h5'>
                    {translate('general.latestPosts')}
                </Typography>

                <Button size='small' startIcon={<Iconify icon='carbon:explore'></Iconify>} onClick={() => window.open('https://vet.arioneo.com/blog/', '_blank')}>{translate('general.exploreBlog')}</Button>
            </Box>
            <Divider sx={{ mt: 1, mb: 2 }}></Divider>
            <Grid container spacing={2} >

                {data.map(item => (
                    <Grid item md={4} sm={12}>
                        <CardBlog item={item}></CardBlog>

                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default memo(BlogCard)