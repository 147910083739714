import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import Iconify from '../Iconify'
import useLocales from 'src/hooks/useLocales'
import { useNavigate } from 'react-router'


function ConnectAccountCard() {
    const { translate } = useLocales()
    const navigate = useNavigate()

    return (
        <Card >
            <CardContent className='gradient_card' >
                <Stack direction={'column'} spacing={1}>
                    <Typography color={'white'} variant='h5'>{translate('general.connect.title')}</Typography>
                    <Typography color={'whitesmoke'} variant='body2'>{translate('general.connect.subtitle')}</Typography>
                    <Button onClick={() => navigate('/settings/account?tab=settings.tabExternalLinks')} startIcon={<Iconify icon={'hugeicons:connect'}></Iconify>} variant='outlined' sx={{ width: 'fit-content', color: 'white', borderColor: 'white' }}>{translate('general.connect.cta')}</Button>
                </Stack>

            </CardContent>
        </Card>
    )
}

export default ConnectAccountCard