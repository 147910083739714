import styled from '@emotion/styled'
import { Box, Container } from '@mui/material'
import React from 'react'
import useLocales from 'src/hooks/useLocales'
import Master from 'src/layouts/dashboard/report/Master'

function MoreInformations({ clinique, pageIndex, totalPages }) {
    const { translate } = useLocales()

    const readOrder = [
        {
            ul: translate('repport.table.supplementary.Ld'),
            li: translate('repport.table.supplementary.LdCompare'),
            how: translate('repport.table.supplementary.LdHint')
        },
        {
            ul: translate('repport.table.supplementary.flexion'),
            li: translate('repport.table.supplementary.flexionCompare'),
            how: translate('repport.table.supplementary.flexionHint')
        },
        {
            ul: translate('repport.table.supplementary.circles'),
            li: translate('repport.table.supplementary.circlesCompare'),
            how: translate('repport.table.supplementary.circlesHint')
        },
        {
            ul: translate('repport.table.supplementary.floor'),
            li: translate('repport.table.supplementary.floorCompare'),
            how: translate('repport.table.supplementary.floorHint')
        },
    ]
    const seeChartOrder = [
        {
            ul: translate('repport.table.supplementary.seeIndice'),
            how: translate('repport.table.supplementary.seeIndiceHint')
        },
        {
            ul: translate('repport.table.supplementary.seeAmp'),
            how: translate('repport.table.supplementary.seeAmpHint')
        },
        {
            ul: translate('repport.table.supplementary.seeMaxMin'),
            how: translate('repport.table.supplementary.seeMaxMinHint')
        },
        {
            ul: translate('repport.table.supplementary.seeInter'),
            how: translate('repport.table.supplementary.seeInterHint')
        },
    ]

    return (
        <Master
            pageIndex={pageIndex}
            totalPages={totalPages}
            clinique={clinique}>
            <Container maxWidth='xl'>
                <h3 style={{ color: 'indigo' }}>{translate('repport.table.supplementary.title')}</h3>
                <Box px={2}>
                    <h3 style={{ color: 'indigo', fontWeight: 400, marginBlock: '15px' }}>{translate('repport.table.supplementary.readOrder')}</h3>
                    {
                        readOrder.map((i, index) => (
                            <>


                                <Box display={'flex'} alignItems={'center'} gap={2} fontSize={15} mt={2}>
                                    <span style={{ color: 'indigo' }}>{String(index + 1)}.</span><span>{i.ul}</span>
                                </Box>

                                <Box display={'flex'} alignItems={'center'} gap={2} ml={3} mt={0.5} fontSize={15}>
                                    {<Box width={8} height={8} sx={{
                                        backgroundSize: 'contain',
                                        backgroundRepeat:'no-repeat',
                                        backgroundImage: "url('https://www.svgrepo.com/show/448406/dot.svg')"
                                    }}></Box>} <span dangerouslySetInnerHTML={{ __html: i.li }}></span>
                                </Box>

                                <Box display={'flex'} alignItems={'center'} gap={2} ml={15} mt={0.5} color={'indigo'} fontSize={13} fontStyle={'italic'}>
                                    {<Box width={10} height={10} sx={{
                                        backgroundSize: 'contain',
                                        backgroundRepeat:'no-repeat',
                                        backgroundImage: "url('https://www.svgrepo.com/show/469643/right-arrow.svg')"
                                    }}></Box>} <span>{i.how}</span>
                                </Box>
                            </>
                        ))
                    }

                    <h3 style={{ color: 'indigo', fontWeight: 400, marginBlock: '15px' }}>{translate('repport.table.supplementary.readChart')}</h3>
                    {
                        seeChartOrder.map((i, index) => (
                            <>
                                <Box display={'flex'} alignItems={'center'} gap={2} fontSize={15} mt={2}>
                                    <span style={{ color: 'indigo' }}>{String(index + 1)}.</span><span>{i.ul}</span>
                                </Box>

                                <Box display={'flex'} alignItems={'center'} gap={2} ml={15} mt={0.5} color={'indigo'} fontSize={13} fontStyle={'italic'}>
                                    {<Box width={10} height={10} sx={{
                                        backgroundSize: 'contain',
                                        backgroundRepeat:'no-repeat',
                                        backgroundImage: "url('https://www.svgrepo.com/show/469643/right-arrow.svg')"
                                    }}></Box>} <span>{i.how}</span>
                                </Box>
                            </>
                        ))
                    }
                </Box>
                <span style={{ color: 'indigo', fontStyle: 'oblique', fontSize: '14px', paddingTop: '10px' }}>{translate('repport.table.supplementary.knowHistory')}</span>
            </Container>
        </Master>
    )
}


export default MoreInformations