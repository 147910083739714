import { add } from "date-fns";
import _mock from "./_mock";
import { _examens } from "../_mock";
// utils
import { fDate } from "../utils/formatTime";

// ----------------------------------------------------------------------

// export const _horses = [...Array(10)].map((_, index) => ({
//   id: _mock.id(index),
//   horseName: _mock.company(index),
//   clientFullname: _mock.name.fullName(index),
//   userFullname: _mock.name.fullName(index + 10),
//   createdAt: add(new Date(), { days: index - 15, hours: index }),
// }));

export const _horses = [
  {
    id: "01",
    horseName: "Acsima",
    clientFullname: "Haras des peupliers",
    userFullname: "Marie Didier",
    createdAt: add(new Date(), { days: 1 - 15, hours: 1 }),
    examens: _examens,
  },
  {
    id: "02",
    horseName: "Caramel",
    clientFullname: "Poney club",
    userFullname: "Bobby Bob",
    createdAt: add(new Date(), { days: 1 - 15, hours: 1 }),
  },
];
