import { FolderOffOutlined, ReportProblemOutlined } from '@mui/icons-material'
import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import useLocales from 'src/hooks/useLocales'

function Error({ title, subtitle, buttonText, onClick }) {
    const { translate } = useLocales()

    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            <ReportProblemOutlined color='error' fontSize='large'></ReportProblemOutlined>
            <Typography variant='h4'>{translate(title)}</Typography>
            <Typography textAlign='center' variant='body2'>{translate(subtitle)}</Typography>
            <Button variant='contained' sx={{ marginTop: 1 }} onClick={onClick}>{translate(buttonText)}</Button>
        </Box>
    )
}
Error.defaultProps = {
    title: 'error.error.title',
    subtitle: "error.error.subTitle",
    buttonText: 'button.retry',

}
export default Error