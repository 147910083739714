import { get } from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { PATH_PAGE } from 'src/routes/paths'

function EcgGuard({ children }) {
    const user = useSelector(state => state.user)

    //check if ecg disabled for this user default to false
    const isEcgEnabled = useMemo(() => get(user, 'user.ecg_enabled', false), [user?.user])

    if (!isEcgEnabled) {
        return <Navigate to={PATH_PAGE.ecgDisabled}></Navigate>
    }
    return (
        <>
            {children}
        </>
    )
}

export default EcgGuard