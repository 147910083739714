// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  registerEquisym: path(ROOTS_AUTH, '/register-with-equisym'),
  registerEquimetre: path(ROOTS_AUTH, '/register-with-equimetre'),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  enterNewPassword: path(ROOTS_AUTH, "/confirm-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  ecgDisabled: "/ecg_disabled",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, "/"),
  horses: {
    view: (horseId) => path("/", `horses/${horseId}/list-view`),
    examenView: (horseId, examenId, folderId = null, conditionsId = null, openReportModal = false, origin = 'case') =>
      path("/", `horses/${horseId}/examens/${examenId}`).concat(`?selected=${folderId}`).concat(conditionsId ? `&selectedConditions=${conditionsId}` : '').concat(openReportModal ? `&openModal=true` : '').concat(`&origin=${origin}`),
    examenEquimetreView: (horseId, examenId, folderId = null, conditionsId = null) =>
      path("/", `horses/${horseId}/examequimetre/${examenId}`).concat(`?selected=${folderId}`),
    folderView: (horseId, folderId) => path("/", `horses/${horseId}/folder/${folderId}`),
    followLongitude: (horseId, folderId) => path("/", `horses/${horseId}/longitude/${folderId}`),
    createNewReport: (horseId, examId, vetCase) => path("/", `horses/${horseId}/newReport?exam=${examId}&vetCase=${vetCase}`)
  },
  exams: {
    nonCategorise: () => path('exams/', "uncategorized"),
  }
};

export const PATH_DOCS = "";
