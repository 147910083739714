import React from "react";

import { useState, useEffect, useRef } from "react";
// @mui
import { Container, Tab, Box, Tabs } from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
import useTabs from "../../hooks/useTabs";
// components
import Page from "../../components/Page";
// sections
import { TeamForm, TeamMembers, TeamAdvanced } from "../team";

// ----------------------------------------------------------------------

function TeamSettings({ team, onCancel, tab, onTab }) {
  const [currentTab, setCurrentTab] = useState("settings.tabGeneral");

  function onChangeTab(event, newValue) {
    setCurrentTab(newValue);
    onTab(newValue);
  }

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  const { translate } = useLocales();

  const CLINIC_TABS = [
    {
      value: "settings.tabGeneral",
      component: <TeamForm team={team} onCancel={onCancel} />,
    },
    {
      value: "settings.tabMembers",
      component: <TeamMembers selectedTeam={team} />,
    },
    {
      value: "settings.tabAdvanced",
      component: <TeamAdvanced team={team} onCancel={onCancel} />,
    },
  ];

  return (
    <Box>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
        sx={{ px: 3 }}
      >
        {CLINIC_TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={translate(tab.value)}
            icon={tab.icon}
            value={tab.value}
            sx={{ typography: "body2" }}
          />
        ))}
      </Tabs>

      {CLINIC_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </Box>
  );
}

export default React.memo(TeamSettings);
