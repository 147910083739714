// @mui
import { styled } from "@mui/material/styles";
import { Box, Container, Typography } from "@mui/material";
// layouts
import LogoOnlyLayout from "../../layouts/LogoOnlyLayout";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import Page from "../../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  alignItems: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Registered() {
  const { translate } = useLocales();

  return (
    <Page title={translate("auth.register")} sx={{ height: 1 }}>
      <RootStyle>
        <LogoOnlyLayout />

        <Container>
          <Box sx={{ maxWidth: 480, mx: "auto" }}>
            <Typography variant="h3" paragraph>
              {translate("auth.registeredTitle")}
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {translate("auth.registeredMessage")}
            </Typography>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
