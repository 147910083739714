import { useState } from "react";
// @mui
import { CircularProgress, MenuItem, Stack } from "@mui/material";
// hooks
import useLocales from "../../../hooks/useLocales";
// components
import Iconify from "../../../components/Iconify";
import MenuPopover from "../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../components/animate";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState(null);
  const { allLang, currentLang, onChangeLang } = useLocales();
  const {isLoading,userUpdation} = useSelector(state=>state.user)
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
    {
      userUpdation ? <CircularProgress size={12}></CircularProgress> :<>
          <IconButtonAnimate
            onClick={handleOpen}
            sx={{
              width: 40,
              height: 40,
              ...(open && { bgcolor: "action.selected" }),
            }}
          >
            <Iconify icon={currentLang?.icon} width={20} height={20} />
          </IconButtonAnimate>


          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              mt: 1.5,
              ml: 0.75,
              width: 180,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            }}
          >
            <Stack spacing={0.75}>
              {allLang.map((option) => (
                <MenuItem
                  key={option?.value}
                  selected={option?.value === currentLang?.value}
                  onClick={() => {
                    onChangeLang(option?.value);
                    handleClose();
                  }}
                >
                  <Iconify
                    icon={option.icon}
                    width={20}
                    height={20}
                    sx={{ mr: 1 }}
                  />
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </MenuPopover>
      </>
    }
     
    </>
  );
}
