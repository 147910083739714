import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { AdminPanelSettings, ArrowForward, Close, ContentCopy, Search, Share } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { Autocomplete, Button, Divider, Fab, IconButton, Link, OutlinedInput, Stack, TextField, Tooltip } from '@mui/material';
import { getTeam } from 'src/redux/slices/team';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import UserList from './UserList';
import { useSelector } from 'react-redux';
import useLocales from 'src/hooks/useLocales';
import { getOrganisationUsers, inviteUser, isUserExist } from 'src/redux/slices/user';
import { useMemo } from 'react';
import { useState } from 'react';
import * as yup from 'yup';
import { addUserVetCase } from 'src/redux/slices/examen';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import Iconify from '../Iconify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    pb: 3,
    borderRadius: 1
};
function ManageUserModal({ open, handleClose, vetCase, refreshVetCase, onChangeTeamClick }) {
    const theme = useTheme();
    const { horse } = useSelector(state => state.horse)
    const { translate } = useLocales()
    const [value, setValue] = React.useState(0);
    const [teamUser, setTeamUser] = React.useState([])
    const [teamLoading, setTeamLoading] = useState(false)
    const [fromList, setFromList] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [selected, setSelected] = useState("")
    const [users, setUsers] = useState([])
    const [foundUsers, setFoundUsers] = useState([])
    let teamId = useMemo(() => get(vetCase, 'team.id'), [vetCase])
    const { userInOrganisation } = useSelector(state => state.user)
    const { team, teams } = useSelector(state => state.team)
    const { enqueueSnackbar } = useSnackbar()

    const organisation = userInOrganisation?.organisation || {}


    useEffect(() => {
        open && getUsers(teamId)
    }, [open,teamId])

    //getTeam
    async function getUsers(team_id) {
        try {
            setTeamLoading(true)
            //Get Team
            let response = await getTeam(team_id)

            //Getting user ids
            let ids = get(response, 'data.user_in_teams', []).map(item => item?.user).map(user => user?.id)

            //Getting users
            let users = await getOrganisationUsers(organisation?.id)

            //Filter users
            let temp = users?.filter(user => !ids.includes(user?.id))

            let formatTemp = temp.map(e => {
                return {
                    label: e?.firstname ? e?.firstname + " " + e?.lastname : e?.email,
                    id: get(e, '@id')
                }
            })

            setUsers(formatTemp)
        } catch (err) {
            console.log(err);
            setUsers([])
        } finally {
            setTeamLoading(false)
        }
    }


    //Prepare Mutatuion
    const teamMutation = useMutation(() => getTeam(teamId), {
        onSuccess: ({ data }) => {
            setTeamUser(get(data, 'user_in_teams', []))
        }
    })

    const checkEmailMutation = useMutation(() => isUserExist(email), {
        onSuccess: ({ data }) => {
            let users = get(data, 'hydra:member')
            setFoundUsers(users)
            setFromList(false)
            if (users.length > 0) {
                setSelected(users[0])
            }
        }
    })

    const inviteMutation = useMutation(() => inviteUser(email))

    const InviteUser = () => {
        inviteMutation.mutateAsync()
            .then(async ({ data }) => {
                addUser(get(data, '@id'))
                enqueueSnackbar(translate('snackbar.inviteSent'), {
                    variant: 'success'
                })
                checkEmailMutation.reset()
            }).catch(err => {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'error'
                })
            })
    }
    const addUserVetCaseMutation = useMutation((id) => addUserVetCase({ vetCase: vetCase['@id'], user: id }))

    const query = useQueryClient()
    useEffect(() => {
        if (teamId && open) {
            teamMutation.mutate()
        }
    }, [open,vetCase])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    let schema = yup.object().shape({
        email: yup.string().email(),
    });


    const addUser = (id) => {
        addUserVetCaseMutation.mutateAsync(id)
            .then(res => {
                refreshVetCase()
            }).catch(err => {
                enqueueSnackbar(translate('snackbar.userAlreadyExistInVet'), {
                    variant: 'warning'
                })
                setValue(1)
            })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                    <Iconify color='primary' icon='hugeicons:share-01' width={21} height={21} />
                        <Typography fontWeight={600}  variant='h6'>{translate('manageUsers')}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>




                {/* Content */}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={translate('general.members')} />
                    <Tab label={translate('addCase.addUser')} />
                </Tabs>

                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    style={{ padding: 0 }}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <UserList changeTeam={onChangeTeamClick} currentTeam={team} onRemoveUser={() => refreshVetCase()} teamUsers={teamUser} caseUsers={get(vetCase, 'user_in_vet_cases', [])} loading={teamMutation.isLoading} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        {/*Add User with Autocomplete */}
                        <Autocomplete
                            value={selected}
                            getOptionLabel={option => option?.label || ''}
                            onChange={(event, value) => {
                                checkEmailMutation.reset()
                                setFromList(true)
                                setEmail('')
                                setError('')
                                setFoundUsers([])
                                setSelected(value)
                            }}
                            options={users}
                            renderInput={(params) => <TextField {...params} label={translate('general.members')} />}
                            placeholder={translate('general.members')} />
                        {(selected && fromList) && <Box display='flex' justifyContent='end' marginTop={1}>
                            <LoadingButton onClick={() => addUser(selected.id)} loading={addUserVetCaseMutation.isLoading} variant='outlined'>{translate('nav.add')}</LoadingButton>
                        </Box>}

                        <Divider>
                            <Typography variant='body2' color='gray' marginY={2}>{translate('general.byMail')}
                            </Typography>
                        </Divider>

                        {/*Add User with Email */}

                        <OutlinedInput
                            placeholder={translate('auth.email')}
                            fullWidth
                            value={email}
                            error={error}
                            disabled={checkEmailMutation.isLoading}
                            onChange={e => {
                                checkEmailMutation.reset()
                                setSelected('')
                                setError('')
                                setEmail(e.target.value.trim())
                            }}
                            onKeyPress={(e) => {

                                e.key === 'Enter' && schema.isValid({ email }).then(valid => {
                                    valid ? checkEmailMutation.mutate() : setError(translate('yup.email.valid'))
                                })
                            }}
                            endAdornment={<>
                                <IconButton onClick={() => {
                                    schema.isValid({ email }).then(valid => {
                                        valid ? checkEmailMutation.mutate() : setError(translate('yup.email.valid'))
                                    })
                                }} disabled={!email}>
                                    <Search />
                                </IconButton></>}
                        >
                        </OutlinedInput>
                        <Typography color='red' variant='caption'>{error}</Typography>
                        {(checkEmailMutation.isSuccess && foundUsers.length == 0) ?
                            <>
                                <LoadingButton sx={{ mt: 1 }} onClick={InviteUser} color='warning' variant='text' loading={inviteMutation.isLoading} size='small'>
                                    <Typography variant='caption'>{translate('yup.email.notFound')},  <Typography variant='caption' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>{translate('nav.invite')} {email}</Typography> </Typography>
                                </LoadingButton>
                            </>

                            : <></>}


                        {(checkEmailMutation.isSuccess && foundUsers.length > 0 && !fromList) ?
                            <Box display='flex' justifyContent='space-between' flexDirection={'column'} marginTop={1}>
                                <Typography variant='caption'>{translate('modal.inviteCaseHint')}</Typography>
                                <LoadingButton sx={{ alignSelf: 'flex-end' }} onClick={() => addUser(selected['@id'])} loading={addUserVetCaseMutation.isLoading} variant='outlined'>{translate('nav.add')}</LoadingButton>
                            </Box>
                            : <></>}

                    </TabPanel>



                </SwipeableViews>

                {/* End of Content */}

            </Box>
        </Modal>
    )
}

export default ManageUserModal

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingBlock: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

