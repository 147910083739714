export const gridKeys = {
    folder: "folderNumber",
    horse: "horseName",
    client: "clientFullname",
    tool: "toolName",
    team: "userFullname",
    reason: "reason",
    lastUsedTool: "lastUsedTool",
    laseExamDate: "laseExamDate",
    actions: "actions",
    creation: "createdAt",
    date: "date",
    note: "note",
    stable: "stable",
    org: "organisation",
    description: "description",
    vet:"vet",
    folders:'folders'
}

export const LocomotionType={
    LC:'SPONTANEOUS'
}