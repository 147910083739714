const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    breadcrumbs: [],
    horseRoute: "0",
    showTabs: true,
}

const slice = createSlice({
    name: 'display',
    initialState,
    reducers: {
        setBreadcrumb(state, action) {
            state.breadcrumbs = action.payload
        },
        setRoute(state, action) {
            state.horseRoute = action.payload
        },
        setTabsVisibility: (state, action) => {
            state.showTabs = action.payload

        }
    }
})
// Reducer
export default slice.reducer;

export const { setBreadcrumb, setRoute,setTabsVisibility } = slice.actions;
