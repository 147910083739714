import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useEffect } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Avatar from "../../../components/Avatar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarHorse.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarHorse({ isCollapse }) {
  const { horse } = useSelector((state) => state.horse);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getHorse());
  // }, [dispatch]);

  return (
    horse && (
      <Link
        underline="none"
        color="inherit"
        component={RouterLink}
        to={PATH_DASHBOARD.horses.view(horse.id)}
        sx={{ mr: 2, ml: 2 }}
      >
        <RootStyle
          sx={{
            ...(isCollapse && {
              bgcolor: "transparent",
            }),
          }}
        >
          <Avatar />

          <Box
            sx={{
              ml: 2,
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Typography variant="subtitle2" noWrap>
              {horse && horse.name}
            </Typography>
            <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
              {horse && horse.owner}
            </Typography>
          </Box>
        </RootStyle>
      </Link>
    )
  );
}
