import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Examen } from 'src/classes/Examen'
import Iconify from '../Iconify'
import { ExamExtractor } from 'src/utils/ExamExtractor'
import useLocales from 'src/hooks/useLocales'
import { getOutils, getOutilsColor, getOutilsTextColor } from 'src/utils/dashboard'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { getUserInOrganisationSuccess, switchToUserInOrganisationFromId } from 'src/redux/slices/user'
import { setExams } from 'src/redux/slices/blog'

function ExamsCard() {
    const { exams } = useSelector(state => state.blog)
    const dispatch = useDispatch()
    const { user, userInOrganisation } = useSelector(state => state.user)
    const { translate } = useLocales()
    const navigate = useNavigate()
    const examsQuery = useQuery(['lastExams'], () => Examen.getLastExams(user.id, 10), {
        refetchOnWindowFocus: false,
        staleTime: 20 * 60 * 1000,
        cacheTime: 20 * 60 * 1000,
        onSuccess: (response) => {
            dispatch(setExams(get(response, 'data.hydra:member', [])))
        }
    })


    const changeOrganisation = (item) => {
        if ('organisation' in item) {

            let restricted = false

            let org_exam_id = get(item, 'organisation.id')

            console.log({ item, userInOrganisation, org_exam_id });

            let org = user.limited_access_organisations.find(org => org.id === org_exam_id)

            if (org) {
                restricted = true
            } else {
                org = user.user_in_organisations.find(e => e?.organisation?.id === org_exam_id)
            }

            switchToUserInOrganisationFromId(org?.id, user, restricted)




        }
    }

    const handleCellClick = (item) => {



        let examType = ExamExtractor.getExamType(item)
        let horse = ExamExtractor.getHorseId(item)
        let externalId = item.external_id

        //If exam has org as field we change before redirection
        changeOrganisation(item)

        if (examType === 'Equisym') {
            navigate(PATH_DASHBOARD.horses.examenView(
                horse,
                externalId,
                get(item, 'exam_in_vet_cases[0].vet_case.id'),
                null,
                false,
                'dashboard'

            ))
        }

        if (examType === 'Equimetre') {
            navigate(PATH_DASHBOARD.horses.examenEquimetreView(
                horse,
                externalId,
                get(item, 'exam_in_vet_cases[0].vet_case.id'),
            ))
        }
    }

    return (
        <Card sx={{ minHeight: '100%', minWidth: '100%' }}>
            <CardContent >
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                    <Typography variant='h5'>
                        {translate('general.exams')}
                    </Typography>


                </Box>
                <Divider sx={{ my: 2 }}></Divider>
                <List dense sx={{ maxHeight: 200, overflowY: 'scroll' }}>

                    {examsQuery.isLoading || examsQuery.isFetching ? Array.from(Array(2)).map(i => (
                        <ListItem disableGutters key={i}>
                            <Skeleton height={50} width={'100%'}></Skeleton>
                        </ListItem>
                    )) : <>
                        {exams.map(exam => (
                            <ListItemButton onClick={() => handleCellClick(exam)} key={exam.external_id}>
                                <ListItemAvatar >
                                    <Avatar sx={{ borderRadius: 1, bgcolor: '#3F51B5' }}>
                                        <Iconify sx={{ color: 'white' }} icon='healthicons:i-exam-multiple-choice' ></Iconify>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Stack alignItems={'center'} direction={'row'} spacing={1}>
                                        <Typography variant='inherit'> {get(exam, 'horse.name', translate('general.unamed'))}</Typography>
                                    </Stack>}
                                    secondary={
                                        <Box display={'flex'} alignItems={'center'} gap={0.5} mt={0.5}>
                                            <Typography fontSize={12} display={'flex'} gap={0.5} alignItems={'center'}>
                                                <Iconify icon={'formkit:time'}></Iconify>
                                                {ExamExtractor.getLastExam(exam)}
                                            </Typography>
                                            <Divider flexItem orientation='vertical'></Divider>
                                            <Typography fontSize={12} display={'flex'} gap={0.5} alignItems={'center'}>
                                                <Iconify icon={'lucide:hospital'}></Iconify>
                                                {get(exam, 'organisation.name', translate('general.unamed'))}
                                            </Typography>
                                            <Divider flexItem orientation='vertical'></Divider>
                                            <Typography fontSize={12} sx={{ ...getOutilsTextColor(get(exam, '@type', null)) }}>{getOutils(get(exam, '@type', null))}</Typography>
                                        </Box>
                                    }
                                >

                                </ListItemText>
                            </ListItemButton>
                        ))}
                    </>}

                </List>
            </CardContent>
        </Card>
    )
}

export default ExamsCard