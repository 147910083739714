import { Close, PushPin, SaveAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, Grid, IconButton, Input, InputLabel, Modal, TextField, Typography } from '@mui/material'
import { isEmpty, uniq } from 'lodash';
import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import useLocales from 'src/hooks/useLocales';
import { setPinned } from 'src/redux/slices/examen';
import { PATH_DASHBOARD } from 'src/routes/paths';

function PinModal({ open, handleClose, horseId, conditions, onSubmit, loading, isSuccess, canvas, displayType }) {
    const { translate } = useLocales()
    const [error, setError] = useState(false)
    const [observation, setObservation] = useState('')
    const [title, setTitle] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const validateAndRedirect = (action) => {
        if (!title) {
            setError(prev => !prev)
        } else {
            onSubmit({
                title,
                observation,
                conditions,
                action
            })
        }
    }

    useEffect(() => {
        let title = ''
        if (conditions.length > 1) {
            title += 'Comparaison '
        }
        title += renderType(displayType)


        let arr = []
        if (!isEmpty(conditions)) {
            conditions.forEach(condition => {
                arr.push(formatText(condition))
            })
            let _uniq = uniq(arr)
            title += ' ' + _uniq.join(' - ')
        }


        setTitle(title)


    }, [conditions])

    const formatText = (data) => {
        let gait = data?.gait ? translate(`gait.${data?.gait}`).concat('/') : ''
        let figure = data?.figure ? translate(`figure.${data?.figure}`).concat('/') : ''
        let ground = translate(`ground.${data?.ground}`) || ''

        return gait.concat(figure, ground)
    }

    const renderType = (type) => {
        console.log({ type });
        switch (type) {
            case 'DISPLAY_MODE_MIN':
                return translate('examEnums.variables.minDesc')

            case 'DISPLAY_MODE_MAX':
                return translate('examEnums.variables.maxDesc')

            case 'DISPLAY_MODE_AMPLITUDE':
                return translate('examEnums.variables.amplitudeDesc')

            case 'DISPLAY_MODE_STANDARD_DEVIATION':
                return translate('examEnums.indice')

            case 'DISPLAY_MODE_UNITARY':
                return translate('examEnums.indiceUnitaire')
            default:
                break;
        }
    }

    useEffect(() => {
        if (isSuccess) {
            setTitle('')
            setObservation('')
        }
    }, [isSuccess])

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <PushPin color='primary' fontSize='small' />
                        <Typography color='primary' variant='h6'>{translate('pinnedConditions.cta')}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>

                {/* Body */}
                <Box sx={{ position: 'relative', overflowY: 'scroll', maxHeight: '500px !important' }} >

                    <Box position='relative'>
                        <a href={canvas} download={title}>
                            <Button size='small' color='info' variant='text' startIcon={<SaveAlt></SaveAlt>} sx={{ float: 'right', mb: 2 }}>
                                {translate('downloadImage')}
                            </Button>
                        </a>
                        <img src={canvas} alt=''></img>
                    </Box>
                    <Grid container spacing={2} marginTop={1}>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField error={error} helperText={error && translate('yup.titre.required')} value={title} onChange={e => { setError(false); setTitle(e.target.value) }} label={translate('pinnedConditions.title')} variant='outlined'></TextField>
                            </FormControl>
                            <FormControl fullWidth >
                                <TextField value={observation} onChange={e => setObservation(e.target.value)} rows={4} sx={{ marginTop: 3 }} multiline label={translate('pinnedConditions.observation')} variant='outlined'></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12} display='flex' justifyContent='end' flexDirection='row' gap={1}>
                            <LoadingButton loading={loading} onClick={() => validateAndRedirect('pin')} sx={{ marginTop: '0 !important' }} variant='outlined'>{translate('pinnedConditions.cta')}</LoadingButton>
                            <LoadingButton loading={loading} onClick={() => validateAndRedirect('create')} sx={{ marginTop: '0 !important' }} variant='contained'>{translate('pinnedConditions.pin_create')}</LoadingButton>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </Modal>
    )
}
PinModal.defaultProps = {
    conditions: [],
    open: false,
    handleClose: () => { },
    horseId: null,
    rawConditions: []
}
export default PinModal
export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};